import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Switch,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLoja } from "../../redux/lojaSlice";
import { toastIt } from "../../functions/pushNotif";
import UrlApi, { ApiToken } from "../../appconfig";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import global_theme from "../../assets/global/globalTheme";

const Vendas = () => {
  const [msgPrimeiroContato, setMsgPrimeiroContato] = useState("");
  const [loading, setLoading] = useState(false);
  const [msgsErro, setMsgsErro] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const theme = global_theme;

  const loja = useSelector(selectLoja);
  const carregarDados = () => {
    setPageLoading(true);
    fetch(`${UrlApi}/loja/configuracoes/vendas`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: ApiToken,
        loja_id: loja._id,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.loja_id) {
          if (data.msgPrimeiroContato != null)
            setMsgPrimeiroContato(data.msgPrimeiroContato);
        } else {
          // console.log("datanaosucesso! ");
        }
        setPageLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        toastIt("Erro ao carregar dados de vendas", "error");
        setPageLoading(false);
      });
  };

  const salvarConfiguracoes = () => {
    if(loading) return;
    setLoading(true);


    const variaveisNecessarias = ["<nomecliente>"];

    const newConfig = {
      msgPrimeiroContato: msgPrimeiroContato,
    };

    var valido = true;

    variaveisNecessarias.forEach((variavel) => {
      if(!msgPrimeiroContato.includes(variavel)) {
        valido = false;
        setMsgsErro(
          [...msgsErro,
            {
              label: "msgPrimeiroContato",
              msg: `É necessário incluir ${variavel} na mensagem.`
            }
          ]
        )
        setLoading(false);
        return;
      }
    })

    if (!valido) return;

    fetch(`${UrlApi}/loja/configuracoes/vendas`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: ApiToken,
        loja_id: loja._id,
      },
      body: JSON.stringify({
        newConfig: newConfig,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("DATA: ", data);
        if (data.success) {
          toastIt("Dados de entrega salvos com sucesso", "success");
        } else {
          toastIt("Erro ao salvar dados de vendas", "error");
        }
        setLoading(false);
      })
      .catch((error) => {
        toastIt("Erro ao salvar dados de vendas", "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loja._id) {
      carregarDados();
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
        p: 2,
        color: theme.palette.primary.main,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
        Vendas
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            Clientes
          </Typography>
          <TextField
            label="Mensagem de primeiro contato"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="text"
            sx={{
              ...theme.inputs.primary,
              width: "100%",
              maxWidth: 500,
            }}
            // how can I set it up to be a textarea?
            multiline
            value={msgPrimeiroContato}
            onChange={(e) => setMsgPrimeiroContato(e.target.value)}
          />
          {msgsErro?.find(m => m.label === "msgPrimeiroContato")?.msg != null && (
            <Typography
            variant="body1"
            sx={{
              color: "#F22"
            }}>
              {msgsErro?.find(m => m.label === "msgPrimeiroContato").msg}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "100%",
            maxWidth: "300px",
            height: "50px",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
          onClick={() => salvarConfiguracoes()}
        >
          {loading ? <CircularProgress size={24} /> : "Salvar"}
        </Button>
      </Box>
    </Box>
  );
};

export default Vendas;
