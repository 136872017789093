import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import {
  selectCarrinho,
  addToCart,
  removeFromCart,
} from "../../../redux/clienteSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultProduct from "../../../assets/default_product.jpg";
import CloseIcon from "@mui/icons-material/Close";
import global_theme from "../../../assets/global/globalTheme";
import { formataPreco } from "../../../functions/formatInfo";
import QuantityButton from "../../Buttons/QuantityButton";

const CartPage = ({ handleClose, open }) => {
  const carrinho = useSelector(selectCarrinho);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = global_theme;

  const handleQuantidade = (produto, operacao) => {
    if (operacao === "add") {
      dispatch(
        addToCart({
          ...produto,
          quantidade: 1,
        })
      );
    } else {
      dispatch(removeFromCart(produto));
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          minWidth: 300,
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: "3em 1em",
          outline: "none",
          position: "relative",
        }}
      >
        <CloseIcon
          sx={{
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            position: "absolute",
            right: 5,
            top: 5,
            "&:hover": {
              color: "#f21",
            },
          }}
          onClick={handleClose}
        />
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          sx={{
            fontWeight: "bold",
          }}
        >
          Carrinho
        </Typography>
        <Box sx={{ mt: 2, paddingBottom: 1 }}>
          {!carrinho?.produtos ||
            (!carrinho.produtos.length && (
              <Typography variant="h6" color="textSecondary" component="p">
                Seu carrinho está vazio
              </Typography>
            ))}
          {carrinho?.produtos &&
            carrinho.produtos.map((produto) => (
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                  outline: "1px solid #ccc",
                  height: 170,
                }}
                key={produto._id}
              >
                <Grid item sm={3} xs={5}>
                  <img
                    src={produto.imagens[0] || DefaultProduct}
                    alt={produto.nome}
                    style={{
                      height: 170,
                      width: "100%",
                      objectFit: "contain",
                      borderRight: "1px solid #ccc",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={7}
                  sm={9}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Card
                    sx={{
                      boxShadow: "none",
                      height: "100%",
                    }}
                  >
                    <CardContent
                      sx={{
                        height: "100%",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: ".9em",
                        }}
                      >
                        {produto.nome}
                      </Typography>
                      {produto.variacoes &&  (
                        // aqui vai mostrar as variações do produto, mas apenas a propriedade value. Elas serão divididas por aquele ponto que aparece no meio
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          sx={{
                            fontSize: ".8em",
                          }}
                        >
                          {Object.entries(produto.variacoes).map(
                            (variacao, index) => {
                              if (index === 0) {
                                return variacao[1].value;
                              } else {
                                return ` . ${variacao[1].value}`;
                              }
                            }
                          )}
                        </Typography>
                      )}
                      {produto.adicionais?.length > 0 &&
                        produto.adicionais.map((adicional, index) => {
                          if (index === 0) {
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                sx={{
                                  fontSize: ".8em",
                                }}
                              >
                                +{adicional.nome}
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                sx={{
                                  fontSize: ".8em",
                                }}
                              >
                                {`, +${adicional.nome}`}
                              </Typography>
                            );
                          }
                        })}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: 1,
                        }}
                      >
                        <QuantityButton
                          outsideHandle={handleQuantidade}
                          produto={produto}
                          outsideQuantity={produto.quantidade}
                          small={true}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="h3"
                          sx={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "#000",
                          }}
                        >
                          {formataPreco(produto.preco)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ))}
        </Box>
        {carrinho?.produtos && carrinho.produtos.length > 0 && (
          <>
            <Typography
              variant="h6"
              color="textSecondary"
              component="p"
              sx={{
                fontWeight: "bold",
                fontSize: 18,
                color: "#000",
              }}
            >
              Total: {formataPreco(carrinho.total)}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                width: "100%",
                fontWeight: "bold",
              }}
              onClick={() => {
                handleClose();
                navigate("/checkout");
              }}
            >
              Finalizar compra
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CartPage;