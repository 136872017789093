// essa será a página que irá listar os pedidos

import React, { useEffect, useState } from "react";
import { Box, InputLabel, Select, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoja } from "../../../redux/lojaSlice";
import { formataData } from "../../../functions/formatInfo";
import { toastIt } from "../../../functions/pushNotif";
import global_theme from "../../../assets/global/globalTheme";
import io from "socket.io-client";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ReplayIcon from "@mui/icons-material/Replay";
import UrlApi, { ApiToken } from "../../../appconfig";
import { statusIcons } from "../../../assets/icons/status-pedido/status_icons";

const PedidosMobile = () => {
  const theme = global_theme;
  const [pedidos, setPedidos] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([]); //
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState("");
  const [filtro, setFiltro] = useState("todos");
  const [ordem, setOrdem] = useState("recentes");
  const [lojaConfig, setLojaConfig] = useState(null);
  const loja = useSelector(selectLoja);
  const navigate = useNavigate();

  var socket = null;
  useEffect(() => {
    if (loja?._id) {
      socket = io(UrlApi, {
        transports: ["websocket"],
      });
      socket.on(`novopedido_${loja._id}`, (data) => {
        toastIt(`${data.data}`, "success");
        getPedidos();
      });
      getPedidos();
    }
  }, []);

  const getPedidos = async () => {
    setLoading(true);
    const response = await fetch(`${UrlApi}/pedidos/loja`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    });
    const data = await response.json();
    setLoading(false);
    setPedidos(data.reverse());
    setPedidosFiltrados(data);
  };

  // aqui um objeto com todos os status possíveis e o que vem depois de cada um
  const todosStatus = {
    "aguardando confirmação": "em preparação",
    "em preparação": "a caminho",
    "a caminho": "entregue",
  };

  const statusToIcon = {
    "aguardando confirmação": statusIcons.Pendente,
    "em preparação": statusIcons.Pendente,
    "a caminho": statusIcons.EmRota,
    entregue: statusIcons.Entregue,
    cancelado: statusIcons.Cancelado,
  };

  if (loading) {
    return <LoadingPage />;
  }

  const handleFiltro = (valor) => {
    setFiltro(valor);
    if (valor === "todos") {
      setPedidosFiltrados(pedidos);
    } else {
      const pedidosFiltrados = pedidos.filter(
        (pedido) => pedido.status.toLowerCase() === valor
      );
      setPedidosFiltrados(pedidosFiltrados);
    }
  };

  const handlePesquisa = (e) => {
    setPesquisa(e.target.value);
    if (filtro === "todos") {
      const pedFiltrados = pedidos.filter((pedido) =>
        pedido.cliente.nome.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPedidosFiltrados(pedFiltrados);
    } else {
      const pedFiltrados = pedidos.filter(
        (pedido) =>
          pedido.status.toLowerCase() === filtro &&
          pedido.cliente.nome
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setPedidosFiltrados(pedFiltrados);
    }
  };

  const handleOrdem = (valor) => {
    if (valor === "recentes") {
      const pedidosOrdenados = pedidosFiltrados.sort((a, b) => {
        return new Date(b.data_pedido) - new Date(a.data_pedido);
      });
      setPedidosFiltrados(pedidosOrdenados);
    } else {
      const pedidosOrdenados = pedidosFiltrados.sort((a, b) => {
        return new Date(a.data_pedido) - new Date(b.data_pedido);
      });

      setPedidosFiltrados(pedidosOrdenados);
    }

    setOrdem(valor);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",

          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            padding: ".8em",
          }}
        >
          Pedidos
        </Typography>
        <ReplayIcon
          sx={{
            color: theme.palette.primary.main,
            cursor: "pointer",
            transition: "all .3s",

            "&:hover": {
              color: theme.palette.secondary.main,
              transform: "rotate(-360deg)",
            },
          }}
          title="Atualizar"
          onClick={getPedidos}
        />
      </Box>
      {/* Aqui terá um filtro de pedidos */}
      <Box
        sx={{
          padding: "0 1em",
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Pesquisar"
          variant="outlined"
          sx={{
            width: "100%",
            backgroundColor: "#fff",

            ...theme.inputs.primary,
          }}
          value={pesquisa}
          onChange={(e) => handlePesquisa(e)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            position: "relative",
          }}
        >
          <InputLabel
            id="filtro-label"
            sx={{
              ...theme.inputs.primary,
              color: "#666",
              position: "absolute",
              top: "-14px",
              left: "10px",
              fontSize: ".8em",
            }}
          >
            Filtro
          </InputLabel>
          <Select
            native
            value={filtro}
            onChange={(e) => handleFiltro(e.target.value)}
            labelId="filtro-label"
            id="select-filter"
            inputProps={{
              id: "filter-select",
            }}
            sx={{
              ...theme.inputs.primary,
            }}
          >
            {[
              "todos",
              ...Object.keys(todosStatus),
              "entregue",
              "cancelado",
            ].map((status, index) => (
              <option
                key={index}
                value={status}
                style={{
                  color: theme.palette.primary.main,

                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                    color: "#fff",
                  },
                }}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </option>
            ))}
          </Select>
        </Box>
        {ordem === "recentes" ? (
          <ArchiveIcon
            sx={{
              color: theme.palette.primary.main,
              cursor: "pointer",
              transition: "all .3s",

              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => handleOrdem("antigos")}
          />
        ) : (
          <UnarchiveIcon
            sx={{
              color: theme.palette.primary.main,
              cursor: "pointer",
              transition: "all .3s",

              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => handleOrdem("recentes")}
          />
        )}
      </Box>
      <Box
        component="ul"
        sx={{
          listStyle: "none",
          padding: ".5em 0",
          margin: 0,
        }}
      >
        {pedidosFiltrados.map((order, index) => (
          <Box
            component="li"
            key={index}
            sx={{
              backgroundColor:
                order.status === "cancelado" ? "#d6322f" : "#fff",
              padding: "1em .5em",
              cursor: "pointer",
              // se esse pedido estiver selecionado, eu adiciono uma borda na direita
              borderRight:
                order._id === pedido?._id
                  ? "10px solid " + theme.palette.primary.contrast
                  : "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              "&:hover": {
                backgroundColor:
                  order.status === "cancelado"
                    ? "#d62222"
                    : theme.palette.primary.light,
              },
            }}
            onClick={() => {
              // vai usar o navigate para ir para a página do pedido
              navigate(`/m/pedidos/${order._id}`)
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5em",
              }}
            >
              <Typography variant="body1">{order.cliente.nome}</Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1em",
                  color: order.status === "cancelado" ? "#fff" : "#666",
                }}
              >
                {formataData(order.data_pedido)}
              </Typography>
            </Box>
            <img
              src={statusToIcon[order.status.toLowerCase()]}
              alt={order.status}
              style={{
                width:
                  order.status.toLowerCase() === "cancelado" ? "42px" : "60px",
                height:
                  order.status.toLowerCase() === "cancelado" ? "42px" : "60px",
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PedidosMobile;
