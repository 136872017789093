export const lojaFuncionamento = (horarios) => {
  // essa função deve retornar se a loja está aberta ou fechada no momento da consulta
  // e até que horas ela ficará aberta/fechada no dia atual, ex: "aberto até 18:00" ou "fechado até 08:00"
  // o objeto que essa função recebe como parâmetro tem a seguinte estrutura:
  // {
  //   domingo: {
  //     aberto: false,
  //     abertura: "08:00",
  //     fechamento: "18:00",
  //   },
  //}
  // o objeto acima indica que a loja não abre aos domingos
  // a função deve retornar um objeto no seguinte formato:
  // {
  //   aberto: false,
  //   mensagem: null // null porque não temos uma mensagem para exibir quando a loja está fechada
  // }
  // ou no caso da loja abrir em algum momento do dia:
  // {
  //   aberto: true,
  //   mensagem: "aberto até 18:00" // mensagem com o horário que a loja fechará
  // }
  // o objeto retornado acima indica que a loja está aberta e fechará às 18:00

  const horarioEmString = (horario) => {

    // se o minuto for menor que 10, adiciona um 0 na frente
    if (horario.split(":")[1].length < 2) {
      horario = horario.split(":")[0] + ":0" + horario.split(":")[1];
    }

    const horarioSemPonto = horario.split(":").join("");

    return parseInt(horarioSemPonto);
  };

  const agora = new Date();
  const diaSemana = agora.getDay();
  const hora = agora.getHours();
  const minuto = agora.getMinutes();

  const semana = [
    "domingo",
    "segunda",
    "terca",
    "quarta",
    "quinta",
    "sexta",
    "sabado",
  ];

  var horarioSemana = horarios[semana[diaSemana]];
  var horarioAtual = horarioEmString(`${hora}:${minuto}`);

  const horarioAbertura = horarioEmString(horarioSemana.horario_abertura);
  const horarioFechamento = horarioEmString(horarioSemana.horario_fechamento);

  if (horarioSemana.aberto) {
    if (horarioAtual < horarioFechamento) {
      const horarioObj = {
        aberto: true,
        mensagem: `Aberto até ${horarioSemana.horario_fechamento}`,
      };
      return horarioObj;
    } else {
      return {
        aberto: false,
        mensagem: null,
      };
    }
  }
  return {
    aberto: false,
    mensagem: null,
  };
};
