import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Switch,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLoja } from "../../redux/lojaSlice";
import { toastIt } from "../../functions/pushNotif";
import UrlApi, { ApiToken } from "../../appconfig";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import global_theme from "../../assets/global/globalTheme";

const Entrega = () => {
  const [entregaAtiva, setEntregaAtiva] = useState(true); // [true, false
  const [distanciaMaxima, setDistanciaMaxima] = useState(0); // [true, false
  const [taxaPorKm, setTaxaPorKm] = useState(0);
  const [taxaMinima, setTaxaMinima] = useState(0);
  const [taxaGratis, setTaxaGratis] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const theme = global_theme;

  const loja = useSelector(selectLoja);
  const carregarDados = () => {
    setPageLoading(true);
    fetch(`${UrlApi}/loja/configuracoes/entrega`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    })
      .then((response) => {
        // console.log(response)
        return response.json();
      })
      .then((data) => {
        if (data && data.loja_id) {
          setTaxaPorKm(data.taxaPorKm);
          setTaxaMinima(data.taxaMinima);
          setTaxaGratis(data.taxaGratis);
          setEntregaAtiva(data.entregaAtiva);
          setDistanciaMaxima(data.distanciaMaxima);
        } else {
          // console.log("datanaosucesso! ");
        }
        setPageLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        toastIt("Erro ao carregar dados de entrega", "error");
        setPageLoading(false);
      });
  };

  const salvaTaxaEntrega = () => {
    setLoading(true);
    const newEntrega = {
      distanciaMaxima: distanciaMaxima ? parseFloat(distanciaMaxima) : 0,
      taxaPorKm: taxaPorKm ? parseFloat(taxaPorKm) : 0,
      taxaMinima: taxaMinima ? parseFloat(taxaMinima) : 0,
      faixaGratis: taxaGratis ? parseFloat(taxaGratis) : 0,
      entregaAtiva: entregaAtiva,
    };
    fetch(`${UrlApi}/loja/configuracoes/entrega`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
      body: JSON.stringify({
        newConfig: newEntrega,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("DATA: ", data);
        if (data.success) {
          toastIt("Dados de entrega salvos com sucesso", "success");
        } else {
          toastIt("Erro ao salvar dados de entrega", "error");
        }
        setLoading(false);
      })
      .catch((error) => {
        toastIt("Erro ao salvar dados de entrega", "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loja._id) {
      carregarDados();
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
        p: 2,
        color: theme.palette.primary.main,
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Entrega
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
            }}
          >
            Taxa de entrega
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              Ativar entrega
            </Typography>
            <Switch
              checked={entregaAtiva}
              onChange={() => setEntregaAtiva(!entregaAtiva)}
              sx={{
                color: theme.palette.primary.main,
                // how do i change the switch color?
                "& .Mui-checked": {
                  color: theme.palette.primary.main,
                },

                "& .MuiSwitch-track": {
                  backgroundColor: theme.palette.primary.main + " !important",
                },

                "& .MuiSwitch-thumb": {
                  backgroundColor: theme.palette.primary.main,
                },

              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
          <TextField
            label="Distância máxima (km)"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="number"
            sx={{ width: "100%", ...theme.inputs.primary }}
            value={distanciaMaxima}
            onChange={(e) => setDistanciaMaxima(e.target.value)}
            disabled={!entregaAtiva}
          />
          <TextField
            label="Taxa por km"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="number"
            sx={{ width: "100%", ...theme.inputs.primary }}
            value={taxaPorKm}
            onChange={(e) => setTaxaPorKm(e.target.value)}
            disabled={!entregaAtiva}
          />
          <TextField
            label="Taxa mínima"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="number"
            sx={{ width: "100%", ...theme.inputs.primary }}
            value={taxaMinima}
            onChange={(e) => setTaxaMinima(e.target.value)}
            disabled={!entregaAtiva}
          />
          <TextField
            label="Taxa grátis até (km)"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="number"
            sx={{ width: "100%", ...theme.inputs.primary }}
            value={taxaGratis}
            onChange={(e) => setTaxaGratis(e.target.value)}
            disabled={!entregaAtiva}
          />
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "100%",
            maxWidth: "300px",
            height: "50px",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
          onClick={() => salvaTaxaEntrega()}
        >
          {loading ? <CircularProgress size={24} /> : "Salvar"}
        </Button>
      </Box>
    </Box>
  );
};

export default Entrega;
