import NovoPedidoAudio from "./assets/content/sounds/novopedido.mp3";
// import PedjaLogo from "./assets/logoDefault.png";
import PedjaLogo from "./assets/logo.png";
//const UrlApi = "http://localhost:8000";
const UrlApi = 'https://vps-49809bff.vps.ovh.net.pedjadelivery.com.br:8000'
const SocketUrl = "http://localhost:8800";
//const SocketUrl = 'https://vps-49809bff.vps.ovh.net.pedjadelivery.com.br:8000'
const ApiToken = "bkUu9gkT29G7Ag5FgQbZ2t6g5AOnxvYX";
const senhaPadrao = "ped74!@";
const NovoPedidoAudioFile = NovoPedidoAudio;
const AppNomeCompacto = 'PedJá';
// const AppNomeCompacto = "App";
const AppNomeCompleto = "PedJá Delivery";
const AppLogo = PedjaLogo;

export default UrlApi;
export {
  ApiToken,
  senhaPadrao,
  NovoPedidoAudioFile,
  AppNomeCompacto,
  AppNomeCompleto,
  AppLogo,
  SocketUrl,
};
