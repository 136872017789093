// aqui será um componente de tema global

import { createTheme } from "@mui/material/styles";

const global_theme = createTheme({
    palette: {
        primary: {
            main: "#5C5C9C",
            contrast: "#9C94C4",
            light: "#dad5f5",
        },
        secondary: {
            main: "#8BCA8C",
            contrast : "#A3D5A4"
        },
        error: {
            main: "#FF0000",
            contrast: "#FFFFFF"
        }
    },

    typography: {
        fontFamily: "Roboto",
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: "0em",
        },
        h3: {
            fontSize: "1.75rem",
            fontWeight: 700,
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: 700,
            lineHeight: 1.235,
            letterSpacing: "0em",
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: 1.334,
            letterSpacing: "0em",
        },
    },

    inputs: {
        primary: {
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#5C5C9C"
                },
              },

              "& .MuiFormLabel-root": {
                "&.Mui-focused": {
                  color: "#5C5C9C",
                },
              },
        },

        secondary: {
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: "#8BCA8C",
                },
            },

            "& .MuiFormLabel-root": {
                "&.Mui-focused": {
                    color: "#8BCA8C",
                },
            },
        },

        error: {
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: "#FF0000",
                },
            },

            "& .MuiFormLabel-root": {
                "&.Mui-focused": {
                    color: "#FF0000",
                },
            },
        },
    },

    buttons: {
        rounded: {
            backgroundColor: "#5C5C9C",
            color: "#FFFFFF",
            borderRadius: 5,
            padding: "10px 20px",
            "&:hover": {
                backgroundColor: "#9C94C4",
            },
        },
    },
});

export default global_theme;