import { Box, Typography, Button, Modal } from "@mui/material"
import { useState } from "react"
import { toastIt } from "../../../../functions/pushNotif"
import UrlApi from "../../../../appconfig"
import { ApiToken } from "../../../../appconfig"
import global_theme from "../../../../assets/global/globalTheme"

// este componente é um modal que aparece quando o usuário clica em excluir uma categoria
const DeleteSub = ({ subcategoria, open, handleClose, updateAll }) => {
    const [loading, setLoading] = useState(false)
    const theme = global_theme

    const excluirSubcategoria = async () => {
        setLoading(true)
        fetch(`${UrlApi}/subcategorias/${subcategoria._id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ApiToken}`,
                "categoria_id": subcategoria.categoria_id
            }
        })
        .then((response) => response.json())
        .then((data) => {
            if (!data.error) {
                toastIt("Subcategoria excluída com sucesso", "success")
                updateAll()
                handleClose()
            } else {
                toastIt(data.error, "error")
            }
            setLoading(false)
        })
        .catch((err) => {
            // console.log(err)
            toastIt("Erro ao excluir subcategoria", "error")
            setLoading(false)
        })
    }

    if (!subcategoria) return null

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    backgroundColor: '#f5f5f5',
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    maxWidth: 500
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                    Excluir Subcategoria
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
                    Tem certeza que deseja excluir a subcategoria <b>{subcategoria.nome}</b>?
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem"
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={!loading ? excluirSubcategoria : () => {
                            toastIt("Aguarde o fim da operação atual", "error")
                        }}
                    >
                        {loading ? "Excluindo..." : "Excluir"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default DeleteSub