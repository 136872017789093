// esse será o slice que irá conter os dados do cliente, como carrinho, endereço, etc

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _id: "",
  nome: "",
  cpf: "",
  telefone: "",
  forma_pagamento: {
    tipo: "",
    troco: 0,
  },
  carrinho: {
    produtos: [],
    total: 0,
  },
  endereco: {
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
  },
};

export const clienteSlice = createSlice({
  name: "cliente",
  initialState: {
    ...initialState,
  },
  reducers: {
    changeCliente(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearCliente(state) {
      return {
        ...initialState,
      };
    },
    addToCart(state, { payload }) {
      const produtoIndex = state.carrinho.produtos.findIndex(
        (produto) => produto._id === payload._id
      );
      if (produtoIndex !== -1) {
        const produtos = [...state.carrinho.produtos];
        produtos[produtoIndex] = {
          ...produtos[produtoIndex],
          quantidade: produtos[produtoIndex].quantidade + payload.quantidade || 1,
        };
        return {
          ...state,
          carrinho: {
            produtos,
            total: state.carrinho.total + (payload.preco*(payload.quantidade || 1)),
          },
        };
      } else {
        const produtos = [
          ...state.carrinho.produtos,
          { ...payload, quantidade: payload.quantidade || 1 },
        ];
        return {
          ...state,
          carrinho: {
            produtos,
            total: state.carrinho.total + (payload.preco*(payload.quantidade || 1)),
          },
        };
      }
    },
    removeFromCart(state, { payload }) {
      const produtoIndex = state.carrinho.produtos.findIndex(
        (produto) => produto._id === payload._id
      );
      if (produtoIndex !== -1) {
        const produtos = [...state.carrinho.produtos];
        produtos[produtoIndex] = {
          ...produtos[produtoIndex],
          quantidade: produtos[produtoIndex].quantidade - 1,
        };
        if (produtos[produtoIndex].quantidade <= 0) {
          produtos.splice(produtoIndex, 1);
        }
        return {
          ...state,
          carrinho: {
            produtos,
            total: state.carrinho.total - payload.preco,
          },
        };
      } else {
        return state;
      }
    },
    clearCart(state) {
      return {
        ...state,
        carrinho: {
          produtos: [],
          total: 0,
        },
      };
    },
  },
});

export const { changeCliente, addToCart, removeFromCart, clearCart } =
  clienteSlice.actions;

export const selectCliente = (state) => state.cliente;
export const selectCarrinho = (state) => state.cliente.carrinho;

export default clienteSlice.reducer;
