import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    _id: "",
    loja_id: "",
    email: "",
    usuario: "",
    role: "",
    isLogged: false,
    token: "",
  },
  reducers: {
    changeUser(state, { payload }) {
      return {
        ...state,
        ...payload,
        isLogged: true,
      };
    },
    setUserToken(state, { payload }) {
      return {
        ...state,
        token: payload,
        isLogged: true,
      };
    },
    logout() {
      return {
        _id: "",
        loja_id: "",
        email: "",
        usuario: "",
        role: "",
        isLogged: false,
        token: "",
      };
    },
  },
});

export const { changeUser, logout, setUserToken } = userSlice.actions;

export const selectUser = (state) => state.user; // state.user.user is the user object

export default userSlice.reducer;
