import { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { toastIt } from "../../functions/pushNotif";
import UrlApi from "../../appconfig";
import { ApiToken } from "../../appconfig";
import global_theme from "../../assets/global/globalTheme";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/userSlice";
import { useSelector } from "react-redux";

const RedefinirSenha = () => {
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");

  const user = useSelector(selectUser);
  const theme = global_theme;
  const navigate = useNavigate();

  const validaSenha = () => {
    if (senha === "" || senha.length < 6 || confirmarSenha === "") {
      return false;
    } else if (senha !== confirmarSenha) {
      toastIt("As senhas não conferem", "error");
      return false;
    }
    return true;
  };

  const redefinirSenha = async () => {
    if (!validaSenha()) {
      return false;
    }
    fetch(`${UrlApi}/usuarios/redefinir_senha`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
        user_id: user._id,
      },
      body: JSON.stringify({
        nova_senha: senha,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.errorMessage) {
          toastIt("Senha redefinida com sucesso", "success");
          navigate("/pedidos");
        } else {
          toastIt(data.errorMessage, "error");
        }
      })
      .catch((error) => {
        toastIt(error, "error");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        p: 2,
        color: theme.palette.primary.main,
      }}
    >
      <Typography variant="h4" sx={{ mt: 2, mb: 2, fontWeight: "bold" }}>
        Alterar Senha
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
        Sua senha foi redefinida. Por favor, escolha uma nova senha.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          p: 2,
        }}
      >
        <TextField
          sx={{ mt: 2, mb: 2, ...theme.inputs.primary }}
          label="Senha"
          variant="outlined"
          type="password"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
        <TextField
          sx={{ mt: 2, mb: 2, ...theme.inputs.primary }}
          label="Confirmar senha"
          variant="outlined"
          type="password"
          value={confirmarSenha}
          onChange={(e) => setConfirmarSenha(e.target.value)}
        />
        <Button
          sx={{
            mt: 2,
            mb: 2,
            backgroundColor: theme.palette.primary.main,
            width: "100%",
            "&:hover": { backgroundColor: theme.palette.primary.contrast },
          }}
          variant="contained"
          onClick={() => redefinirSenha()}
        >
          Redefinir Senha
        </Button>
        <Typography variant="body2">
          <a href="/pedidos">Agora não</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default RedefinirSenha;
