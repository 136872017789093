export const formataData = (data) => {
  const dataLocal = new Date(data);

  const dia = dataLocal.getDate().toString().padStart(2, "0");
  const mes = (dataLocal.getMonth() + 1).toString().padStart(2, "0");
  const ano = dataLocal.getFullYear().toString();
  const hora = dataLocal.getHours().toString().padStart(2, "0");
  const minutos = dataLocal.getMinutes().toString().padStart(2, "0");

  const dataFormatada = `${dia}/${mes}/${ano} ${hora}h${minutos}`;
  return dataFormatada;
};

export const formataPreco = (preco) => {
  if(!preco) return "R$ 0,00";
  return preco.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const formataTelefone = (telefone) => {
  // recebe 1199999999 e retorna (11) 99999-9999

  const telefoneLimpo = telefone.replace(/\D/g, "");

  // checa se o telefone tem 10 ou 11 dígitos
  if (telefoneLimpo.length !== 10 && telefoneLimpo.length !== 11) {
    return telefone;
  }

  const ddd = telefoneLimpo.substring(0, 2);
  const primeiroBloco = telefoneLimpo.substring(2, 7);
  const segundoBloco = telefoneLimpo.substring(7, 11);

  return `(${ddd}) ${primeiroBloco}-${segundoBloco}`;
};

export const formataParaDataFormal = (dataString) => {
  // Crie um objeto Date a partir da string de data
  const data = new Date(dataString);

  // Array com os nomes dos dias da semana em português
  const diasDaSemana = [
    "Dom",
    "Seg",
    "Ter",
    "Qua",
    "Qui",
    "Sex",
    "Sáb",
  ];

  // Array com os nomes dos meses em português
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  // Obtenha o nome do dia da semana e do mês correspondente
  const diaDaSemana = diasDaSemana[data.getUTCDay()];
  const mes = meses[data.getUTCMonth()];

  // Obtenha o dia do mês e o ano
  const dia = data.getUTCDate();
  const ano = data.getUTCFullYear();
  const hora = data.getHours();
  const minutos = data.getMinutes();

  // Formate a data como uma string no formato desejado
  const dataFormatada = `${hora}h${minutos}, ${diaDaSemana}. ${dia} de ${mes} de ${ano}`;

  // Exiba a data formatada no console
  return dataFormatada;
};

export const formataEndereco = (endereco) => {
  // ex: Rua dos Bobos, 0 - Agua Branca · São Paulo/SP - 00000-000
  const { rua, numero, bairro, cidade, estado, cep } = endereco;

  const enderecoFormatado = `${rua}, ${numero} - ${bairro} · ${cidade}/${estado} - ${cep}`;
  return enderecoFormatado;
}

export const geraSlugProduto = (nome) => {
  const slug = nome
    .toLowerCase()
    .normalize("NFD") // Normaliza para remover acentos
    .replace(/[\u0300-\u036f]/g, "") // Remove caracteres diacríticos
    .replace(/ç/g, "c") // Substitui 'ç' por 'c'
    .replace(/ /g, "-"); // Substitui espaços por hífens

  return slug;
}