import { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";

const QuantityButton = ({ outsideHandle, produto, outsideQuantity, small }) => {
  const [quantity, setQuantity] = useState(1);

  const handleAdd = () => {
    setQuantity(quantity + 1);
  };

  const handleRemove = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    // isso será o botão de quantidade de produtos no carrinho
    // usando materialUI para estilizar
    <Box
      sx={{
        border: "1px solid #ccc",
        margin: small ? ".5em 0" :"1em 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "150px",
      }}
    >
      <Button
        onClick={() => {
          if (outsideHandle) {
            if(produto) {
              outsideHandle(produto, "remove")
            } else {
              outsideHandle("remove");
            }
          } else handleRemove();
        }}
        size="small"
        sx={{
          borderRight: "1px solid #ccc",
          borderRadius: 0,
        }}
      >
        -
      </Button>
      <Typography variant="body1" component="span" style={{ margin: "0 10px" }}>
        {outsideQuantity || quantity}
      </Typography>
      <Button
        onClick={() => {
          if (outsideHandle) {
            if(produto) {
              outsideHandle(produto, "add")
            } else {
              outsideHandle("add");
            }
          } else handleAdd();
        }}
        size="small"
        sx={{
          borderLeft: "1px solid #ccc",
          borderRadius: 0,
        }}
      >
        +
      </Button>
    </Box>
  );
};

export default QuantityButton;
