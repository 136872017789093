import React, { useEffect, useState } from "react";
import { Modal, Button, Box, Typography } from "@mui/material";
import OneSignal from "react-onesignal";
import global_theme from "../../assets/global/globalTheme";

const NotificacaoModal = () => {
  const [permitido, setPermitido] = useState(null);
  const [open, setOpen] = useState(true);
  const theme = global_theme;

  const pushPermitido = async () => {
    const isEnabled = await OneSignal.isPushNotificationsEnabled();
    setPermitido(isEnabled);
    return isEnabled;
  };

  useEffect(() => {
    if(Notification.permission === "granted"){
      OneSignal.registerForPushNotifications();
      setPermitido(true);
      setOpen(false);
      return;
    }
    pushPermitido();
  }, []);

  const solicitarPermissao = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        OneSignal.registerForPushNotifications();
        setPermitido(true);
      } else {
        setPermitido(false);
      }
    });
    setOpen(false);
  };

  if (permitido) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ color: theme.palette.primary.main }}
      id="onesignal-modal"
    >
      <Box
        sx={{
          width: 300,
          bgcolor: "background.paper",
          border: "2px solid " + theme.palette.secondary.main,
          boxShadow: 24,
          borderRadius: 2,
          p: 2,
          m: 1,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Notificações
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Para receber notificações de seus pedidos, clique no botão abaixo e permita o envio de notificações.
        </Typography>
        <Button
          variant="contained"
          onClick={solicitarPermissao}
          sx={{
            mt: 2,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
        >
          Entendi
        </Button>
      </Box>
    </Modal>
  );
};

export default NotificacaoModal;