import { Box, Button, Typography } from "@mui/material";
import "react-time-picker/dist/TimePicker.css";
import global_theme from "../../assets/global/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import { selectLoja, changeLoja } from "../../redux/lojaSlice";
import { Link } from "react-router-dom";

const Settings = () => {
  const theme = global_theme;
  const loja = useSelector(selectLoja);
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        padding: 2,
        color: theme.palette.primary.main,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          width: "100%",
          textAlign: "left",
          mb: 1,
        }}
      >
        Configurações
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "2em 0",
          borderTop: "1px solid #ccc",
          width: "100%",
          //border: "1px solid black",
          margin: 0,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textDecoration: "underline",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Link to="/configuracoes/horario-funcionamento">
            Horário de funcionamento
          </Link>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textDecoration: "underline",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Link to="/configuracoes/entrega">Entrega</Link>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textDecoration: "underline",
            borderBottom: "1px solid #ccc",
          }}
          >
          <Link to="/configuracoes/vendas">Vendas</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Settings;
