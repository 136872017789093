// este modal vai ser usado para buscar o cep do cliente e salvar no redux e localstorage

import { useState } from "react";
import {
  Modal,
  FormControl,
  Typography,
  TextField,
  Box,
  Button,
} from "@mui/material";
import global_theme from "../../assets/global/globalTheme";
import { toastIt } from "../../functions/pushNotif";
import { useSelector, useDispatch } from "react-redux";
import { selectCliente, changeCliente } from "../../redux/clienteSlice";

const CepModal = ({ open, handleClose }) => {
  const theme = global_theme;
  const cliente = useSelector(selectCliente);
  const dispatch = useDispatch();
  const [cep, setCep] = useState("");

  const handleSubmit = () => {
    if (cep.length !== 8) {
      toastIt("CEP inválido", "error");
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        const { localidade, uf, logradouro } = data;
        return fetch(
          `https://nominatim.openstreetmap.org/search.php?q=${logradouro},${localidade},${uf},Brasil&format=jsonv2`
        );
      })
      .then((response) => response.json())
      .then((data) => {
        const { lat, lon } = data[0];
        const novaLocalizacao = {
          latitude: lat,
          longitude: lon,
          cep: cep,
        };
        dispatch(changeCliente({ ...cliente, localizacao: novaLocalizacao }));
        const lsCliente = JSON.parse(localStorage.getItem("cliente"));
        localStorage.setItem(
            "cliente",
            JSON.stringify({ ...lsCliente, localizacao: novaLocalizacao })
        );
        handleClose(true);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Informe seu CEP
        </Typography>
        <FormControl fullWidth>
          <TextField
            label="CEP"
            variant="outlined"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
          />
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose(false)}
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CepModal;