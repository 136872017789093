import { Box, Typography } from "@mui/material";

const Clientes = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "#5C5C9C",
        }}
      >
        Em breve...
      </Typography>
    </Box>
  );
};

export default Clientes;
