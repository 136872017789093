import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import SignIn from "../pages/SignIn/SignIn";
import ClienteRoutes from "./ClienteRoutes";
import LojaRoutes from "./LojaRoutes";
import { toastIt } from "../functions/pushNotif";
import { useSelector } from "react-redux";
import { selectLoja } from "../redux/lojaSlice";
import { NovoPedidoAudioFile } from "../appconfig";
import { SocketUrl } from "../appconfig";
import io from "socket.io-client";

const RoutesConfig = () => {
  const loja = useSelector(selectLoja);

  //SOCKET
  var socket = null;
  
  useEffect(() => {
    if (loja?._id) {
      socket = io(SocketUrl, {
        transports: ["websocket"],
      });
      socket.on(`novopedido_${loja._id}`, (data) => {
        toastIt(`${data.data}`, "success");
        const audio = new Audio(NovoPedidoAudioFile);
        audio.play();
      });
    }
  }, []);

  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/signin" element={<SignIn />} />
      {ClienteRoutes}
      {LojaRoutes}
    </Routes>
  );
};

export default RoutesConfig;
