import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoja } from "../../../redux/lojaSlice";
import { selectUser } from "../../../redux/userSlice";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  CircularProgress,
  Chip,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CurrencyInput from "react-currency-input-field";
import global_theme from "../../../assets/global/globalTheme";
import { toastIt } from "../../../functions/pushNotif";
import AdicionaisForm from "../CriarProduto/Adicionais/AdicionaisForm";
import VariacoesForm from "../CriarProduto/Variacoes/VariacoesForm";
import { formataPreco } from "../../../functions/formatInfo";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";

const EditarProduto = () => {
  const [produto, setProduto] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [imagem, setImagem] = useState(null);

  const loja = useSelector(selectLoja);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { id } = useParams();

  const theme = global_theme;

  useEffect(() => {
    if (loja && user) {
      fetch(`${UrlApi}/loja/produtos/produto/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
          loja_id: loja.id,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setProduto(data);
          setLoading(false);
        })
        .catch((err) => console.log(''));
    }
  }, [loja]);

  const checkValidity = (propriedade) => {
    if(!propriedade || propriedade === "") {
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      produto.preco = produto.preco
        .replace("R$ ", "")
        .replace(".", "")
        .replace(",", ".");
    } catch (error) {
      // console.log(error);
    }

    if(!produto.nome || produto.nome === "") {
      toastIt("Preencha todos os campos", "error");
      setSubmitLoading(false);
      return;
    }

    if(!produto.descricao || produto.descricao === "") {
      toastIt("Preencha todos os campos", "error");
      setSubmitLoading(false);
      return;
    }

    if(!produto.preco || produto.preco === "") {
      toastIt("Preencha todos os campos", "error");
      setSubmitLoading(false);
      return;
    }

    fetch(
      `${UrlApi}/loja/produtos/produto/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
        },
        body: JSON.stringify({
          nome: produto.nome,
          descricao: produto.descricao,
          categoria: produto.categoria,
          subcategoria: produto.subcategoria,
          adicionais: produto.adicionais,
          variacoes: produto.variacoes,
          preco: parseFloat(produto.preco),
          preco_desconto: parseFloat(produto.preco_desconto),
          imagens: imagem !== null ? [imagem] : null,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toastIt("Produto editado com sucesso!", "success");
        setSubmitLoading(false);
        navigate("/catalogo");
      })
      .catch((err) => {
        toastIt("Erro ao editar produto", "error");
        setSubmitLoading(false);
      });
  };

  const setAdicionais = (value) => {
    setProduto({ ...produto, adicionais: value });
  };

  const setVariacoes = (value) => {
    setProduto({ ...produto, variacoes: value });
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      {!loading ? (
        <Box
          component="form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginBottom: "1rem",
              textAlign: "center",
              color: theme.palette.primary.main,
            }}
          >
            Editar Produto
          </Typography>
          <TextField
            label="Nome"
            variant="outlined"
            value={produto.nome}
            onChange={(e) => setProduto({ ...produto, nome: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),

              ...theme.inputs.primary,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="descricao"
            label="Descrição"
            name="descricao"
            autoComplete="descricao"
            autoFocus
            type={"text"}
            multiline
            rows={4} // set the number of rows to 4
            variant="outlined" // optional: set the variant of the TextField
            onChange={(e) => {
              if (error) setError(false);
              setProduto({ ...produto, descricao: e.target.value });
            }}
            value={produto.descricao}
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),

              ...theme.inputs.primary,
            }}
          />
          {/* Aqui terá um box com input de foto */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-end",
              justifyContent: "space-between",
              gap: "1rem",
              margin: "1rem 0",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "180px",
                width: "180px",
                border: "1px solid " + theme.palette.primary.main,
                borderRadius: "5px",
                position: "relative",
                padding: "0",
              }}
            >
              <img
                src={imagem || produto.imagens[0]}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
              <input
                type="file"
                name="imagem"
                id="imagem"
                style={{
                  display: "none",
                }}
                onChange={(e) => {
                  if (e.target.files[0]) {
                    // cria um objeto de leitura da imagem que será também enviada para o servidor
                    const reader = new FileReader();
                    // quando a imagem for carregada, executa o código abaixo
                    reader.onload = (e) => {
                      setImagem(e.target.result);
                    };

                    reader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
            </Box>
            <label
              htmlFor="imagem"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "5px",
                backgroundColor: theme.palette.secondary.main,
                color: "#fff",
                height: 50,
                width: "auto",
                padding: "0 1rem",

                "&:hover": {
                  backgroundColor: theme.palette.secondary.contrast,
                },
              }}
            >
              <AddIcon
                sx={{
                  fontSize: "1.5rem",
                }}
              />
              Selecionar imagem
            </label>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: ".5rem",
            }}
          >
            <TextField
              margin="normal"
              label="Preço"
              variant="outlined"
              name="preco"
              required
              value={produto.preco} // remove this
              onChange={(e) => {
                const preco = e.target.value.replace("R$ ", "");
                setProduto({ ...produto, preco });
              }}
              InputProps={{
                inputComponent: CurrencyInput,
                inputProps: {
                  prefix: "R$ ",
                  decimalSeparator: ",",
                  groupSeparator: ".",
                  decimalsLimit: 2,
                },
              }}
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),
                width: "100%",
                ...theme.inputs.primary,
              }}
            />
            <TextField
              margin="normal"
              label="Preço com desconto"
              variant="outlined"
              name="preco_desconto"
              value={produto.preco_desconto} // remove this
              onChange={(e) => {
                const preco_desconto = e.target.value.replace("R$ ", "");
                setProduto({ ...produto, preco_desconto: preco_desconto });
              }}
              InputProps={{
                inputComponent: CurrencyInput,
                inputProps: {
                  prefix: "R$ ",
                  decimalSeparator: ",",
                  groupSeparator: ".",
                  decimalsLimit: 2,
                },
              }}
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),
                width: "100%",
                ...theme.inputs.primary,
              }}
            />
          </Box>
          <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: ".5rem",
                marginTop: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={produto.variacoes !== null}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setVariacoes([]);
                      } else {
                        setVariacoes(null);
                      }
                    }}
                    inputProps={{ "aria-label": "Habilitar adicionais" }}
                  />
                }
                label="Habilitar variações"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={produto.adicionais !== null}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAdicionais([]);
                      } else {
                        setAdicionais(null);
                      }
                    }}
                    inputProps={{ "aria-label": "Habilitar adicionais" }}
                  />
                }
                label="Habilitar adicionais"
              />
            </Box>
            {produto.variacoes && (
              <VariacoesForm
                variacoes={produto.variacoes}
                setVariacoes={setVariacoes}
              />
            )}
            {produto.adicionais && (
              <AdicionaisForm
                adicionais={produto.adicionais}
                setAdicionais={setAdicionais}
              />
            )}
            {produto.variacoes && produto.variacoes.length > 0 && (
              // Aqui terá um box listando as variações
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="h6">Variações</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {produto.variacoes.map((variacao, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5rem",
                        padding: "1rem",
                        borderRadius: "5px",
                        backgroundColor: theme.palette.secondary.main,
                        color: "#fff",
                      }}
                      key={index}
                    >
                      <Typography variant="h6">{variacao.nome}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {variacao.opcoes.map((opcao) => (
                          <Chip
                            label={`${opcao.nome} - ${formataPreco(
                              opcao.preco
                            ).replace(".", ",")}`}

                            onDelete={() => {
                              const newVariacoes = produto.variacoes;
                              newVariacoes[index].opcoes = newVariacoes[
                                index
                              ].opcoes.filter((o) => o.nome !== opcao.nome);
                              // se não tiver mais opções, remove a variação
                              if (newVariacoes[index].opcoes.length === 0) {
                                setProduto({
                                  ...produto,
                                  variacoes: produto.variacoes.filter(
                                    (v) => v.nome !== variacao.nome
                                  ),
                                })
                                return
                              }

                              setVariacoes([...newVariacoes]);
                              setProduto({
                                ...produto,
                                variacoes: [...newVariacoes],
                              })
                            }}
                            key={opcao.nome}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {produto.adicionais && produto.adicionais.length > 0 && (
              // Aqui terá um box listando os adicionais
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="h6">Adicionais</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {produto.adicionais.map((adicional) => (
                    <Chip
                      label={`${adicional.nome} - ${formataPreco(adicional.preco)}`}
                      onDelete={() => {
                        const newAdicionais = produto.adicionais.filter(
                          (a) => a.nome !== adicional.nome
                        );
                        setProduto({ ...produto, adicionais: newAdicionais });
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              margin: "1rem 0",
            }}
          >
            <InputLabel
              id="categoria-label"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "1rem",
              }}
            >
              Categoria
            </InputLabel>
            <Select
              native
              value={produto.categoria?.nome}
              disabled
              id="categoria"
              labelId="categoria-label"
              sx={{
                width: "100%",
                ...theme.inputs.primary,
              }}
            >
              <option value={produto.categoria?.nome}>
                {produto.categoria?.nome}
              </option>
            </Select>
            <InputLabel
              id="subcategoria-label"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "1rem",
              }}
            >
              Subcategoria
            </InputLabel>
            <Select
              native
              value={produto.subcategoria?.nome}
              disabled
              labelId="subcategoria-label"
              sx={{
                width: "100%",
                ...theme.inputs.primary,
              }}
            >
              <option value={produto.subcategoria?.nome}>
                {produto.subcategoria?.nome}
              </option>
            </Select>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="error"
            sx={{
              width: "100%",
              marginBottom: "1rem",
              backgroundColor: theme.palette.primary.main,
              color: "#fff",

              "&:hover": {
                backgroundColor: theme.palette.primary.contrast,
              },
            }}
          >
            {submitLoading ? <CircularProgress /> : "Salvar"}
          </Button>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
};

export default EditarProduto;
