import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { changeLoja } from "../../../redux/lojaSlice";
import {
  Box,
  Typography,
  Container,
  Avatar,
  Divider,
  CardContent,
  CardMedia,
  Card,
  Grid,
  Modal,
  Button,
  Chip,
} from "@mui/material";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { addToCart } from "../../../redux/clienteSlice";
import { useDispatch } from "react-redux";
import { toastIt } from "../../../functions/pushNotif";
import DefaultProduct from "../../../assets/default_product.jpg";
import CloseIcon from "@mui/icons-material/Close";
import { formataPreco, geraSlugProduto } from "../../../functions/formatInfo";
import global_theme from "../../../assets/global/globalTheme";
import QuantityButton from "../../../components/Buttons/QuantityButton";
import CepModal from "../../../components/Modal/CepModal";
import NotificacaoModal from "../../../components/Modal/NotificacaoModal";
import { distanciaEntre } from "../../../functions/distanciaEntre";
import UrlApi from "../../../appconfig";
import { ApiToken, AppNomeCompacto } from "../../../appconfig";
import { lojaFuncionamento } from "../../../functions/lojaFuncionamento";
import { Helmet } from "react-helmet";

const HomeLoja = () => {
  const { id } = useParams();
  const [loja, setLoja] = useState({});
  const [lojaFunc, setLojaFunc] = useState(null); // {aberto, mensagem}
  const [produtos, setProdutos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [loading, setLoading] = useState(true);
  const [produto, setProduto] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState({});
  const [distancia, setDistancia] = useState(null);
  const [openCepModal, setOpenCepModal] = useState(false);
  const [lsCliente, setLsCliente] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const theme = global_theme;

  const getLoja = async () => {
    if (id) {
      fetch(`${UrlApi}/loja/slug/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          setLoja(data);
          const lojaSliceObj = {
            ...data,
          };
          const lojaEntregaConfig = await carregaConfiguracoes(data._id);
          if (lojaEntregaConfig) {
            lojaSliceObj.funcionamento.entrega = {
              ativo: lojaEntregaConfig.entregaAtiva,
              taxaPorKm: lojaEntregaConfig.taxaPorKm,
              taxaMinima: lojaEntregaConfig.taxaMinima,
              taxaGratis: lojaEntregaConfig.faixaGratis,
              distanciaMaxima: lojaEntregaConfig.distanciaMaxima,
            };
          }

          dispatch(changeLoja(lojaSliceObj));
          setLoading(false);
          document.title = data.nome + " | " + AppNomeCompacto;
          // muda o logo do site para o logo da loja
          document
            .querySelector("link[rel*='icon']")
            .setAttribute("href", data.imagem_perfil);
        })
        .catch((err) => console.log(''));
    }
  };

  const carregaConfiguracoes = async (loja_id) => {
    if (loja_id) {
      var result = null;
      const response = await fetch(`${UrlApi}/loja/configuracoes/entrega`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
          loja_id: loja_id,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          result = data;
        })
        .catch((err) => console.log(''));

      return result;
    }
  };

  const getCategorias = async () => {
    if (loja?._id) {
      fetch(`${UrlApi}/loja/categorias`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
          loja_id: loja._id,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCategorias(data);
        })
        .catch((err) => console.log(''));
    }
  };

  useEffect(() => {
    if (id) getLoja();

    var clienteLs = JSON.parse(localStorage.getItem("cliente"));

    if (clienteLs) {
      setLsCliente(clienteLs);
    } else {
      setOpenCepModal(true);
    }
  }, [id]);

  useEffect(() => {
    if (loja && loja._id) {
      if (lsCliente?.localizacao?.cep && loja.endereco.cep) {
        handleDistancia(lsCliente.localizacao.cep, loja.endereco.cep);
      }
      if (loja?.funcionamento?.horarios) {
        setLojaFunc(lojaFuncionamento(loja.funcionamento.horarios));
      }
      fetch(`${UrlApi}/loja/produtos/${loja._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // vamos organizar para que os produtos com desconto fiquem no topo e os sem estoque fiquem no final
          let produtosComDesconto = [];
          let produtosSemDesconto = [];
          let produtosSemEstoque = [];
          data.forEach((produto) => {
            if (produto.preco_desconto) {
              produtosComDesconto.push(produto);
            } else {
              produtosSemDesconto.push(produto);
            }
          });

          produtosSemDesconto.forEach((produto) => {
            if (produto.estoque <= 0) {
              produtosSemEstoque.push(produto);
            }
          });

          setProdutos([
            ...produtosComDesconto,
            ...produtosSemDesconto,
            ...produtosSemEstoque,
          ]);
        })
        .catch((err) => console.log(''));
      getCategorias();
    }
  }, [loja]);

  const handleOpen = (produto) => {
    const id = produto._id;
    // leva o usuario para a pagina /loja/slug/produto/id
    navigate(`/loja/${loja.slug}/produto/${geraSlugProduto(produto.nome)}/id/${id}`);
    return;

    const produtoClicado = produtos.find((produto) => produto._id === id);
    setProduto(produtoClicado);
    setProdutoSelecionado({
      _id: produtoClicado._id,
      nome: produtoClicado.nome,
      preco: produtoClicado.preco,
      preco_desconto: produtoClicado.preco_desconto,
      imagem: produtoClicado.imagens[0],
      variacoes:
        produtoClicado.variacoes && produtoClicado.variacoes.length
          ? [
              ...produtoClicado.variacoes?.map((variacao) => {
                return {
                  nome: variacao.nome,
                  value: "",
                };
              }),
            ]
          : [],
      adicionais: [],
      quantidade: 1,
    });
    document.body.classList.add("modal-open");
  };

  const handleClose = () => {
    setProduto(null);
    document.body.classList.remove("modal-open");
  };

  const adicionarAoCarrinho = () => {
    let preco = produtoSelecionado.preco_desconto || produtoSelecionado.preco; // preço inicial é o preço do produto
    let precoAntigo = produtoSelecionado.preco_desconto
      ? produtoSelecionado.preco
      : null;
    let produtoId = produto._id; // id inicial é o id do produto

    // checa se o produto tem alguma variação
    if (produto.variacoes) {
      // checa se todas as variacoes foram selecionadas
      for (const variacao of produto.variacoes) {
        const variacaoSelecionada = produtoSelecionado.variacoes.find(
          (ps) => ps.nome === variacao.nome
        );
        if (!variacaoSelecionada.value) {
          toastIt(`Selecione o(a) ${variacao.nome.toLowerCase()}`, "error");
          return;
        }
      }
      // percorre todas as variacoes selecionadas e define o preço do produto total como o preço da variacao mais cara
      produtoSelecionado.variacoes.forEach((variacao) => {
        const variacaoProduto = produto.variacoes.find(
          (vp) => vp.nome === variacao.nome
        );
        const opcao = variacaoProduto.opcoes.find(
          (opcao) => opcao.nome === variacao.value
        );

        // pega o id do produto e adiciona o nome da variacao e o nome da opcao
        produtoId += `-${variacao.nome}-${opcao.nome}`;
      });

      // checa se o produto tem adicionais
      if (produto.adicionais) {
        let preco = produto.preco; // preço inicial é o preço do produto
        produtoSelecionado.adicionais.forEach((adicional) => {
          produtoId += `-${adicional.nome}`;
        });
      }
    }

    toastIt("Produto adicionado ao carrinho", "success");
    dispatch(
      addToCart({
        ...produtoSelecionado,
        preco: parseFloat(preco.toFixed(2)),
        precoAntigo: precoAntigo,
        _id: produtoId,
      })
    );
    handleClose();
  };

  const handleVariacao = (variacao, opcao) => {
    const opcaoPreco = parseFloat(opcao.preco)

    setProdutoSelecionado({
      ...produtoSelecionado,
      variacoes: [
        ...produtoSelecionado.variacoes.map((v) => {
          if (v.nome === variacao.nome) {
            return {
              ...v,
              value: opcao.nome,
              preco: opcaoPreco
            };
          }
          return v;
        }),
      ],
      preco: opcaoPreco,
    });
  };

  const handleAdicional = (adicional) => {
    // se o adicional já estiver selecionado, remove ele, senão adiciona
    const adicionalPreco = parseInt(adicional.preco);
    if (produtoSelecionado.adicionais) {
      if (
        produtoSelecionado.adicionais.find((a) => a.nome === adicional.nome)
      ) {
        setProdutoSelecionado({
          ...produtoSelecionado,
          adicionais: produtoSelecionado.adicionais.filter(
            (a) => a.nome !== adicional.nome
          ),
          preco: produtoSelecionado.preco - adicionalPreco,
        });
      } else {
        setProdutoSelecionado({
          ...produtoSelecionado,
          adicionais: [...produtoSelecionado.adicionais, adicional],
          preco: produtoSelecionado.preco + adicionalPreco,
        });
      }
    } else {
      setProdutoSelecionado({
        ...produtoSelecionado,
        adicionais: [adicional],
      });
    }
  };

  const opcaoEstaSelecionada = (variacao, opcao) => {
    const variacoesProduto = produtoSelecionado.variacoes;
    if (variacoesProduto) {
      if (
        variacoesProduto.find((vp) => vp.nome === variacao.nome).value ===
        opcao.nome
      ) {
        return true;
      }
    }
    return false;
  };

  const adicionalEstaSelecionado = (adicional) => {
    if (produtoSelecionado.adicionais) {
      if (
        produtoSelecionado.adicionais.find((a) => a.nome === adicional.nome)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleQuantidade = (type) => {
    if (type === "add") {
      setProdutoSelecionado({
        ...produtoSelecionado,
        quantidade: produtoSelecionado.quantidade + 1,
      });
    } else {
      if (produtoSelecionado.quantidade > 1) {
        setProdutoSelecionado({
          ...produtoSelecionado,
          quantidade: produtoSelecionado.quantidade - 1,
        });
      }
    }
  };

  const handleDistancia = async (cep1, cep2) => {
    cep1 = cep1.replace("-", "");
    cep2 = cep2.replace("-", "");
    const calculaDistancia = await distanciaEntre(cep1, cep2);
    setDistancia(calculaDistancia);
  };

  return !loading ? (
    <Box
      sx={{
        minHeight: "90vh",
      }}
    >
      {/* Helmet tags */}

      <Helmet>
        <title>
          {loja.nome} | {AppNomeCompacto}
        </title>
        <meta
          name="description"
          content={`${loja.nome} - ${AppNomeCompacto}`}
        />
        <meta
          name="keywords"
          content={`${AppNomeCompacto}, ${loja.categoria}, ${loja.nome}`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content={AppNomeCompacto} />
        <meta name="url" content={window.location.href} />
        <meta name="og:title" content={`${loja.nome} | ${AppNomeCompacto}`} />
        <meta
          name="og:description"
          content={`${loja.nome} - ${AppNomeCompacto}`}
        />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:site_name" content={AppNomeCompacto} />
        <meta name="og:type" content="website" />
        <meta name="og:image" content={loja.imagem_perfil} />
        <meta name="og:image:alt" content={loja.nome} />
        <meta name="og:locale" content="pt_BR" />
        <meta name="og:locale:alternate" content="en_US" />
      </Helmet>

      <NotificacaoModal />
      <CepModal
        open={openCepModal}
        handleClose={(reload) => {
          if (reload) {
            window.location.reload();
          } else {
            setOpenCepModal(false);
          }
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${loja.banner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: "150px",
          paddingTop: 3,
          position: "relative",
          marginBottom: 12,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: "50%",
            position: "absolute",
          }}
        >
          <Avatar
            sx={{
              width: "100px",
              height: "100px",
              backgroundColor: "#f21",
              border: "4px solid #fff",
            }}
            src={loja.imagem_perfil}
            loading="lazy"
          />
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            sx={{
              fontWeight: "bold",
              marginBottom: 0,
            }}
          >
            {loja.nome}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.5,
            }}
          >
            {lojaFunc && (
              <Typography
                variant="span"
                component="div"
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  color: lojaFunc.aberto ? "#0f0" : "#f00",
                }}
              >
                {
                  // se a loja estiver aberta, mostra a mensagem de aberto, senão mostra a mensagem de fechado
                  lojaFunc.aberto ? lojaFunc.mensagem : "Fechado"
                }
              </Typography>
            )}
            {lsCliente?.localizacao?.cep && loja.endereco.cep && (
              <>
                <span
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  ·
                </span>
                <Typography
                  variant="span"
                  component="div"
                  gutterBottom
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    margin: 0,
                    color: theme.palette.secondary.contrast,
                  }}
                >
                  {distancia ? distancia : "Calculando..."}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Container
        sx={{
          marginTop: 3,
        }}
      >
        {/* <ProdutoDestaqueCard /> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "hidden",
          }}
        >
          {categorias.length > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginBottom: 3,
                overflowX: "auto",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCategoriaSelecionada(null);
                }}
                sx={{
                  minWidth: 100,
                  height: 40,
                  backgroundColor:
                    categoriaSelecionada === null
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  color:
                    categoriaSelecionada === null
                      ? theme.palette.primary.contrast
                      : theme.palette.primary.main,

                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrast,
                  },
                }}
              >
                Todos
              </Button>
              {categorias.map(
                (categoria, index) =>
                  produtos.filter((p) => p.categoria._id == categoria._id)
                    .length > 0 && (
                    <Button
                      key={index}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCategoriaSelecionada(categoria._id);
                      }}
                      sx={{
                        minWidth: "fit-content",
                        height: 40,
                        margin: 0,
                        backgroundColor:
                          categoriaSelecionada === categoria._id
                            ? theme.palette.primary.main
                            : theme.palette.primary.light,
                        color:
                          categoriaSelecionada === categoria._id
                            ? theme.palette.primary.contrast
                            : theme.palette.primary.main,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrast,
                        },
                      }}
                    >
                      {categoria.nome}
                    </Button>
                  )
              )}
            </Box>
          )}
        </Box>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 2, sm: 4, md: 10 }}
          sx={{
            marginBottom: 3,
          }}
        >
          {produtos?.map(
            (produto, index) =>
              (produto.categoria._id === categoriaSelecionada ||
                categoriaSelecionada == null) && (
                <Grid item xs={1} sm={2} md={2} key={index}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      height: 300,
                      paddingBottom: "0",
                      display: "grid",
                      cursor: produto.estoque > 0 ? "pointer" : "not-allowed",
                      position: "relative",
                      "&:hover": {
                        boxShadow: "0 0 10px #000",
                      },
                    }}
                    onClick={() => {
                      if (produto.estoque < 1) return;
                      handleOpen(produto);
                    }}
                  >
                    {produto.estoque < 1 && (
                      <>
                        <Box
                          sx={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            background: "black",
                            background: "rgba(0,0,0,0.8)",
                            filter: "blur(4px)",
                            "-o-filter": "blur(4px)",
                            "-ms-filter": "blur(4px)",
                            "-moz-filter": "blur(4px)",
                            "-webkit-filter": "blur(4px)",
                          }}
                        ></Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            fontWeight: "bold",
                            color: "#fff",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: loja.paleta[1],
                              color: loja.paleta[0],
                              textAlign: "center",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            Indisponível
                          </Typography>
                        </Typography>
                      </>
                    )}
                    <CardMedia
                      image={produto.imagens[0] || DefaultProduct}
                      title={produto.nome}
                      component="img"
                      sx={{
                        height: 180,
                        objectFit: "cover",
                        padding: 0.5,
                        backgroundColor: "#fff",
                        borderRadius: "5px",
                      }}
                    />
                    <CardContent sx={{ height: 120, p: 0.5 }}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: "1rem",
                        }}
                      >
                        {
                          // se o nome do produto for maior que 30 caracteres, ele corta e coloca 3 pontos
                          produto.nome.length > 30
                            ? produto.nome.substring(0, 30) + "..."
                            : produto.nome
                        }
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {
                          // se houver preço promocional, mostra o preço promocional destacado e o preço normal cortado, senão mostra só o preço normal
                          produto.preco_desconto ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  color: "#7a7a7a",
                                  fontWeight: "bold",
                                  fontSize: ".8rem",
                                }}
                              >
                                {formataPreco(produto.preco)}
                              </span>
                              <span
                                style={{
                                  color: "#f21",
                                  fontWeight: "bold",
                                  fontSize: "1.5rem",
                                }}
                              >
                                {formataPreco(produto.preco_desconto)}
                              </span>
                            </>
                          ) : (
                            <span
                              style={{
                                color: "#7a7a7a",
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                              }}
                            >
                              {formataPreco(produto.preco)}
                            </span>
                          )
                        }
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Container>
      <Modal
        open={Boolean(produto)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            maxWidth: "500px",
            maxHeight: "600px",
            padding: 3,
            overflow: "auto",
            borderRadius: "10px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CloseIcon
            sx={{
              alignSelf: "flex-end",
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                color: "#f21",
              },
            }}
            onClick={handleClose}
          />
          <img
            src={produto?.imagens[0] || DefaultProduct}
            alt={produto?.nome}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "300px",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
            }}
          >
            {produto?.nome}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "1.2em" }}
          >
            {produto?.descricao}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {produto?.variacoes?.map((variacao, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: 2,
                  paddingBottom: 1,
                  width: "100%",
                  maxWidth: "500px",
                  borderBottom: "1px solid #ccc",
                }}
                key={index}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    fontSize: ".9em",
                    fontWeight: "bold",
                    color: "#7a7a7a",
                    margin: 0,
                    textTransform: "uppercase",
                  }}
                >
                  {variacao.nome}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  {variacao?.opcoes?.map((opcao, index) => (
                    <Chip
                      label={opcao.nome}
                      key={index}
                      sx={{
                        backgroundColor: `${
                          opcaoEstaSelecionada(variacao, opcao)
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main
                        }`,
                        color: "#fff",
                        cursor: "pointer",
                        transition: "all 0.2s ease-in-out",

                        "&:hover": {
                          backgroundColor: theme.palette.secondary.contrast,
                          color: "#fff",
                        },
                      }}
                      onClick={() => handleVariacao(variacao, opcao)}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
          {/* aqui encontra-se os adicionais */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {produto && produto.adicionais && produto.adicionais.length > 0 ? (
              <Typography
                component="h5"
                variant="h5"
                sx={{
                  mt: 2,
                  fontSize: ".9em",
                  fontWeight: "bold",
                  color: "#7a7a7a",
                  margin: 0,
                  textTransform: "uppercase",
                }}
              >
                Adicionais
              </Typography>
            ) : null}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: 2,
                paddingBottom: 1,
                width: "100%",
                borderBottom: "1px solid #ccc",
              }}
            >
              {produto?.adicionais?.map((adicional, index) => (
                <Chip
                  label={
                    <span style={{ display: "inline-block" }}>{`${
                      adicional.nome
                    } +${formataPreco(adicional.preco)}`}</span>
                  }
                  sx={{
                    backgroundColor: `${
                      adicionalEstaSelecionado(adicional)
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }`,
                    color: "#fff",
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                    alignItems: "center",
                    padding: 0,
                    width: "fit-content",

                    "&:hover": {
                      backgroundColor: theme.palette.secondary.contrast,
                      color: "#fff",
                    },
                  }}
                  key={index}
                  onClick={() => handleAdicional(adicional)}
                />
              ))}
            </Box>
          </Box>
          {/* aqui encontra-se o botão de quantidade */}
          <QuantityButton
            outsideHandle={handleQuantidade}
            outsideQuantity={produtoSelecionado.quantidade}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            {
              // se houver preço promocional, mostra o preço promocional destacado e o preço normal cortado, senão mostra só o preço normal
              produtoSelecionado.preco_desconto ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#7a7a7a",
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    {formataPreco(produtoSelecionado.preco)}
                  </span>
                  <span
                    style={{
                      color: "#f21",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    {formataPreco(produtoSelecionado?.preco_desconto)}
                  </span>
                </>
              ) : (
                <span
                  style={{
                    color: "#7a7a7a",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  {formataPreco(produtoSelecionado?.preco)}
                </span>
              )
            }
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              marginTop: 2,
              width: "100%",
              transition: "all 0.2s ease-in-out",

              "&:hover": {
                backgroundColor: theme.palette.secondary.contrast,
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              if (!lojaFunc.aberto) {
                toastIt("Loja fechada no momento", "error");
                return;
              }
              if (produtoSelecionado.quantidade < 1) {
                toastIt("Selecione uma quantidade", "error");
                return;
              }
              adicionarAoCarrinho();
            }}
          >
            Adicionar ao carrinho
          </Button>
        </Box>
      </Modal>
    </Box>
  ) : (
    <LoadingPage />
  );
};

export default HomeLoja;
