import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Header from "./components/Header/Header";
import RoutesConfig from "./routes/RoutesConfig";
import CartButton from "./components/Cart/CartButton/CartButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OneSignalReact from "react-onesignal";

function App() {

  return (
    <Router>
      <Provider store={store}>
        <Header />
        <RoutesConfig />
        <CartButton />
        <ToastContainer />
      </Provider>
    </Router>
  );
}

export default App;
