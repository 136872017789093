import React from "react";
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SideBar from "../Sidebar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import {
  selectUser,
  logout,
  changeUser,
  setUserToken,
} from "../../redux/userSlice";
import { selectLoja, logoutLoja, changeLoja } from "../../redux/lojaSlice";
import { useDispatch, useSelector } from "react-redux";
import { toastIt } from "../../functions/pushNotif";
import LoadingPage from "../LoadingPage/LoadingPage";
import logo from "../../assets/logo.png";
import UrlApi from "../../appconfig";
import { ApiToken, AppNomeCompacto, AppLogo } from "../../appconfig";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector(selectUser);
  const loja = useSelector(selectLoja);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem("token-pedja");
    dispatch(logout());
    dispatch(logoutLoja());
    toastIt("Você desconectou de sua conta", "info");
    navigate("/signin");
  };

  const carregarLoja = async (id) => {
    const response = await fetch(`${UrlApi}/loja/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
    });
    const data = await response.json();
    dispatch(changeLoja(data));
    // muda o titulo e o icone da pagina
    document.title = data.nome + " | " + AppNomeCompacto;
    // muda o logo do site para o logo da loja
    document
      .querySelector("link[rel*='icon']")
      .setAttribute("href", data.imagem_perfil);
    navigate("/");
  };

  const checkUserToken = (user) => {
    const token = localStorage.getItem("token");
    if (token && !user.token) {
      setLoading(true);
      fetch(`${UrlApi}/login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiToken}`,
          token: token,
        },
      })
        .then(async (response) => {
          if (!response) {
            setLoading(false);
            return false;
          }
          setLoading(false);
          return response.json();
        })
        .then((data) => {
          if (data) {
            toastIt(`Bem-vindo de volta, ${data.data.usuario}!`, "success");
            dispatch(changeUser(data.data));
            dispatch(setUserToken(data.token));
            carregarLoja(data.data.loja_id);
            setLoading(false);
          }
        })
        .catch((err) => console.log(''));
    }
  };

  const handleLogin = () => {
    if (!user.token && localStorage.getItem("token")) {
      checkUserToken(user);
    } else navigate("/signin");
  };

  // se a página atual for a de meus pedidos, não mostra o header
  if (window.location.pathname === "/meuspedidos") return null;

  return (
    <>
      {loading && <LoadingPage />}
      <AppBar
        position="static"
        sx={{
          height: 60,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: `${loja.paleta[0] ? loja.paleta[1] : "#5C5C9C"}`,
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              mr: 2,
              position: "absolute",
              left: "1em",
            }}
            onClick={handleToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <SideBar open={open} handleToggle={handleToggle} />
      </AppBar>
    </>
  );
};

export default Header;
