import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CurrencyInput from "react-currency-input-field";
import { selectUser } from "../../../redux/userSlice";
import { selectLoja } from "../../../redux/lojaSlice";
import { useSelector } from "react-redux";
import { toastIt } from "../../../functions/pushNotif";
import global_theme from "../../../assets/global/globalTheme";
import AddIcon from "@mui/icons-material/Add";
import DefaultProduct from "../../../assets/default_product.jpg";
import AdicionaisForm from "./Adicionais/AdicionaisForm";
import VariacoesForm from "./Variacoes/VariacoesForm";
import { formataPreco } from "../../../functions/formatInfo";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";

const CriarProduto = () => {
  const [error, setError] = useState("");
  const [categoria, setCategoria] = useState(null);
  const [subcategoria, setSubcategoria] = useState(null);
  const [categorias, setCategorias] = useState([]); // [ {nome: "nome", subcategorias: []}
  const [variacoes, setVariacoes] = useState(null);
  const [adicionais, setAdicionais] = useState(null);
  const [imagem, setImagem] = useState(null);

  const user = useSelector(selectUser);
  const loja = useSelector(selectLoja);
  const theme = global_theme;

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === false) {
      setError("Preencha todos os campos!");
      return false;
    }

    if (!categoria || !subcategoria) {
      setError("Selecione uma categoria e subcategoria!");
      return false;
    }

    const data = new FormData(event.currentTarget);

    var { nome, descricao, valor, valor_desconto, estoque } =
      Object.fromEntries(data.entries());
    valor = valor.replace("R$ ", "").replace(".", "").replace(",", ".");
    valor_desconto = valor_desconto
      .replace("R$ ", "")
      .replace(".", "")
      .replace(",", ".");
    const produto = {
      nome,
      descricao,
      imagens: imagem ? [imagem] : [],
      preco: parseFloat(valor), // 1.000,00
      preco_desconto: valor_desconto ? parseFloat(valor_desconto) : 0.0,
      estoque: parseInt(estoque) || 0,
      categoria: {
        nome: categoria.nome,
        _id: categoria._id,
      },
      subcategoria: {
        nome: subcategoria.nome,
        _id: subcategoria._id,
      },
      variacoes,
      adicionais,
      criador: user.usuario,
      data_cadastro: new Date(),
    };

    if (loja._id && user.usuario) {
      fetch(`${UrlApi}/loja/produtos/${loja._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
        },
        body: JSON.stringify(produto),
      })
        .then((response) => response.json())
        .then((data) => {
          toastIt("Produto criado com sucesso!", "success");
          navigate("/catalogo");
        })
        .catch((error) => {
          toastIt("Erro ao criar produto!", "error");
          console.error("Error:", error);
        });
    }
  };

  const carregarCategorias = async () => {
    fetch(`${UrlApi}/loja/categorias`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCategorias(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (categorias.length) return;
    carregarCategorias();
  }, []);

  return (
    <Box
      sx={{
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          color: theme.palette.primary.main,
          textAlign: "center",
        }}
      >
        Cadastro de Produto
      </Typography>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="nome"
              autoFocus
              type={"text"}
              onChange={(e) => (error ? setError("") : null)}
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),

                ...theme.inputs.primary,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="descricao"
              label="Descrição"
              name="descricao"
              autoComplete="descricao"
              autoFocus
              type={"text"}
              multiline
              rows={4} // set the number of rows to 4
              variant="outlined" // optional: set the variant of the TextField
              onChange={(e) => (error ? setError("") : null)}
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),

                ...theme.inputs.primary,
              }}
            />
            {/* Aqui terá um box com input de foto */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-end",
                justifyContent: "space-between",
                gap: "1rem",
                margin: "1rem 0",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "180px",
                  width: "180px",
                  border: "1px solid " + theme.palette.primary.main,
                  borderRadius: "5px",
                  position: "relative",
                  padding: "0",
                }}
              >
                <img
                  src={imagem || DefaultProduct}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                />
                <input
                  type="file"
                  name="imagem"
                  id="imagem"
                  style={{
                    display: "none",
                  }}
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      // cria um objeto de leitura da imagem que será também enviada para o servidor
                      const reader = new FileReader();
                      // quando a imagem for carregada, executa o código abaixo
                      reader.onload = (e) => {
                        setImagem(e.target.result);
                      };

                      reader.readAsDataURL(e.target.files[0]);
                    }
                  }}
                />
              </Box>
              <label
                htmlFor="imagem"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "5px",
                  backgroundColor: theme.palette.secondary.main,
                  color: "#fff",
                  height: 50,
                  width: "auto",
                  padding: "0 1rem",
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: "1.5rem",
                  }}
                />
                Selecionar imagem
              </label>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: ".5rem",
                marginTop: "1rem",
                justifyContent: "space-between",
              }}
            >
              <TextField
                margin="normal"
                label="Preço"
                variant="outlined"
                name="valor"
                required
                InputProps={{
                  inputComponent: CurrencyInput,
                  inputProps: {
                    prefix: "R$ ",
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    decimalsLimit: 2,
                  },
                }}
                sx={{
                  ...(error && {
                    ...theme.inputs.error,
                  }),
                  ...theme.inputs.primary,
                  width: "100%",
                }}
              />
              <TextField
                margin="normal"
                label="Preço com desconto"
                variant="outlined"
                name="valor_desconto"
                InputProps={{
                  inputComponent: CurrencyInput,
                  inputProps: {
                    prefix: "R$ ",
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    decimalsLimit: 2,
                  },
                }}
                sx={{
                  ...(error && {
                    ...theme.inputs.error,
                  }),
                  ...theme.inputs.primary,
                  width: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: ".5rem",
                marginTop: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={variacoes !== null}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setVariacoes([]);
                      } else {
                        setVariacoes(null);
                      }
                    }}
                    inputProps={{ "aria-label": "Habilitar adicionais" }}
                  />
                }
                label="Habilitar variações"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adicionais !== null}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAdicionais([]);
                      } else {
                        setAdicionais(null);
                      }
                    }}
                    inputProps={{ "aria-label": "Habilitar adicionais" }}
                  />
                }
                label="Habilitar adicionais"
              />
            </Box>
            {variacoes && (
              <VariacoesForm
                variacoes={variacoes}
                setVariacoes={setVariacoes}
              />
            )}
            {adicionais && (
              <AdicionaisForm
                adicionais={adicionais}
                setAdicionais={setAdicionais}
              />
            )}
            {variacoes && variacoes.length > 0 && (
              // Aqui terá um box listando as variações
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="h6">Variações</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {variacoes.map((variacao, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5rem",
                        padding: "1rem",
                        borderRadius: "5px",
                        backgroundColor: theme.palette.secondary.main,
                        color: "#fff",
                      }}
                      key={index}
                    >
                      <Typography variant="h6">{variacao.nome}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {variacao.opcoes.map((opcao) => (
                          <Chip
                            label={`${opcao.nome} - ${formataPreco(
                              opcao.preco
                            ).replace(".", ",")}`}
                            onDelete={() => {
                              const newVariacoes = variacoes;
                              newVariacoes[index].opcoes = newVariacoes[
                                index
                              ].opcoes.filter((o) => o.nome !== opcao.nome);
                              // se não tiver mais opções, remove a variação
                              if (newVariacoes[index].opcoes.length === 0) {
                                setVariacoes(
                                  variacoes.filter(
                                    (v) => v.nome !== variacao.nome
                                  )
                                );
                                return;
                              }

                              setVariacoes([...newVariacoes]);
                            }}
                            key={opcao.nome}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {adicionais && adicionais.length > 0 && (
              // Aqui terá um box listando os adicionais
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="h6">Adicionais</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {adicionais.map((adicional) => (
                    <Chip
                      label={`${adicional.nome} - ${formataPreco(
                        adicional.preco
                      )}`}
                      onDelete={() => {
                        const newAdicionais = adicionais.filter(
                          (a) => a.nome !== adicional.nome
                        );
                        setAdicionais(newAdicionais);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            <InputLabel id="categoria-label">Categoria</InputLabel>
            <Select
              labelId="categoria-label"
              id="categoria"
              name="categoria"
              value={categoria?.nome || "sergio"}
              onChange={(e) => {
                const categoriax = categorias.find(
                  (c) => c.nome === e.target.value
                );
                setCategoria(categoriax);
              }}
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),
                ...theme.inputs.primary,
                width: "100%",
                mb: "1rem",
                color: "#000",
              }}
            >
              {categorias.map((categoria) => (
                <MenuItem value={categoria.nome}>{categoria.nome}</MenuItem>
              ))}
            </Select>
            {categoria &&
              categoria.subcategorias &&
              categoria.subcategorias.length > 0 && (
                <>
                  <InputLabel id="subcategoria-label">Subcategoria</InputLabel>
                  <Select
                    labelId="subcategoria-label"
                    id="subcategoria"
                    name="subcategoria"
                    label="Subcategoria"
                    value={subcategoria?.nome || ""}
                    fullWidth
                    onChange={(e) => {
                      const subcategoria = categoria.subcategorias.find(
                        (c) => c.nome === e.target.value
                      );
                      setSubcategoria(subcategoria);
                    }}
                  >
                    {categoria.subcategorias.map((subcategoria) => (
                      <MenuItem value={subcategoria.nome}>
                        {subcategoria.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            {error && <Typography color="error">{error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                color: "#fff",

                "&:hover": {
                  backgroundColor: theme.palette.primary.contrast,
                  color: "#fff",
                },
              }}
            >
              Cadastrar
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CriarProduto;
