import { useState } from "react";
import { Box, Typography, Button, TextField, Select } from "@mui/material";
import UrlApi from "../../../../appconfig";
import { ApiToken } from "../../../../appconfig";
import global_theme from "../../../../assets/global/globalTheme";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/userSlice";
import { toastIt } from "../../../../functions/pushNotif";

const CriarUsuario = () => {
  const [usuario, setUsuario] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [role, setRole] = useState("");

  const user = useSelector(selectUser);
  const theme = global_theme;
  const navigate = useNavigate();

  const validaUsuario = () => {
    if (
      usuario === "" ||
      usuario.length < 3 ||
      email === "" ||
      senha === "" ||
      role === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const criarUsuario = async () => {
    if (!validaUsuario()) {
      return false;
    }
    fetch(`${UrlApi}/usuarios`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
        loja_id: user.loja_id,
      },
      body: JSON.stringify({
        usuario,
        email,
        senha,
        role,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.errorMessage) {
          toastIt("Usuário criado com sucesso", "success");
          setUsuario("");
          setEmail("");
          setSenha("");
          setRole("");
          navigate("/usuarios");
        } else {
          toastIt(data.errorMessage, "error");
        }
      })
      .catch((err) => console.log(''));
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
      >
        Criar usuário
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "1rem",
          maxWidth: 500,
        }}
      >
        <TextField
          label="Usuário"
          variant="outlined"
          value={usuario}
          onChange={(e) => setUsuario(e.target.value)}
          sx={{
            ...theme.inputs.primary,
          }}
        />
        <TextField
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            ...theme.inputs.primary,
          }}
        />
        <TextField
          label="Senha"
          variant="outlined"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          sx={{
            ...theme.inputs.primary,
          }}
          type="password"
        />
        <Select
          native
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{
            ...theme.inputs.primary,

            color: theme.palette.primary.main,

          }}
        >
          <option value="" disabled>
            Selecione uma função
          </option>
          <option value="admin">Administrador</option>
          <option value="funcionario">Funcionário</option>
        </Select>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary.main,

            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
          onClick={criarUsuario}
        >
          Criar Usuário
        </Button>
      </Box>
    </Box>
  );
};

export default CriarUsuario;
