import { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toastIt } from "../../../functions/pushNotif";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";
import { selectLoja } from "../../../redux/lojaSlice";
import global_theme from "../../../assets/global/globalTheme";
import ExcluirCategoria from "./ExcluirCategoria/ExcluirCategoria";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import * as XLSX from "xlsx";

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemExcluir, setItemExcluir] = useState(null); // item que será excluído
  const loja = useSelector(selectLoja);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = global_theme;

  const carregarCategorias = async () => {
    setLoading(true);
    fetch(`${UrlApi}/loja/categorias`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          toastIt("Erro ao carregar categorias", "error");
          return false;
        }
      })
      .then((data) => {
        setCategorias(data);
      })
      .catch((err) => {
        // console.log(err);
        toastIt("Erro ao carregar categorias", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      convertToJson(file);
    }
  };

  const convertToJson = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      // Assuming you want to convert the first sheet of the XLSX file
      const sheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      fetch(`${UrlApi}/loja/categorias/importacao`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiToken}`,
          loja_id: loja._id,
          dataAgora: new Date()
        },
        body: JSON.stringify({categorias: jsonData}),
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.success) {
            toastIt(data.message, "error");
          } else {
            toastIt(data.message, "success");
            carregarCategorias();
          }
        })
        .catch((err) => {
          // console.log(err);
          toastIt("Erro ao importar categorias. Tente novamente mais tarde.", "error");
        });
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (loja) {
      carregarCategorias();
    }
  }, [loja]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <ExcluirCategoria
        categoria={itemExcluir}
        open={itemExcluir !== null}
        handleClose={() => {
          setItemExcluir(null);
        }}
        updateAll={() => {
          setCategorias(
            categorias.filter((categoria) => categoria._id !== itemExcluir._id)
          );
          setItemExcluir(null);
        }}
      />
      <Typography
        variant="h4"
        sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        Categorias
      </Typography>
      <Box
        sx={{
          display: "flex",
          mt: "1rem",
          mb: "1rem",
          gap: ".5em",
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate("/catalogo/categorias/criar")}
          sx={{
            backgroundColor: theme.palette.primary.main,

            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
        >
          Criar Categoria
        </Button>
        <label
          htmlFor="upload-xlsx"
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="body1"
            title="Importar categorias"
            sx={{
              color: "#fff",
              backgroundColor: "#4fc26e",
              borderRadius: "4px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: ".5em"
            }}
          >
            <FileOpenIcon />
          </Typography>
          <input
            id="upload-xlsx"
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </label>
      </Box>
      {categorias.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {categorias.map((categoria) => (
            <Box
              key={categoria._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
                padding: "1rem",
                border: "1px solid #ccc",
                borderRadius: "0.5rem",

                // screen width <= 768px
                "@media screen and (max-width: 768px)": {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",

                  // screen width <= 768px
                  "@media screen and (max-width: 768px)": {
                    width: "100%",
                  },
                }}
              >
                {categoria.nome}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                Subcategorias: {categoria.subcategorias?.length || 0}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.contrast,
                    },
                  }}
                  onClick={() =>
                    navigate(`/catalogo/categorias/editar/${categoria._id}`)
                  }
                >
                  Editar
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#f44336",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#d32f2f",
                    },
                  }}
                  onClick={() => setItemExcluir(categoria)}
                >
                  Excluir
                </Button>
                {/* <Button
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.contrast,
                    },
                  }}
                >
                  Ver Produtos
                </Button> */}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        >
          Nenhuma categoria cadastrada
        </Typography>
      )}
    </Box>
  );
};

export default Categorias;
