import { useState } from "react";
import { ShoppingBag } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { selectCarrinho } from "../../../redux/clienteSlice";
import { selectUser } from "../../../redux/userSlice";
import { selectLoja } from "../../../redux/lojaSlice";
import { useSelector } from "react-redux";
import CartPage from "../CartPage/CartPage";
import "./index.css";


const CartButton = () => {
  const [clicked, setClicked] = useState(false);
  const [open, setOpen] = useState(false);
  
  const user = useSelector(selectUser);
  const loja = useSelector(selectLoja);
  const carrinho = useSelector(selectCarrinho);

  const SmallAvatar = styled(Avatar)(
    ({ theme }) => css`
      width: 22px;
      height: 22px;
      border: 2px solid ${theme.palette.background.paper};
      background-color: ${loja.paleta[0] ? loja.paleta[0] : "#f21"};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    `
  );

  const handleOpen = () => {
    setOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleClose = () => {
    setOpen(false);
    document.body.classList.remove("modal-open");
  };

  // se a página atual for de home ou checkout, não exibe o botão
  const path = window.location.pathname;
  if (path === "/" || path === "/checkout" || path === "/meuspedidos") return null;

  return (
    <>
      {loja._id && !user._id ? (
        <Badge
        className={clicked ? "cart-btn" : ""}
        onClick={() => {
          setClicked(true);
          handleOpen();
          setTimeout(() => {
            setClicked(false);
          }, 500);
        }}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          carrinho.produtos.length > 0 ? (
            <SmallAvatar alt="5" src="/static/images/avatar/1.jpg">
              <span
                style={{
                  fontSize: "0.8rem",
                  margin: 0,
                  padding: 0,
                }}
              >
                  {carrinho.produtos.length}
              </span>
            </SmallAvatar>
          ) : (
            ""
          )
        }
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
        }}
      >
        <Avatar
          src="/static/images/avatar/2.jpg"
          sx={{
            backgroundColor: `${loja.paleta[0] ? loja.paleta[0] : "#f21"}`,
            width: "60px",
            height: "60px",
            color: `${loja.paleta[0] ? loja.paleta[1] : "#fff"}`
          }}
        >
          <ShoppingBag />
        </Avatar>
      </Badge>
      ) : null
      }
      <CartPage open={open} setOpen={setOpen} handleClose={handleClose} />
    </>
  );
};

export default CartButton;
