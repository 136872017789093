import React from "react";
import { Avatar, Backdrop, Box, Button, Typography } from "@mui/material";
import { toastIt } from "../../../functions/pushNotif";
import { useNavigate } from "react-router-dom";
import defaultProduct from "../../../assets/default_product.jpg";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";

const ExcluirProduto = ({ handleClose, open, produto, updateAll }) => {
  const navigate = useNavigate();

  const handleSubmit = (id) => {
    fetch(
      `${UrlApi}/loja/produtos/${produto._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${ApiToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toastIt(data.message, "success");
        updateAll();
      })
      .catch((err) => console.log(''));
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <Box
        component="form"
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "#fff",
          borderRadius: "12px",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <Typography variant="h5" sx={{ textAlign: "center", color: "#f22f22" }}>
            Você tem certeza que deseja excluir este produto?
        </Typography>
        <Avatar
            sx={{
                width: "100px",
                height: "100px",
                margin: "auto",
                marginBottom: "1rem",
                border: "1px solid #ccc",
            }}
            alt={produto?.nome}
            src={produto?.imagens[0] || defaultProduct }
        />
        <Typography variant="h6" sx={{ textAlign: "center", color: "#000" }}>
            {produto?.nome}
        </Typography>
        <Button
          variant="contained"
          color="error"
          sx={{
            width: "100%",
          }}
          onClick={() => handleSubmit(produto._id)}
        >
          Confirmar
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
          }}
          onClick={() => handleClose()}
        >
          Cancelar
        </Button>
      </Box>
    </Backdrop>
  );
};

export default ExcluirProduto;