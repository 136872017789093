import { Box, Typography } from "@mui/material";
import React from "react";

const Descricao = ({ produto }) => {
  console.log(produto);

  return (
    produto && (
      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#000",
            fontWeight: "400",
            marginBottom: 1,
            maxWidth: "100%",
          }}
        >
          <pre style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}>{produto.descricao}</pre>
        </Typography>
      </Box>
    )
  );
};

export default Descricao;
