import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoja } from "../../../../redux/lojaSlice";
import global_theme from "../../../../assets/global/globalTheme";
import LoadingPage from "../../../../components/LoadingPage/LoadingPage";
import UrlApi from "../../../../appconfig";
import { ApiToken } from "../../../../appconfig";
import { toastIt } from "../../../../functions/pushNotif";
import { Box, Button, Typography, TextField, InputLabel } from "@mui/material";
import NewSub from "../Subcategorias/NewSub";
import DeleteSub from "../Subcategorias/DeleteSub";
import DeleteIcon from '@mui/icons-material/Delete';

const EditarCategoria = () => {
  const [categoria, setCategoria] = useState(null);
  const [openSub, setOpenSub] = useState(false); // modal para criar subcategoria
  const [subExcluir, setSubExcluir] = useState(null); // modal para excluir subcategoria
  const [loading, setLoading] = useState(false); // loading da página
  const loja = useSelector(selectLoja);
  const navigate = useNavigate();
  const { id } = useParams();

  const theme = global_theme;

  const carregarCategoria = async () => {
    if (!id) return;
    setLoading(true);
    fetch(`${UrlApi}/loja/categorias/${loja._id}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setCategoria(data);
            setLoading(false);
          });
        } else {
          response.json().then((data) => {
            toastIt(data.message, "error");
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        toastIt(error.message, "error");
        setLoading(false);
      });

    setLoading(false);
  };

  useEffect(() => {
    if (id) carregarCategoria();
  }, [id]);

  const salvarCategoria = async () => {
    if (!categoria.nome || !categoria.descricao) {
      toastIt("Preencha todos os campos", "error");
      return;
    }

    fetch(`${UrlApi}/loja/categorias/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
      body: JSON.stringify(categoria),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            toastIt(data.message, "success");
            navigate("/catalogo/categorias");
          });
        } else {
          response.json().then((data) => {
            toastIt(data.message, "error");
          });
        }
      })
      .catch((error) => {
        toastIt(error.message, "error");
      });
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <NewSub
        open={openSub}
        handleClose={() => setOpenSub(null)}
        updateAll={carregarCategoria}
        categoria={categoria}
      />
      <DeleteSub
        open={subExcluir !== null}
        handleClose={() => setSubExcluir(null)}
        updateAll={carregarCategoria}
        subcategoria={subExcluir}
      />
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", color: theme.palette.primary.main, mb: 2 }}
      >
        Editar Categoria
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <TextField
          variant="outlined"
          value={categoria?.nome}
          onChange={(e) => setCategoria({ ...categoria, nome: e.target.value })}
          sx={{ mb: 2, width: "100%" }}
          label={<InputLabel shrink={true}>Nome</InputLabel>}
        />
        <TextField
          label={<InputLabel shrink={true}>Descrição</InputLabel>}
          variant="outlined"
          value={categoria?.descricao}
          onChange={(e) =>
            setCategoria({ ...categoria, descricao: e.target.value })
          }
          sx={{ mb: 2, width: "100%" }}
          inputProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 2, width: "100%" }}
          onClick={salvarCategoria}
        >
          Salvar
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: theme.palette.primary.main,
              mr: 2,
            }}
          >
            Subcategorias
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,

              "&:hover": {
                backgroundColor: theme.palette.primary.contrast,
              },
            }}
            onClick={() => setOpenSub(true)}
          >
            Novo
          </Button>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: .5,
          width: "100%",
          maxWidth: "500px",
        }}>
        {categoria?.subcategorias?.length ? (
          categoria?.subcategorias?.map((subcategoria) => (
            <Box
              key={subcategoria._id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "500px",
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "5px",
                p: 1,
              }}
              >
              <Typography
                variant="span"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  mr: 2,
                }}
              >
                {subcategoria.nome}
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={() => setSubExcluir(subcategoria)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          ))
        ) : (
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: theme.palette.primary.main,
              mr: 2,
            }}
            >
            Nenhuma subcategoria
          </Typography>
        )}
            </Box>
      </Box>
    </Box>
  );
};

export default EditarCategoria;
