// essa será a página que irá listar os pedidos

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoja } from "../../../redux/lojaSlice";
import {
  formataData,
  formataPreco,
  formataEndereco,
} from "../../../functions/formatInfo";
import { toastIt } from "../../../functions/pushNotif";
import global_theme from "../../../assets/global/globalTheme";
import io from "socket.io-client";
import WhatsappButton from "../../../components/Whatsapp/WhatsappButton";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ReplayIcon from "@mui/icons-material/Replay";
import UrlApi from "../../../appconfig";
import { ApiToken, SocketUrl } from "../../../appconfig";
import {
  statusIcons,
  statusIconsComponent,
} from "../../../assets/icons/status-pedido/status_icons";
import EsperandoPedido from "../../../assets/esperando-pedido.png";

// TODO: A ordem de pedidos será feita pelo botão que troca de ícone, tendo opçoes:
// recentes, antigos e status
// arrowDown => import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown // ';
// arrowUp => import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp' //  ;

const ListaPedidos = () => {
  // aqui vou fazer uma verificação de dispositivo para saber se é mobile ou desktop

  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth < 600) {
      // manda o usuario para a página de pedidos mobile sem perder o redux state
      navigate("/m/pedidos", { replace: true });
    }
  }, []);

  const theme = global_theme;
  const [pedidos, setPedidos] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([]); //
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState("");
  const [filtro, setFiltro] = useState("todos");
  const [ordem, setOrdem] = useState("recentes");
  const [lojaConfig, setLojaConfig] = useState(null);
  const loja = useSelector(selectLoja);


  var socket = null;
  useEffect(() => {
    if (loja?._id) {
      socket = io(SocketUrl, {
        transports: ["websocket"],
      });
      socket.on(`novopedido_${loja._id}`, (data) => {
        toastIt(`${data.data}`, "success");
        getPedidos();
      });
      getPedidos();
    }
  }, []);

  const getPedidos = async () => {
    setLoading(true);
    const response = await fetch(`${UrlApi}/pedidos/loja`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    });
    const data = await response.json();
    setLoading(false);
    setPedidos(data.reverse());
    setPedidosFiltrados(data);
    getConfig();
  };

  const getConfig = () => {
    fetch(`${UrlApi}/loja/configuracoes/vendas`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.loja_id) {
          setLojaConfig(data);
        } else {
          // console.log("datanaosucesso! ");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // aqui é uma função para configurar a mensagem que será enviada ao cliente
  const configurarMensagem = () => {
    const mensagem = lojaConfig?.msgPrimeiroContato;
    const cliente = pedido.cliente;
    var mensagemFormatada = mensagem;

    // aqui eu substituo as variáveis da mensagem pelos valores do cliente
    // as variaveis são: <nomecliente>
    try {
      mensagemFormatada = mensagemFormatada.replace(
        "<nomecliente>",
        cliente.nome
      );
    } catch (err) {
      // console.log(err);
    }

    return mensagemFormatada;
  };

  const changePedido = (id) => {
    const pedido = pedidos.find((pedido) => pedido._id === id);
    // console.log(pedido);
    setPedido(pedido);
  };

  // aqui um objeto com todos os status possíveis e o que vem depois de cada um
  const todosStatus = {
    "aguardando confirmação": "em preparação",
    "em preparação": "a caminho",
    "a caminho": "entregue",
  };

  const todosStatusRetirada = {
    "aguardando confirmação": "em preparação",
    "em preparação": "aguardando retirada",
    "aguardando retirada": "retirado",
  }

  const statusToIcon = {
    "aguardando confirmação": statusIcons.Pendente,
    "em preparação": statusIcons.Pendente,
    "a caminho": statusIcons.EmRota,
    "aguardando retirada": statusIcons.AguardandoRetirada,
    entregue: statusIcons.Entregue,
    cancelado: statusIcons.Cancelado,
  };

  // Aqui é uma função que irá controlar o status do pedido
  const changeStatus = async (cancelado) => {
    if (
      cancelado === "cancelado" &&
      pedido.status.toLowerCase() === "aguardando confirmação"
    ) {
      const confirm = window.confirm(`Deseja cancelar o pedido?`);

      if (confirm) {
        const response = await fetch(
          `${UrlApi}/pedidos/loja/${pedido._id}/update_status`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${ApiToken}`,
            },
            body: JSON.stringify({
              status: "cancelado",
            }),
          }
        );

        const data = await response.json();

        if (data.status === "success") {
          getPedidos();
          setPedido(null);
          toastIt("Pedido cancelado com sucesso!", "success");
        } else {
          toastIt("Erro ao cancelar pedido!", "error");
        }
      }

      return;
    }

    // aqui eu pego o status atual do pedido
    const statusAtual = pedido.status.toLowerCase();

    // aqui eu pego o próximo status do pedido
    const proximoStatus = pedido.pagamento?.formaEntrega?.toUpperCase() === "RETIRADA" ?
    todosStatusRetirada[statusAtual] : todosStatus[statusAtual];

    // aqui, antes de enviar a requisição, eu peço confirmação ao usuário
    const confirm = window.confirm(
      `Deseja alterar o status do pedido para ${proximoStatus}?`
    );

    // se o usuário confirmar, eu envio a requisição
    if (confirm) {
      const response = await fetch(
        `${UrlApi}/pedidos/loja/${pedido._id}/update_status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ApiToken}`,
          },
          body: JSON.stringify({
            status: proximoStatus,
          }),
        }
      );

      const data = await response.json();

      // se a requisição for bem sucedida, eu atualizo a página
      if (data.status === "success") {
        getPedidos();
        setPedido(null);
        toastIt(data.message, "success");
      } else {
        toastIt(data.message, "error");
      }
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  const handleFiltro = (valor) => {
    setFiltro(valor);
    if (valor === "todos") {
      setPedidosFiltrados(pedidos);
    } else {
      const pedidosFiltrados = pedidos.filter(
        (pedido) => pedido.status.toLowerCase() === valor
      );
      setPedidosFiltrados(pedidosFiltrados);
    }
  };

  const handlePesquisa = (e) => {
    setPesquisa(e.target.value);
    if (filtro === "todos") {
      const pedFiltrados = pedidos.filter((pedido) =>
        pedido.cliente.nome.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPedidosFiltrados(pedFiltrados);
    } else {
      const pedFiltrados = pedidos.filter(
        (pedido) =>
          pedido.status.toLowerCase() === filtro &&
          pedido.cliente.nome
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setPedidosFiltrados(pedFiltrados);
    }
  };

  const handleOrdem = (valor) => {
    if (valor === "recentes") {
      const pedidosOrdenados = pedidosFiltrados.sort((a, b) => {
        return new Date(b.data_pedido) - new Date(a.data_pedido);
      });
      setPedidosFiltrados(pedidosOrdenados);
    } else {
      const pedidosOrdenados = pedidosFiltrados.sort((a, b) => {
        return new Date(a.data_pedido) - new Date(b.data_pedido);
      });

      setPedidosFiltrados(pedidosOrdenados);
    }

    setOrdem(valor);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        padding: 0,
      }}
    >
      {/* Aqui terá um sidebar com os pedidos */}
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "#f2ffff",
        }}
      >
        <Grid
          item
          xs={4}
          md={4}
          sx={{
            backgroundColor: "#f5f5f5",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {/* Aqui terá o sidebar */}

          <Box
            sx={{
              display: "flex",

              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                padding: ".8em",
              }}
            >
              Pedidos
            </Typography>
            <ReplayIcon
              sx={{
                color: theme.palette.primary.main,
                cursor: "pointer",
                transition: "all .3s",

                "&:hover": {
                  color: theme.palette.secondary.main,
                  transform: "rotate(-360deg)",
                },
              }}
              title="Atualizar"
              onClick={getPedidos}
            />
          </Box>
          {/* Aqui terá um filtro de pedidos */}
          <Box
            sx={{
              padding: "0 1em",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Pesquisar"
              variant="outlined"
              sx={{
                width: "100%",
                backgroundColor: "#fff",

                ...theme.inputs.primary,
              }}
              value={pesquisa}
              onChange={(e) => handlePesquisa(e)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#fff",
                position: "relative",
              }}
            >
              <InputLabel
                id="filtro-label"
                sx={{
                  ...theme.inputs.primary,
                  color: "#666",
                  position: "absolute",
                  top: "-14px",
                  left: "10px",
                  fontSize: ".8em",
                }}
              >
                Filtro
              </InputLabel>
              <Select
                native
                value={filtro}
                onChange={(e) => handleFiltro(e.target.value)}
                labelId="filtro-label"
                id="select-filter"
                inputProps={{
                  id: "filter-select",
                }}
                sx={{
                  ...theme.inputs.primary,
                }}
              >
                {[
                  "todos",
                  ...Object.keys(todosStatus),
                  "entregue",
                  "cancelado",
                ].map((status, index) => (
                  <option
                    key={index}
                    value={status}
                    style={{
                      color: theme.palette.primary.main,

                      "&:hover": {
                        backgroundColor: theme.palette.secondary.main,
                        color: "#fff",
                      },
                    }}
                  >
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </option>
                ))}
              </Select>
            </Box>
            {ordem === "recentes" ? (
              <ArchiveIcon
                sx={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                  transition: "all .3s",

                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
                onClick={() => handleOrdem("antigos")}
              />
            ) : (
              <UnarchiveIcon
                sx={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                  transition: "all .3s",

                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
                onClick={() => handleOrdem("recentes")}
              />
            )}
          </Box>
          <Box
            component="ul"
            sx={{
              listStyle: "none",
              padding: ".5em 0",
              margin: 0,
            }}
          >
            {pedidosFiltrados.map((order, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  backgroundColor:
                    order.status === "cancelado" ? "#d6322f" : "#fff",
                  padding: "1em .5em",
                  cursor: "pointer",
                  // se esse pedido estiver selecionado, eu adiciono uma borda na direita
                  borderRight:
                    order._id === pedido?._id
                      ? "10px solid " + theme.palette.primary.contrast
                      : "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",

                  "&:hover": {
                    backgroundColor:
                      order.status === "cancelado"
                        ? "#d62222"
                        : theme.palette.primary.light,
                  },
                }}
                onClick={() => changePedido(order._id)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5em",
                  }}
                >
                  <Typography variant="body1">{order.cliente.nome}</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1em",
                      color: order.status === "cancelado" ? "#fff" : "#666",
                    }}
                  >
                    {formataData(order.data_pedido)}
                  </Typography>
                </Box>
                <img
                  src={statusToIcon[order.status.toLowerCase()]}
                  alt={order.status}
                  style={{
                    width:
                      order.status.toLowerCase() === "cancelado"
                        ? "42px"
                        : "60px",
                    height:
                      order.status.toLowerCase() === "cancelado"
                        ? "42px"
                        : "60px",
                  }}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          md={8}
          sx={{
            backgroundColor: "#fff",
            padding: "1em",
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          {/* Aqui terá o conteúdo do pedido */}
          {pedido ? (
            <Typography
              variant="h4"
              sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
            >
              Detalhes do pedido
            </Typography>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  mb: 5,
                }}
              >
                Você verá os detalhes do pedido aqui...
              </Typography>
              <img
                src={EsperandoPedido}
                alt="Esperando Pedido"
                style={{
                  width: "100%",
                  height: "100%",

                  maxWidth: "300px",
                  maxHeight: "300px",
                  objectFit: "contain",
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: "#fff",
              marginTop: "1em",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {pedido && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    p: "1em",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: "1.2em",
                    }}
                  >
                    ID: {pedido._id}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      fontSize: "1.2em",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1em",
                        mr: 0.5,
                      }}
                    >
                      Status:
                    </Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {
                        // maiuscula
                        pedido.status.charAt(0).toUpperCase() +
                          pedido.status.slice(1)
                      }
                    </span>
                    <img
                      src={statusToIcon[pedido.status.toLowerCase()]}
                      alt={pedido.status}
                      style={{
                        width: "36px",
                        height: "36px",
                        marginLeft: ".5em",
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1em",
                      }}
                    >
                      · Data: {formataData(pedido.data_pedido)}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "1.4em",
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                >
                  Cliente
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    padding: "1em",
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.2em",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1em",
                          fontWeight: "bold",
                        }}
                      >
                        Nome:{" "}
                      </span>{" "}
                      {pedido.cliente.nome} ·
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.2em",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1em",
                          fontWeight: "bold",
                        }}
                      >
                        Telefone:{" "}
                      </span>{" "}
                      {pedido.cliente.telefone}
                      <WhatsappButton
                        numero={pedido.cliente.telefone}
                        mensagem={
                          lojaConfig?.msgPrimeiroContato
                            ? configurarMensagem(lojaConfig?.msgPrimeiroContato)
                            : "Olá, tudo bem?"
                        }
                      />{" "}
                      ·
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.2em",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1em",
                          fontWeight: "bold",
                        }}
                      >
                        CPF:{" "}
                      </span>{" "}
                      {pedido.cliente.cpf}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2em",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1em",
                        fontWeight: "bold",
                      }}
                    >
                      Endereço:{" "}
                    </span>{" "}
                    {formataEndereco(pedido.cliente.endereco)}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "1.4em",
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                >
                  Itens do pedido
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    padding: "1em 0",
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    borderRadius: "5px",
                  }}
                >
                  {pedido.produtos.map((produto, index) => (
                    <Box
                      key={index}
                      sx={{
                        padding: "1em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1.2em",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontSize: "1.2em" }}>
                          <span style={{ fontWeight: "bold" }}>
                            x{produto.quantidade}
                          </span>{" "}
                          {produto.nome}
                        </Typography>
                        {produto.variacoes && (
                          // aqui vai mostrar as variações do produto, mas apenas a propriedade value. Elas serão divididas por aquele ponto que aparece no meio
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            sx={{
                              fontSize: ".8em",
                            }}
                          >
                            {Object.entries(produto.variacoes).map(
                              (variacao, index) => {
                                if (index === 0) {
                                  return variacao[1].value;
                                } else {
                                  return ` . ${variacao[1].value}`;
                                }
                              }
                            )}
                          </Typography>
                        )}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          sx={{
                            fontSize: ".8em",
                          }}
                        >
                          {produto.adicionais.length > 0 &&
                            produto.adicionais.map((adicional, index) => {
                              if (index === 0) {
                                return "+" + adicional.nome;
                              } else {
                                return `, +${adicional.nome}`;
                              }
                            })}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ color: "#00a35c", fontSize: "1.2em" }}
                      >
                        {formataPreco(produto.preco)}
                      </Typography>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 1em",
                    }}
                  >
                    <Typography variant="h6">Subtotal</Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#00a35c", fontWeight: "bold" }}
                    >
                      {formataPreco(pedido.pagamento?.subtotal)}
                    </Typography>
                  </Box>
                  {pedido.pagamento?.formaEntrega?.toUpperCase() ===
                    "ENTREGA" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 1em",
                      }}
                    >
                      <Typography variant="h6">Frete</Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: "#00a35c", fontWeight: "bold" }}
                      >
                        {formataPreco(pedido.pagamento?.frete) || "Grátis"}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 1em",
                    }}
                  >
                    <Typography variant="h6">Total</Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#00a35c", fontWeight: "bold" }}
                    >
                      {formataPreco(pedido.pagamento?.total)}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "1.4em",
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                >
                  Pagamento e Observações
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "1em",
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2em",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Forma de Entrega:{" "}
                    </span>
                    {pedido.pagamento?.formaEntrega?.toUpperCase()}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2em",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Forma de Pagamento:{" "}
                    </span>
                    {pedido.pagamento?.forma_pagamento.toUpperCase()}
                  </Typography>
                  {pedido.pagamento?.forma_pagamento === "dinheiro" && (
                    <Typography variant="h6">
                      Troco para: {formataPreco(pedido.pagamento?.troco)}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2em",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Observações: </span>{" "}
                    {pedido.observacoes}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "1em",
                    padding: "1em",
                  }}
                >
                  {![
                    "cancelado",
                    "finalizado",
                    "entregue",
                    "retirado",
                  ].includes(pedido.status.toLowerCase()) && (
                    <>
                      <Button
                        variant="contained"
                        sx={{
                          border: "1px solid #f25555",
                          backgroundColor: "#fff",
                          color: "#f25555",
                          width: 250,
                          marginTop: "1em",
                          fontSize: "1.2em",

                          "&:hover": {
                            backgroundColor: "#f25555",
                            color: "#fff",
                          },
                        }}
                        color="error"
                        onClick={() => changeStatus("cancelado")}
                      >
                        Cancelar Pedido
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          border: "1px solid #00a35c",
                          backgroundColor: "#00a35c",
                          color: "#fff",
                          width: 250,
                          marginTop: "1em",
                          fontSize: "1.2em",

                          "&:hover": {
                            backgroundColor: "#027d48",
                            border: "1px solid #027d48",
                            color: "#fff",
                          },
                        }}
                        onClick={changeStatus}
                      >
                        {pedido.pagamento?.formaEntrega?.toUpperCase() ===
                        "RETIRADA" ? (
                          todosStatusRetirada[pedido.status.toLowerCase()]
                        ) : (
                          todosStatus[pedido.status.toLowerCase()]
                        )}
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListaPedidos;
