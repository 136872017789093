import { Route } from "react-router-dom";
import PrivateRoutes from "../PrivateRoutes";
import Settings from "../../pages/Settings/Settings";
import HorarioFuncionamento from "../../pages/Settings/HorarioFuncionamento";
import Entrega from "../../pages/Settings/Entrega";
import Vendas from "../../pages/Settings/Vendas";

const ConfiguracoesRoutes = [
  <Route
    path="/configuracoes"
    element={
      <PrivateRoutes>
        <Settings />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/configuracoes/horario-funcionamento"
    element={
      <PrivateRoutes>
        <HorarioFuncionamento />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/configuracoes/entrega"
    element={
      <PrivateRoutes>
        <Entrega />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/configuracoes/vendas"
    element={
      <PrivateRoutes>
        <Vendas />
      </PrivateRoutes>
    }
  />,
];

export default ConfiguracoesRoutes;
