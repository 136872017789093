import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Avatar,
  Grid,
} from "@mui/material";
import { selectCliente } from "../../../redux/clienteSlice";
import { useSelector } from "react-redux";
import { validaCpf } from "../../../functions/validaInfo";
import Home from "@mui/icons-material/Home";
import global_theme from "../../../assets/global/globalTheme";
import {
  formataParaDataFormal,
  formataPreco,
} from "../../../functions/formatInfo";
import defaultLoja from "../../../assets/default_product.jpg";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";

const MeusPedidos = () => {
  const cliente = useSelector(selectCliente);
  const [pedidos, setPedidos] = useState([]);
  const [cpf, setCpf] = useState(cliente?.cpf);
  const [cpfEdit, setCpfEdit] = useState(false);
  const [error, setError] = useState(null);

  const theme = global_theme;

  const getPedidos = async () => {
    if (!validaCpf(cpf)) {
      setError("CPF inválido");
      return;
    }

    const response = await fetch(UrlApi+"/pedidos/cliente", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        cpf: cpf,
      },
    });

    const data = await response.json();
    

    setPedidos(
      data.sort((a, b) => {
        return new Date(b.data_pedido) - new Date(a.data_pedido);
      })
    );
  };

  useEffect(() => {
    if (!pedidos.length && cliente?.cpf) {
      getPedidos();
    }
  }, []);

  function buildWhatsAppLink(phoneNumber, message) {
    const encodedMessage = encodeURIComponent(message);
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
  }
  const clickSuport = (pedidoId, telefone) => {
    // essa funcao vai chamar o whatsapp em outra aba com o numero da loja
    const mensagem = `Olá, gostaria de suporte para o pedido ${pedidoId}`;
    const whatsappLink = buildWhatsAppLink(telefone, mensagem);
    window.open(whatsappLink, "_blank");
  }

  return (
    <Container
      sx={{
        padding: "0 !important",
        minWidth: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          padding: 0,
          width: "100%",
          gap: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
            height: 80,
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottomRightRadius: 20,
          }}
        >
          Meus Pedidos
        </Typography>
        <Link to="/">
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: theme.palette.primary.main,
              height: 80,
              width: 100,
              borderRadius: 0,
              borderBottomLeftRadius: 20,
              fontSize: "1.5em !important",
            }}
          >
            <Home
              sx={{
                fontSize: "1.5em !important",
              }}
            />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "0 2em",
          width: "100%",
          height: "auto",
          marginBottom: 3,
        }}
      >
        {!cpfEdit ? (
          <>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.main,
                width: "100%",
                textAlign: "left",
              }}
            >
              CPF: {cpf}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                height: 40,
                width: 100,
                borderRadius: 5,
                marginLeft: 2,
              }}
              onClick={() => setCpfEdit(true)}
            >
              Alterar
            </Button>
          </>
        ) : (
          <>
            <TextField
              id="cpf"
              label="Insira seu CPF"
              variant="outlined"
              // q: how can I make this input only accept numbers?
              inputProps={{
                inputMode: "numeric",
                maxLength: 11,
              }}
              // q: how can I make this input max 11 characters?
              //
              sx={{
                width: "100%",
                height: 40,
                margin: 0,

                ...theme.inputs.primary,
              }}
              onChange={(e) => setCpf(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                height: 40,
                width: 100,
                borderRadius: 5,
                marginLeft: 2,
              }}
              onClick={() => {
                setCpfEdit(false);
                setError(null);
                getPedidos();
              }}
            >
              Buscar
            </Button>
          </>
        )}
      </Box>
      {error && (
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#f24440",
            width: "100%",
            textAlign: "center",
          }}
        >
          {error}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0 2em",
          width: "100%",
          height: "auto",
          marginTop: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: theme.palette.primary.main,
            width: "100%",
            textAlign: "left",
          }}
        >
          Histórico
        </Typography>
        {pedidos.length ? (
          pedidos.map((pedido) => (
            <Box
              key={pedido.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 1,
                width: "100%",
                minHeight: 300,
                border: `2px solid ${theme.palette.secondary.main}`,
                borderRadius: 3,
                marginBottom: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: 50,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    color: theme.palette.primary.main,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {formataParaDataFormal(pedido.data_pedido)}
                </Typography>
                <Avatar
                  src={pedido.loja?.imagem_perfil || defaultLoja}
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    border: "2px solid " + theme.palette.secondary.main,
                  }}
                >
                  mimimi
                </Avatar>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.primary.main,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {pedido.status}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    minHeight: 80,
                    padding: "0 1em",
                    overflowX: "auto",
                    border: "1px solid " + theme.palette.primary.contrast,
                    borderRadius: 3,
                  }}
                >
                  {pedido.produtos.map((produto, index) => (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        key={index}
                        variant="body1"
                        sx={{
                          fontWeight: 400,
                          color: theme.palette.primary.main,
                          textAlign: "left",
                        }}
                      >
                        x{produto.quantidade} {produto.nome}
                      </Typography>
                      <Typography
                        key={index + produto.nome}
                        variant="body1"
                        sx={{
                          fontWeight: 400,
                          color: theme.palette.primary.main,
                          textAlign: "right",
                        }}
                      >
                        {formataPreco(produto.preco)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    color: theme.palette.primary.main,
                    width: "100%",
                    textAlign: "left",
                    height: 30,
                  }}
                >
                  Total: {formataPreco(pedido.pagamento.total)}
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                sx={{ width: "100%", marginLeft: "-6px !important" }}
              >
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: 400,
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: 5,
                      width: "100%",
                    }}
                  >
                    Refazer pedido
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: 400,
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: 5,
                      width: "100%",
                    }}
                  >
                    Avaliar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: 400,
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: 5,
                      width: "100%",
                    }}
                    onClick={() => {
                      clickSuport(pedido._id, pedido.loja.telefone)
                    }}
                  >
                    Ajuda
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: theme.palette.primary.main,
              width: "100%",
              textAlign: "center",
            }}
          >
            Nenhum pedido encontrado
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default MeusPedidos;
