import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/favicon.png";
import BannerImage from "../../assets/images/banner_pedja.webp";
import global_theme from "../../assets/global/globalTheme";
import UrlApi, { ApiToken, AppNomeCompacto } from "../../appconfig";
import { toastIt } from "../../functions/pushNotif";
import { Helmet } from "react-helmet";

const Home = () => {
  const [lojas, setLojas] = useState([]);
  const [lojasPesquisa, setLojasPesquisa] = useState([]);
  const [pesquisa, setPesquisa] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.querySelector("link[rel*='icon']").setAttribute("href", Logo);
  });

  const theme = global_theme;

  const carregarLojasProximas = (lat, lon, raio) => {
    fetch(`${UrlApi}/loja/raio/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        latitude: lat,
        longitude: lon,
        raio: raio,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setLojas(data);
        });
      }
    });
  };

  useEffect(() => {
    if (lojas.length > 0) return;
    const cliente = JSON.parse(localStorage.getItem("cliente"));

    if (cliente) {
      if (cliente.localizacao?.latitude && cliente.localizacao?.longitude) {
        // a latitude e longitude do cliente já estão cadastradas, mas estao em string
        const latitude = parseFloat(cliente.localizacao.latitude);
        const longitude = parseFloat(cliente.localizacao.longitude);
        carregarLojasProximas(latitude, longitude, 25);
      }
    }
  }, []);

  const pesquisarLojas = () => {
    setLoading(true);
    if (pesquisa.length < 3)
      return toastIt("Digite pelo menos 3 caracteres", "error");

    fetch(`${UrlApi}/loja/busca/${pesquisa}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setLojasPesquisa(data);
          if (data.length === 0) toastIt("Nenhuma loja encontrada", "error");
        });
      }
    })
    .catch((err) => {
      toastIt("Erro ao pesquisar lojas", "error");
    }
    )
    .finally(() => setLoading(false));
  };

  return (
    <Box>
      <Helmet>
        <title>PedJá Delivery</title>
        <meta name="description" content="Os melhores serviços você encontra aqui" />
        <meta name="keywords" content="delivery, pedja, pedja delivery, pedja delivery app, pedja app," />
        <meta name="robots" content="All" />
        <meta name="language" content="Portuguese" />
        <meta name="author" content="Insight Agency" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="url" content={window.location.href} />
        <meta name="og:title" content="Pedja Delivery" />
        <meta name="og:description" content="Os melhores serviços você encontra aqui" />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:site_name" content={AppNomeCompacto} />
        <meta name="og:type" content="website" />
        <meta name="og:image" content="https://i.ibb.co/vHdf49q/Large-Tile-scale-125.png" />
        <meta name="og:image:alt" content={AppNomeCompacto} />
        <meta name="og:locale" content="pt_BR" />
        <meta name="og:locale:alternate" content="en_US" />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          height: "60vh",
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          p: "2em 4em",

          // screen width <= 768px
          "@media (max-width: 768px)": {
            p: "1em 2em",
            
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "900px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#fff",
              fontSize: "2rem",
              fontWeight: "bold",
              lineHeight: "1.2",
            }}
          >
            Os melhores serviços você encontra aqui
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              fontSize: "1rem",
              fontWeight: "bold",
              lineHeight: "1.2",
            }}
          >
            Peça e receba no conforto de sua casa
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          p: "2em 4em",

          // screen width <= 768px
          "@media (max-width: 768px)": {
            p: "1em",
          }
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
            fontSize: "1.5rem",
            fontWeight: "bold",
            lineHeight: "1.2",
            mb: "1em",

            // screen width <= 768px
            "@media (max-width: 768px)": {
              fontSize: "1.2rem",
            }
          }}
        >
          Procure por uma loja
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mb: "1em",

            // screen width <= 768px
            "@media (max-width: 768px)": {
              flexDirection: "column",
              gap: "1em",
            }

            // how can I detect the screen width in react?
            // https://stackoverflow.com/questions/36862334/how-can-i-detect-the-screen-width-in-react
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Digite o nome da loja"
            InputProps={{ style: { borderRadius: 25 } }}
            sx={{
              ...theme.inputs.primary,
              borderRadius: 10,
              width: "100%",
              maxWidth: 500,
            }}
            value={pesquisa}
            onChange={(e) => setPesquisa(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.main,
              borderRadius: 10,
              width: 140,
              ml: "1em",
              "&:hover": {
                backgroundColor: theme.palette.primary.contrast,
              },

              // screen width <= 768px
              "@media (max-width: 768px)": {
                width: "100%",
                ml: 0,
              }
            }}
            onClick={() => {
              if (loading) return;
              pesquisarLojas();
            }}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  width: 20,
                  height: 20,
                }}
              />
            ) : (
              "Buscar"
            )}
          </Button>
        </Box>
        {lojasPesquisa.length > 0 && (
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.5rem",
              fontWeight: "bold",
              lineHeight: "1.2",
              mb: "1em",
            }}
          >
            Resultados
          </Typography>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "1em",
            mt: 2,
          }}
        >
          {lojasPesquisa.map((loja, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: 120,
                height: 120,
                transition: "all 0.2s ease-in-out",

                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <Link to={`loja/${loja.slug}`}>
                <img
                  src={loja.imagem_perfil}
                  alt="Mimimi"
                  style={{
                    width: "100%",
                    maxWidth: 500,
                    height: "auto",
                    borderRadius: "10px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color: "#000",
                    fontWeight: "bold",
                    width: "100%",
                    fontSize: "1rem",
                  }}
                >
                  {loja.nome}
                </Typography>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          p: "2em 4em",

          // screen width <= 768px
          "@media (max-width: 768px)": {
            p: "1em",
          }
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
            fontSize: "1.5rem",
            fontWeight: "bold",
            lineHeight: "1.2",
            mb: "1em",
          }}
        >
          Ou veja as lojas mais próximas
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "1em",
          }}
        >
          {lojas.map((loja, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: 120,
                height: 120,
                transition: "all 0.2s ease-in-out",

                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <Link to={`loja/${loja.slug}`}>
                <img
                  src={loja.imagem_perfil}
                  alt="Mimimi"
                  style={{
                    width: "100%",
                    maxWidth: 500,
                    height: "auto",
                    borderRadius: "10px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color: "#000",
                    fontWeight: "bold",
                    width: "100%",
                    fontSize: "1rem",
                  }}
                >
                  {loja.nome}
                </Typography>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
