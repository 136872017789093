import { Box, Button, TextField } from "@mui/material";
import CurrencyInput from "react-currency-input-field";
import global_theme from "../../../../assets/global/globalTheme";
import { useEffect, useState } from "react";
import { AddCircleOutline, DeleteForever } from "@mui/icons-material/";

const VariacoesForm = ({ setVariacoes, variacoes }) => {
  const [error, setError] = useState("");
  const [variacao, setVariacao] = useState({
    nome: "",
    opcoes: [
      {
        nome: "",
        preco: 0,
      },
    ],
  });

  const theme = global_theme;

  const novaVariacao = () => {
    // checa se os campos estão preenchidos. OBS: Pelo menos uma opção deve ser preenchida, a segunda é opcional
    if (
      variacao.nome === "" ||
      variacao.opcoes[0].nome === "" ||
      variacao.opcoes[0].preco === 0
    ) {
      setError("Preencha todos os campos");
      return;
    }

    // checa se a variacao já existe
    const variacaoJaExiste = variacoes.find(
      (variacaoAtual) => variacaoAtual.nome === variacao.nome
    );
    if (variacaoJaExiste) {
      setError("Essa variação já existe");
      return;
    }

    // checa se a opção já existe
    const opcaoJaExiste = variacoes.find((variacaoAtual) =>
      variacaoAtual.opcoes.find(
        (opcaoAtual) => opcaoAtual.nome === variacao.opcoes[0].nome
      )
    );

    if (opcaoJaExiste) {
      setError("Essa opção já existe");
      return;
    }

    // formata os preços das opções para float
    variacao.opcoes.forEach((opcao, index) => {
      variacao.opcoes[index].preco = parseFloat(opcao.preco.replace(",", ".")); // substitui a virgula por ponto
    });

    // se a segunda variacao nao estiver preenchida, remove ela do array

    if (variacao.opcoes[1]?.nome === "" || variacao.opcoes[1]?.preco === 0) {
      const variacaoSemOpcao2 = variacao;
      variacaoSemOpcao2.opcoes = [variacao.opcoes[0]];
      setVariacoes([...variacoes, variacaoSemOpcao2]);
    } else {
      setVariacoes([...variacoes, variacao]);
    }

    setVariacao({
      nome: "",
      opcoes: [
        {
          nome: "",
          preco: 0,
        },
      ],
    });
  };

  const novaOpcao = () => {
    if (variacao.opcoes.length === 10) {
      setError("Você já adicionou dez opções");
      return;
    }

    setVariacao({
      ...variacao,
      opcoes: [
        ...variacao.opcoes,
        {
          nome: "",
          preco: 0,
        },
      ],
    });
  };

  const removerOpcao = (index) => {
    const novaOpcoes = variacao.opcoes.filter((opcao, i) => i !== index);
    setVariacao({
      ...variacao,
      opcoes: novaOpcoes,
    });
  };


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        border: "1px solid #ccc",
        padding: ".5rem",
      }}
    >
      {/* Aqui será um alerta de erros, caso haja algum */}
      {error && (
        <Box sx={{ backgroundColor: "#f235", color: "red" }}>{error}</Box>
      )}
      <TextField
        label="Nome da Variação"
        variant="outlined"
        name="nome_variacao"
        fullWidth
        sx={{
          ...(error && {
            ...theme.inputs.error,
          }),
          ...theme.inputs.primary,
        }}
        onChange={(e) => setVariacao({ ...variacao, nome: e.target.value })}
        value={variacao.nome}
      />
      {variacao.opcoes.map((opcao, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            gap: ".5rem",
            margin: ".5rem 0",
          }}
        >
          <TextField
            label="Nome da Opção"
            variant="outlined"
            name="nome_opcao"
            size="small"
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),
              ...theme.inputs.primary,
            }}
            value={opcao.nome}
            onChange={(e) => {
              setVariacao((prevVariacao) => {
                const novaOpcoes = [...prevVariacao.opcoes];
                novaOpcoes[index].nome = e.target.value;
                return { ...prevVariacao, opcoes: novaOpcoes };
              });
            }}
          />
          <TextField
            label="Preço"
            variant="outlined"
            name="preco_opcao"
            size="small"
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),
              ...theme.inputs.primary,
            }}
            value={opcao.preco}
            onChange={(e) => {
              setVariacao((prevVariacao) => {
                const novaOpcoes = [...prevVariacao.opcoes];
                novaOpcoes[index].preco = e.target.value;
                return { ...prevVariacao, opcoes: novaOpcoes };
              });
            }}
          />
          {index !== 0 && (
            <Button
              sx={{
                maxWidth: "10%",
                height: "100%",
                backgroundColor: "#f23335",
                gap: ".5rem",
              }}
              variant="contained"
              color="error"
              onClick={() => removerOpcao(index)}
            >
              <DeleteForever />
            </Button>
          )}
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: ".5rem",
          marginBottom: ".5rem",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: ".5rem",
            backgroundColor: theme.palette.secondary.contrast,
            color: theme.palette.primary.main,
          }}
          color="inherit"
          onClick={novaOpcao}
        >
          <AddCircleOutline />
          Adicionar Opção
        </Button>
      </Box>

      <Button
        sx={{
          width: "100%",
          backgroundColor: theme.palette.primary.main,
        }}
        variant="contained"
        onClick={novaVariacao}
      >
        Adicionar Variação
      </Button>
    </Box>
  );
};

export default VariacoesForm;
