// essa será a página onde o cliente irá fazer o checkout e finalizar o pedido
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectCliente, changeCliente } from "../../../redux/clienteSlice";
import { selectLoja } from "../../../redux/lojaSlice";
import { formataPreco, formataTelefone } from "../../../functions/formatInfo";
import defaultProduto from "../../../assets/default_product.jpg";
import { validaCpf, validaTelefone } from "../../../functions/validaInfo";
import global_theme from "../../../assets/global/globalTheme";
import { toastIt } from "../../../functions/pushNotif";
import Home from "@mui/icons-material/Home";
import OneSignalReact from "react-onesignal";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";
import { lojaFuncionamento } from "../../../functions/lojaFuncionamento";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { distanciaEntre } from "../../../functions/distanciaEntre";

const Checkout = () => {
  const cliente = useSelector(selectCliente);
  const loja = useSelector(selectLoja);
  const [nome, setNome] = useState(cliente.nome);
  const [telefone, setTelefone] = useState(cliente.telefone);
  const [cpf, setCpf] = useState(cliente.cpf);
  const [formaPagamento, setFormaPagamento] = useState(null);
  const [troco, setTroco] = useState(null);
  const [observacoes, setObservacoes] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState(cliente.endereco);
  const [error, setError] = useState(false);
  const [frete, setFrete] = useState(null);
  const [formaEntrega, setFormaEntrega] = useState("1");
  const [processando, setProcessando] = useState(false);
  const [permiteCompra, setPermiteCompra] = useState(true);

  const theme = global_theme;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getOneSignalId = async () => {
    const result = await OneSignalReact.getUserId();
    if (!result) {
      return false;
    }
    return result;
  };

  const validaCliente = async () => {
    if (!cpf || !nome || !telefone) {
      toastIt("Preencha todos os campos", "error");
      return false;
    }

    if (!validaCpf(cpf)) {
      toastIt("CPF inválido", "error");
      return false;
    }


    if (!validaTelefone(telefone)) {
      toastIt("Telefone inválido", "error");
      return false;
    }

    var oneSignalID = "";
    const timeout = 5000; // Tempo limite em milissegundos (aqui definido como 5 segundos)

    // Função para criar um tempo limite para a Promise
    function timeoutPromise(ms) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Tempo limite excedido"));
        }, ms);
      });
    }

    try {
      // Executa a Promise e define um tempo limite
      oneSignalID = await Promise.race([
        getOneSignalId(),
        timeoutPromise(timeout),
      ]);
    } catch (error) {
      console.error("Erro ao obter o ID do OneSignal:", error);
      oneSignalID = null;
    }

    const novoCliente = {
      ...cliente,
      nome,
      telefone,
      cpf,
      onesignal_ids: [oneSignalID || ""],
    };

    dispatch(changeCliente(novoCliente));
    return novoCliente;
  };

  const handleSavePedido = async () => {
    var valido = true;
    if (processando) {
      return;
    } else {
      setProcessando(true);
    }
    const esteCliente = await validaCliente();

    if (!esteCliente) {
      valido = false;
    }

    //return

    if (!endereco.cep) {
      toastIt("Preencha o CEP", "error");
      valido = false;
    }

    // checa se o numero do endereço foi preenchido e pode ser convertido para inteiro
    if (!endereco.numero || isNaN(parseInt(endereco.numero))) {
      toastIt("Preencha o número do endereço", "error");
      valido = false;
    }

    if (!formaPagamento) {
      toastIt("Selecione a forma de pagamento", "error");
      valido = false;
    }

    if (formaPagamento === "dinheiro" && !troco) {
      toastIt("Preencha o campo troco", "error");
      valido = false;
    }

    if (!valido) {
      setProcessando(false);
      return;
    }

    const novoPedido = {
      loja: {
        _id: loja._id,
        nome: loja.nome,
        telefone: loja.telefone,
        imagem_perfil: loja.imagem_perfil,
      },
      cliente: {
        nome: esteCliente.nome,
        telefone: esteCliente.telefone,
        cpf: esteCliente.cpf,
        endereco: endereco,
        onesignal_ids: esteCliente.onesignal_ids,
      },
      data_pedido: new Date(),
      pagamento: {
        forma_pagamento: formaPagamento.toLowerCase(),
        troco:
          formaPagamento.toLowerCase() === "dinheiro" ? parseFloat(troco) : 0,
        formaEntrega: formaEntrega === "1" ? "Retirada" : "Entrega",
        frete: formaEntrega === "1" ? 0 : frete,
        subtotal: cliente.carrinho.total,
        total:
          formaEntrega === "0"
            ? cliente.carrinho.total + frete
            : cliente.carrinho.total,
      },
      produtos: cliente.carrinho?.produtos?.map((produto) => ({
        produto_id: produto._id,
        quantidade: produto.quantidade,
        nome: produto.nome,
        preco: produto.preco,
        imagem: produto.imagem || produto.imagens[0] || defaultProduto,
        variacoes: produto.variacoes || [],
        adicionais: produto.adicionais || [],
      })),
      observacoes,
    };

    // salva o cliente no banco de dados
    fetch(`${UrlApi}/clientes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
      },
      body: JSON.stringify({
        cliente: {
          nome: esteCliente.nome,
          telefone: esteCliente.telefone,
          cpf: esteCliente.cpf,
          endereco: endereco,
          onesignal_ids: esteCliente.onesignal_ids,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toastIt(res.error, "error");
        } else {
          toastIt("Seu perfil foi salvo", "success");
          const clienteLocal = JSON.parse(localStorage.getItem("cliente"));
          localStorage.setItem(
            "cliente",
            JSON.stringify({
              ...clienteLocal,
              nome: esteCliente.nome,
              telefone: esteCliente.telefone,
              token: res.token,
            })
          );
        }
      });

    // aqui você deve enviar o novoPedido para o backend

    fetch(`${UrlApi}/pedidos/loja`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
      },
      body: JSON.stringify(novoPedido),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toastIt(res.error, "error");
        } else {
          toastIt("Pedido realizado com sucesso", "success");
          dispatch(
            changeCliente({
              ...cliente,
              carrinho: {
                produtos: [],
                total: 0,
              },
            })
          );
          navigate("/loja/" + loja.slug);
        }
      })
      .finally(() => {
        setProcessando(false);
      });
  };

  const buscaEndereco = (cep) => {
    cep = cep.replace(/\D/g, "");
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((res) => {
        if (res.erro) {
          toastIt("CEP inválido", "error");
          return;
        }

        const novoEndereco = {
          cep: res.cep,
          rua: res.logradouro,
          numero: endereco.numero || "",
          bairro: res.bairro,
          cidade: res.localidade,
          estado: res.uf,
          complemento: "",
        };

        if (loja.funcionamento.entrega.ativo) {
          calculaFrete();
        }

        setEndereco(novoEndereco);
      })
      .catch((err) => {
        toastIt("Erro ao buscar endereço", "error");
      });
  };

  const handleRadioChange = (event) => {
    setFormaPagamento(event.target.value);
  };

  const handleFormaEntrega = (event) => {
    setFormaEntrega(event.target.value);
  };

  useEffect(() => {
    const clienteStorage = JSON.parse(localStorage.getItem("cliente"));

    if (clienteStorage?.localizacao?.cep) {
      setCep(clienteStorage.localizacao.cep);
    }
  }, []);

  useEffect(() => {
    if (!loja) {
      return <LoadingPage />;
    }

    if (cliente?.carrinho?.produtos?.length === 0) {
      toastIt("Seu carrinho está vazio. Retornando...", "error");
      setTimeout(() => {
        if (loja.slug !== "") {
          navigate("/loja/" + loja.slug);
        } else navigate("/");
      }, 2000);
    }

    if (loja && loja.funcionamento?.horarios?.length) {
      const lojaFunc = lojaFuncionamento(loja.funcionamento.horarios);
      if (lojaFunc) {
        if (!lojaFunc.aberto) {
          toastIt("A loja está fechada. Retornando...", "error");
          setTimeout(() => {
            if (loja.slug !== "") {
              navigate("/loja/" + loja.slug);
            } else navigate("/");
          }, 2000);
        }
      }
    }
  }, []);

  const calculaDistancia = async (cep1, cep2) => {
    cep1 = cep1.replace("-", "");
    cep2 = cep2.replace("-", "");
    const calculaDistancia = await distanciaEntre(cep1, cep2);

    // essa distancia vem no estilo '1.2km', então precisamos remover o 'km' e converter para float

    const distancia = parseFloat(calculaDistancia.replace("km", ""));

    return distancia;
  };

  const calculaFrete = async () => {
    if (loja._id) {
      const cepLoja = loja.endereco.cep;
      const cepCliente = cep;
      var distancia = await calculaDistancia(cepLoja, cepCliente);
      const taxaPorKm = loja.funcionamento.entrega.taxaPorKm;
      const taxaMinima = loja.funcionamento.entrega.taxaMinima;
      const taxaGratis = loja.funcionamento.entrega.taxaGratis;
      const distanciaMaxima = loja.funcionamento.entrega.distanciaMaxima;

      if (
        distanciaMaxima &&
        distanciaMaxima > 0 &&
        distancia > distanciaMaxima
      ) {
        toastIt(
          "O endereço informado está fora da área de entrega da loja. Informe outro endereço ou entre em contato com a loja para mais informações.",
          "error"
        );
        setPermiteCompra(false);
        return;
      }

      if (!permiteCompra) {
        setPermiteCompra(true);
      }

      if (distancia <= 0 && !taxaGratis) {
        setFrete(taxaMinima);
        return;
      } else if (distancia <= taxaGratis) {
        setFrete(0);
        return;
      } else {
        const frete = distancia * taxaPorKm;
        setFrete(frete);
        return;
      }
    }
  };

  return (
    <Container
      sx={{
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          padding: "1rem 0",
        }}
      >
        <Typography variant="h5">Checkout</Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: theme.palette.primary.main,

            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
          onClick={() => navigate("/loja/" + loja.slug)}
        >
          <Home />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            label="Nome"
            variant="outlined"
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),
              ...theme.inputs.primary,
              marginBottom: "1rem",
            }}
            fullWidth
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <TextField
            label="Telefone"
            variant="outlined"
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),
              ...theme.inputs.primary,
              marginBottom: "1rem",
            }}
            inputProps={{
              maxLength: 15,
            }}
            fullWidth
            value={telefone}
            onChange={(e) => {
              var formatado = formataTelefone(e.target.value);
              setTelefone(formatado);
            }}
          />
          <TextField
            label="CPF"
            variant="outlined"
            sx={{
              ...(error && {
                ...theme.inputs.error,
              }),
              ...theme.inputs.primary,
              marginBottom: "1rem",
            }}
            fullWidth
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <TextField
              label="CEP"
              variant="outlined"
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),
                ...theme.inputs.primary,
                maxWidth: 500,
              }}
              fullWidth
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                height: "56px",
                "&:hover": {
                  backgroundColor: theme.palette.primary.contrast,
                },
              }}
              onClick={() => {
                if (cep.replace(/\D/g, "").length !== 8) {
                  alert("CEP inválido");
                  return;
                }

                buscaEndereco(cep);
              }}
            >
              Buscar
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: 0.5,
            }}
          >
            <TextField
              label="Nº"
              variant="outlined"
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),
                ...theme.inputs.primary,
                marginBottom: "1rem",
              }}
              fullWidth
              value={endereco?.numero}
              onChange={(e) => {
                setEndereco({
                  ...endereco,
                  numero: e.target.value,
                });
              }}
            />
            <TextField
              label="Complemento"
              variant="outlined"
              sx={{
                ...(error && {
                  ...theme.inputs.error,
                }),
                ...theme.inputs.primary,
                marginBottom: "1rem",
              }}
              fullWidth
              value={endereco?.complemento}
              onChange={(e) => {
                setEndereco({
                  ...endereco,
                  complemento: e.target.value,
                });
              }}
            />
          </Box>
          <Typography variant="h6">Conferir endereço</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              border: "3px solid " + theme.palette.secondary.main,
              borderRadius: "5px",
              padding: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="body1">Rua: {endereco?.rua}</Typography>
            <Typography variant="body1">Número: {endereco?.numero}</Typography>
            <Typography variant="body1">Bairro: {endereco?.bairro}</Typography>
            <Typography variant="body1">Cidade: {endereco?.cidade}</Typography>
            <Typography variant="body1">Estado: {endereco?.estado}</Typography>
            <Typography variant="body1">
              Complemento: {endereco?.complemento}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="h6">Frete</Typography>
            <Box
              sx={{
                border: "3px solid " + theme.palette.secondary.main,
                borderRadius: "5px",
                padding: "0.5rem",
              }}
            >
              {loja.funcionamento.entrega.ativo && frete && (
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      sx={{
                        color: theme.palette.secondary.main,

                        "&.Mui-checked": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label={`Entrega - ${formataPreco(frete)}`}
                  checked={formaEntrega === "0"}
                  onChange={handleFormaEntrega}
                />
              )}
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    sx={{
                      color: theme.palette.secondary.main,

                      "&.Mui-checked": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label={`Retirada - R$ 0,00`}
                checked={
                  loja.funcionamento.entrega.entregaAtiva === false
                    ? true
                    : formaEntrega === "1"
                }
                onChange={handleFormaEntrega}
              />
            </Box>
          </Box>
          <Typography variant="h6">Conferir pedido</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              border: "3px solid " + theme.palette.secondary.main,
              borderRadius: "5px",
              padding: "1rem",
            }}
          >
            {cliente.carrinho?.produtos?.map((produto) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                  border: "1px solid " + theme.palette.secondary.main,
                }}
              >
                <img
                  src={produto.imagem || produto.imagens[0] || defaultProduto}
                  alt={produto.nome}
                  style={{
                    width: 60,
                    height: 60,
                    objectFit: "cover",
                    borderRight: "1px solid " + theme.palette.secondary.main,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    paddingRight: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1">{produto.nome}</Typography>
                    <Typography variant="body1">
                      {produto.quantidade} x {formataPreco(produto.preco)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        marginRight: "1rem",
                      }}
                    >
                      <Typography variant="body1">Variação:</Typography>
                      {produto.variacoes?.map((variacao, index) =>
                        index === produto.variacoes?.length - 1 ? (
                          <Typography variant="body1">
                            {variacao.value}
                          </Typography>
                        ) : index < 3 ? (
                          <Typography variant="body1">
                            {variacao.value},
                          </Typography>
                        ) : (
                          <Typography variant="body1">
                            +{produto.variacoes.length - 3}
                          </Typography>
                        )
                      )}
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body1">
                        Adicionais: {produto.adicionais?.length || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              {frete > 0 && formaEntrega === "0" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1">Frete</Typography>
                  <Typography variant="body1">{formataPreco(frete)}</Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="body1">Subtotal</Typography>
                <Typography variant="body1">
                  {frete > 0 && formaEntrega === "0"
                    ? formataPreco(cliente.carrinho?.total + frete)
                    : formataPreco(cliente.carrinho?.total)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxWidth: "800px",
            }}
          >
            <FormControlLabel
              value="cartao-credito"
              control={
                <Radio
                  sx={{
                    color: theme.palette.secondary.main,

                    "&.Mui-checked": { color: theme.palette.primary.main },
                  }}
                />
              }
              label="Cartão de crédito"
              checked={formaPagamento === "cartao-credito"}
              onChange={handleRadioChange}
            />
            <FormControlLabel
              value="cartao-debito"
              control={
                <Radio
                  sx={{
                    color: theme.palette.secondary.main,

                    "&.Mui-checked": { color: theme.palette.primary.main },
                  }}
                />
              }
              label="Cartão de débito"
              checked={formaPagamento === "cartao-debito"}
              onChange={handleRadioChange}
            />
            <FormControlLabel
              value="pix"
              control={
                <Radio
                  sx={{
                    color: theme.palette.secondary.main,

                    "&.Mui-checked": { color: theme.palette.primary.main },
                  }}
                />
              }
              label="Pix"
              checked={formaPagamento === "pix"}
              onChange={handleRadioChange}
            />
            <FormControlLabel
              value="dinheiro"
              control={
                <Radio
                  sx={{
                    color: theme.palette.secondary.main,

                    "&.Mui-checked": { color: theme.palette.primary.main },
                  }}
                />
              }
              label="Dinheiro"
              checked={formaPagamento === "dinheiro"}
              onChange={handleRadioChange}
            />
          </Box>
          {formaPagamento === "dinheiro" && (
            <TextField
              label="Troco para"
              variant="outlined"
              type="number"
              sx={{
                ...(error && {
                  "& .MuiOutlinedInput-root": {
                    borderColor: theme.palette.error.main,
                  },
                }),

                ...theme.inputs.primary,
                width: "100%",
                maxWidth: "500px",
                marginTop: "1rem",
              }}
              value={troco}
              onChange={(e) => setTroco(e.target.value)}
            />
          )}
        </Box>
        <TextField
          label="Observações"
          variant="outlined"
          multiline
          rows={4}
          sx={{
            ...theme.inputs.primary,
            width: "100%",

            marginTop: "1rem",
          }}
          value={observacoes}
          onChange={(e) => setObservacoes(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          {permiteCompra ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "100%",
                maxWidth: "500px",
                padding: ".5rem 0",
                fontSize: "1.2rem",

                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
              onClick={handleSavePedido}
            >
              {processando ? (
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                "Finalizar pedido"
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              sx={{
                width: "100%",
                maxWidth: "500px",
                padding: ".5rem 0",
                fontSize: "1.2rem",
              }}
              onClick={() => navigate("/loja/" + loja.slug)}
            >
              Voltar
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Checkout;