import { useState, useEffect } from "react";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import global_theme from "../../assets/global/globalTheme";
import {
  formataParaDataFormal,
  formataPreco,
} from "../../functions/formatInfo";
import { selectLoja } from "../../redux/lojaSlice";
import { useSelector } from "react-redux";
import UrlApi, { ApiToken } from "../../appconfig";
import { toastIt } from "../../functions/pushNotif";
import DefaultProduct from "../../assets/default_product.jpg";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loja = useSelector(selectLoja);
  const theme = global_theme;

  const loadData = async () => {
    setLoading(true);
    // vamos setar uma variavel de data de hoje sem horas
    const hoje = `${new Date().getFullYear()}-${
      new Date().getMonth() < 10
        ? "0" + (new Date().getMonth()+1)
        : (new Date().getMonth()+1)
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }`;
    // console.log("fazendo post com data: ", hoje);
    fetch(`${UrlApi}/loja/vendas/receitas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
      body: JSON.stringify({
        data: hoje,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setData(data);
            // console.log(data)
          });
        } else {
          // console.log(response);
        }
      })
      .catch((error) => {
        toastIt("Erro ao carregar dados", "error");
        // console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loja._id) {
      loadData();
    }
  }, [loja]);

  const StatusBox = ({ nome, numero, borda }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRight: borda ? "1px solid #fff" : "none",
          p: 2,
          height: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#fff",
            fontWeight: "light",
          }}
        >
          {nome}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: "#fff",
            fontWeight: "bold",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {numero}
        </Typography>
      </Box>
    );
  };

  return (
    <Container
      sx={{
        height: "90vh",
      }}
      >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "10%",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Hoje
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.primary.main,
          }}
          >
          {formataParaDataFormal(new Date()).substring(7)}
        </Typography>
      </Box>
      {loading ? (
        <CircularProgress />
        ) : (
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            height: "90%",
          }}
          >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              alignItems: "center",
              backgroundColor: theme.palette.primary.contrast,
              borderRadius: "20px",
              width: "100%",
              height: 250,
              p: "10px 0",
            }}
          >
            <StatusBox nome="Pedidos" numero={data?.vendas || 0} borda={true} />
            <StatusBox
              nome="Entregues"
              numero={data?.status?.entregue || 0}
              borda={true}
              />
            <StatusBox
              nome="Em andamento"
              numero={data?.status ? data.status["em preparação"] : 0}
              borda={true}
              />
            <StatusBox
              nome="Cancelamentos"
              numero={data?.status?.cancelados || 0}
              borda={true}
              />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: theme.palette.primary.main,
              borderRadius: "20px",
              width: "100%",
              height: "100%",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
                height: "100%",
                borderRight: "1px solid #fff",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  marginTop: "10px",
                  width: "100%",
                  height: "10%",
                  textAlign: "center",
                }}
              >
                Receita
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                  }}
                >
                  {data?.receita ? formataPreco(data?.receita) : "R$ 0,00"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
                height: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  marginTop: "10px",
                  width: "100%",
                  height: "10%",
                  textAlign: "center",
                }}
              >
                Mais vendido
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 2,
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
              >
                <img
                    src={data?.produtoMaisVendido?.imagem || DefaultProduct}
                    alt="produto mais vendido"
                    style={{ width: "40%", borderRadius: "20px" }}
                  />
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1rem",
                    color: "#fff",
                    fontWeight: "light",
                  }}
                >
                  {data?.produtoMaisVendido?.nome}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Dashboard;
