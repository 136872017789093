import { Box, Button, TextField } from "@mui/material";
import CurrencyInput from "react-currency-input-field";
import global_theme from "../../../../assets/global/globalTheme";
import { useState } from "react";

const AdicionaisForm = ({ setAdicionais, adicionais }) => {
  const [error, setError] = useState("");
  const [adicional, setAdicional] = useState({
    nome: "",
    preco: 0,
  });
  
  const theme = global_theme;

  const novoAdicional = (adicional) => {
    setAdicionais([...adicionais, adicional]);
    setAdicional({
      nome: "",
      preco: 0,
    });
  };

  const handleSubmit = () => {

    const preco = adicional.preco.replace("R$ ", "").replace(".", "").replace(",", ".");
    // pega os dois ultimos caracteres do preco e transforma em centavos
    const centavos = preco.slice(-2);
    // pega o resto do preco e transforma em reais
    const reais = preco.slice(0, -2);
    // concatena os dois valores
    const precoFormatado = `${reais}.${centavos}`;

    // dá um parse no valor para float
    const precoFloat = parseFloat(precoFormatado);

    novoAdicional({
      ...adicional,
      preco: precoFloat,
    });
  };

  return (
    <Box
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        border: "1px solid #ccc",
        padding: ".5rem",
      }}
    >
      <TextField
        label="Nome do Adicional"
        variant="outlined"
        size="small"
        name="nome_adicional"
        fullWidth
        onChange={(e) => setAdicional({ ...adicional, nome: e.target.value })}
        value={adicional.nome}
        sx={{
          ...(error && {
            ...theme.inputs.error,
          }),
          ...theme.inputs.primary,
        }}
      />
      <TextField
        margin="normal"
        label="Preço"
        variant="outlined"
        name="preco_adicional"
        fullWidth
        onChange={(e) => {
          const precoFormatado = parseFloat(e.target.value.replace("R$ ", "").replace(".", "").replace(",", ".")).toFixed(2);
          if (precoFormatado === "NaN") {
            setAdicional({ ...adicional, preco: 0 });
            return;
          }
          setAdicional({ ...adicional, preco: precoFormatado })
        }}
        value={adicional.preco}
        InputProps={{
          inputComponent: CurrencyInput,
          inputProps: {
            prefix: "R$ ",
            decimalSeparator: ",",
            groupSeparator: ".",
            decimalsLimit: 2,
          },
        }}
        sx={{
          ...(error && {
            ...theme.inputs.error,
          }),
          ...theme.inputs.primary,
        }}
      />
      <Button
        sx={{
          width: "100%",
          backgroundColor: theme.palette.primary.main,

          "&:hover": {
            backgroundColor: theme.palette.primary.contrast,
          }
        }}
        variant="contained"
        onClick={handleSubmit}
      >
        Adicionar
      </Button>
    </Box>
  );
};

export default AdicionaisForm;
