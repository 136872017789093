import { useState, useEffect } from "react";

import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import InventoryIcon from "@mui/icons-material/Inventory";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
  logout,
  changeUser,
  setUserToken,
  selectUser,
} from "../../redux/userSlice";
import { logoutLoja, changeLoja, selectLoja } from "../../redux/lojaSlice";
import { toastIt } from "../../functions/pushNotif";
import UrlApi from "../../appconfig";
import { ApiToken, AppNomeCompacto, AppLogo } from "../../appconfig";
import global_theme from "../../assets/global/globalTheme";

const theme = global_theme;

const SidebarData = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    link: "/dashboard",
  },
  {
    title: "Produtos",
    icon: <InventoryIcon />,
    link: "none",
    subitems: [
      {
        title: "Categorias",
        icon: <CategoryIcon />,
        link: "/catalogo/categorias",
      },
      {
        title: "Catálogo",
        icon: <LocalOfferIcon />,
        link: "/catalogo",
      },
    ],
  },
  {
    title: "Pedidos",
    icon: <DeliveryDiningIcon />,
    link: "/pedidos",
  },
  {
    title: "Pessoas",
    icon: <PeopleAltIcon />,
    link: "none",
    subitems: [
      {
        title: "Clientes",
        icon: <SentimentSatisfiedAltIcon />,
        link: "/clientes",
      },
      {
        title: "Usuários",
        icon: <AssignmentIndIcon />,
        link: "/usuarios",
      },
    ],
  },
  {
    title: "Configurações",
    icon: <SettingsIcon />,
    link: "/configuracoes",
    subitems: [
      {
        title: "Horários",
        icon: <AccessTimeIcon />,
        link: "/configuracoes/horario-funcionamento",
      },
      {
        title: "Entrega",
        icon: <LocalShippingIcon />,
        link: "/configuracoes/entrega",
      },
      {
        title: "Vendas",
        icon: <ShoppingCartIcon />,
        link: "/configuracoes/vendas"
      }
    ],
  },
];

const clienteSideList = [
  {
    title: "Início",
    icon: <HomeIcon />,
    link: "/",
  },
  {
    title: "Meus Pedidos",
    icon: <DeliveryDiningIcon />,
    link: "/meuspedidos",
  },
];

const SideList = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);
  const loja = useSelector(selectLoja);
  const sideBarSelected = user._id ? SidebarData : clienteSideList;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token-pedja");
    dispatch(logout());
    dispatch(logoutLoja());
    toastIt("Você desconectou de sua conta", "info");
    navigate("/signin");
  };

  const carregarLoja = async (id) => {
    const response = await fetch(`${UrlApi}/loja/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
    });
    const data = await response.json();
    dispatch(changeLoja(data));
    // muda o titulo e o icone da pagina
    document.title = data.nome + " | " + AppNomeCompacto;
    // muda o logo do site para o logo da loja
    document
      .querySelector("link[rel*='icon']")
      .setAttribute("href", data.imagem_perfil);
    navigate("/pedidos");
  };

  const checkUserToken = (user) => {
    const token = localStorage.getItem("token");
    if (token && !user.token) {
      setLoading(true);
      fetch(`${UrlApi}/login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiToken}`,
          token: token,
        },
      })
        .then(async (response) => {
          if (!response) {
            setLoading(false);
            return false;
          }
          setLoading(false);
          return response.json();
        })
        .then((data) => {
          if (data) {
            toastIt(`Bem-vindo de volta, ${data.data.usuario}!`, "success");
            dispatch(changeUser(data.data));
            dispatch(setUserToken(data.token));
            carregarLoja(data.data.loja_id);
            setLoading(false);
          }
        })
        .catch((err) => console.log(''));
    }
  };

  const handleLogin = () => {
    if (!user.token && localStorage.getItem("token")) {
      checkUserToken(user);
    } else navigate("/signin");
  };

  return (
    <Box
      sx={{
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0.5em",
        }}
      >
        {user._id && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
              padding: "0.5em 0",
              width: "100%",
              gap: 1,
            }}
          >
            <Avatar
              sx={{
                width: "2.5rem",
                height: "2.5rem",
              }}
              src={loja.imagem_perfil}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1rem",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                {loja.nome}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontSize: ".8rem",
                  color: theme.palette.primary.contrast,
                }}
              >
                {user.usuario}
              </Typography>
            </Box>
            <ExitToAppIcon
              sx={{
                fontSize: "1.4rem",
                color: "#f22f22",
                cursor: "pointer",
              }}
              onClick={handleLogout}
            />
          </Box>
        )}
      </Box>
      {sideBarSelected.map((listItem, index) => (
        <Box
          key={index}
          sx={{
            position: "relative",
            display: "flex",
            "&:hover ": {
              [`& #subitem_${index}`]: {
                display: "block",
              },
            },
          }}
        >
          <ListItem
            button
            component={listItem.link === "none" ? "div" : Link}
            to={listItem.link}
            sx={{
              padding: "0.5em",
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.contrast,
                color: theme.palette.secondary.contrast,

                [`& #list-icon-${index}`]: {
                  color: theme.palette.secondary.contrast,
                },
              },
            }}
            id={`item_${index}`}
          >
            <ListItemIcon
              id={`list-icon-${index}`}
              sx={{ color: theme.palette.primary.main }}
            >
              {listItem.icon}
            </ListItemIcon>
            <ListItemText primary={listItem.title} />
          </ListItem>
          {listItem.subitems && (
            <Box
              sx={{
                position: "fixed",
                width: "200px",
                backgroundColor: "#fff",
                zIndex: 999,
                left: "220px",
                display: "none",

                [`&:hover`]: {
                  display: "block",
                },
              }}
              id={`subitem_${index}`}
            >
              {listItem.subitems.map((subitem, index) => (
                <ListItem
                  key={index}
                  button
                  component={Link}
                  to={subitem.link}
                  sx={{
                    padding: "0.5em",
                    color: theme.palette.primary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.contrast,
                      color: theme.palette.secondary.contrast,

                      [`& #subitem-icon-${index}`]: {
                        color: theme.palette.secondary.contrast,
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    id={`subitem-icon-${index}`}
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {subitem.icon}
                  </ListItemIcon>
                  <ListItemText primary={subitem.title} />
                </ListItem>
              ))}
            </Box>
          )}
        </Box>
      ))}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={AppLogo}
          alt="logo"
          style={{ width: "80px", cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};

export default function App({ open, handleToggle }) {
  return (
    <div className="Sidebar">
      <Drawer open={open} anchor="left" onClick={handleToggle}>
        <SideList />
      </Drawer>
    </div>
  );
}
