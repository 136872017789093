// essa será a página que irá listar os pedidos

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoja } from "../../../redux/lojaSlice";
import {
  formataData,
  formataPreco,
  formataEndereco,
} from "../../../functions/formatInfo";
import { toastIt } from "../../../functions/pushNotif";
import global_theme from "../../../assets/global/globalTheme";
import io from "socket.io-client";
import WhatsappButton from "../../../components/Whatsapp/WhatsappButton";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";
import { statusIcons } from "../../../assets/icons/status-pedido/status_icons";
import EsperandoPedido from "../../../assets/esperando-pedido.png";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";

// TODO: A ordem de pedidos será feita pelo botão que troca de ícone, tendo opçoes:
// recentes, antigos e status
// arrowDown => import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown // ';
// arrowUp => import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp' //  ;

const DetalhesPedido = () => {
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lojaConfig, setLojaConfig] = useState(null);
  const theme = global_theme;
  const loja = useSelector(selectLoja);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (loja?._id) {
      getPedido();
    }
  }, []);

  const getPedido = async () => {
    fetch(`${UrlApi}/pedidos/loja/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data._id) {
          setPedido(data);
          getConfig();
        }
      })
      .catch((error) => {
        console.log(error);
        toastIt("Erro ao carregar pedido!", "error");
      })
      .finally(setLoading(false));
  };

  const getConfig = () => {
    fetch(`${UrlApi}/loja/configuracoes/vendas`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        loja_id: loja._id,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.loja_id) {
          setLojaConfig(data);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // aqui é uma função para configurar a mensagem que será enviada ao cliente
  const configurarMensagem = () => {
    const mensagem = lojaConfig?.msgPrimeiroContato;
    const cliente = pedido.cliente;
    var mensagemFormatada = mensagem;

    // aqui eu substituo as variáveis da mensagem pelos valores do cliente
    // as variaveis são: <nomecliente>
    try {
      mensagemFormatada = mensagemFormatada.replace(
        "<nomecliente>",
        cliente.nome
      );
    } catch (err) {
      // console.log(err);
    }

    return mensagemFormatada;
  };

  // aqui um objeto com todos os status possíveis e o que vem depois de cada um
  const todosStatus = {
    "aguardando confirmação": "em preparação",
    "em preparação": "a caminho",
    "a caminho": "entregue",
  };

  const todosStatusRetirada = {
    "aguardando confirmação": "em preparação",
    "em preparação": "aguardando retirada",
    "aguardando retirada": "retirado",
  }

  const statusToIcon = {
    "aguardando confirmação": statusIcons.Pendente,
    "em preparação": statusIcons.Pendente,
    "aguardando retirada": statusIcons.AguardandoRetirada,
    "a caminho": statusIcons.EmRota,
    entregue: statusIcons.Entregue,
    retirado: statusIcons.Entregue,
    cancelado: statusIcons.Cancelado,
  };

  // Aqui é uma função que irá controlar o status do pedido
  const changeStatus = async (cancelado) => {
    if (
      cancelado === "cancelado" &&
      pedido.status.toLowerCase() === "aguardando confirmação"
    ) {
      const confirm = window.confirm(`Deseja cancelar o pedido?`);

      if (confirm) {
        const response = await fetch(
          `${UrlApi}/pedidos/loja/${pedido._id}/update_status`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${ApiToken}`,
            },
            body: JSON.stringify({
              status: "cancelado",
            }),
          }
        );

        const data = await response.json();

        if (data.status === "success") {
          getPedido();
          toastIt("Pedido cancelado com sucesso!", "success");
        } else {
          toastIt("Erro ao cancelar pedido!", "error");
        }
      }

      return;
    }

    // aqui eu pego o status atual do pedido
    const statusAtual = pedido.status.toLowerCase();

    // aqui eu pego o próximo status do pedido
    const proximoStatus = pedido.pagamento?.formaEntrega?.toUpperCase() === "RETIRADA" ?
    todosStatusRetirada[statusAtual] : todosStatus[statusAtual];

    // aqui, antes de enviar a requisição, eu peço confirmação ao usuário
    const confirm = window.confirm(
      `Deseja alterar o status do pedido para ${proximoStatus}?`
    );

    // se o usuário confirmar, eu envio a requisição
    if (confirm) {
      const response = await fetch(
        `${UrlApi}/pedidos/loja/${pedido._id}/update_status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ApiToken}`,
          },
          body: JSON.stringify({
            status: proximoStatus,
          }),
        }
      );

      const data = await response.json();

      // se a requisição for bem sucedida, eu atualizo a página
      if (data.status === "success") {
        getPedido();
        toastIt(data.message, "success");
      } else {
        toastIt(data.message, "error");
      }
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        padding: 1,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.primary.main,
          cursor: "pointer",
          marginBottom: 2,

          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => navigate("/m/pedidos", { replace: true })}
      >
        Voltar
      </Typography>
      {pedido ? (
        <Typography
          variant="h5"
          sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
        >
          Detalhes do pedido
        </Typography>
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              mb: 5,
            }}
          >
            Você verá os detalhes do pedido aqui...
          </Typography>
          <img
            src={EsperandoPedido}
            alt="Esperando Pedido"
            style={{
              width: "100%",
              height: "100%",

              maxWidth: "300px",
              maxHeight: "300px",
              objectFit: "contain",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: "#fff",
          marginTop: "1em",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        {pedido && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                p: "1em",
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="body"
                sx={{
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                ID:
                <span style={{ fontWeight: "normal", marginLeft: 5 }}>{pedido._id}</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    mr: 0.5,
                  }}
                >
                  Status:
                </Typography>
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "1em",
                  }}
                >
                  {
                    // maiuscula
                    pedido.status.charAt(0).toUpperCase() +
                      pedido.status.slice(1)
                  }
                </span>
                <img
                  src={statusToIcon[pedido.status.toLowerCase()]}
                  alt={pedido.status}
                  style={{
                    width: "36px",
                    height: "36px",
                    marginLeft: ".5em",
                  }}
                />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                Data:{" "}
                <span
                  style={{
                    fontWeight: "normal",
                  }}
                >
                  {formataData(pedido.data_pedido)}
                </span>
              </Typography>
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1.4em",
                fontWeight: 600,
                color: theme.palette.primary.main,
              }}
            >
              Cliente
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                padding: "1em",
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                borderRadius: "5px",
              }}
            >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.2em",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1em",
                      fontWeight: "bold",
                    }}
                  >
                    Nome:{" "}
                  </span>{" "}
                  {pedido.cliente.nome}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.2em",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1em",
                      fontWeight: "bold",
                    }}
                  >
                    Telefone:{" "}
                  </span>{" "}
                  {pedido.cliente.telefone}
                  <WhatsappButton
                    numero={pedido.cliente.telefone}
                    mensagem={
                      lojaConfig?.msgPrimeiroContato
                        ? configurarMensagem(lojaConfig?.msgPrimeiroContato)
                        : "Olá, tudo bem?"
                    }
                  />
                </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2em",
                }}
              >
                <span
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  CPF:{" "}
                </span>{" "}
                {pedido.cliente.cpf}
              </Typography>
              <Typography variant="body1">
                <span
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  Endereço:{" "}
                </span>{" "}
                <span style={{ fontSize: "1em" }}>
                  {formataEndereco(pedido.cliente.endereco)}
                </span>
              </Typography>
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1.4em",
                fontWeight: 600,
                color: theme.palette.primary.main,
              }}
            >
              Itens do pedido
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                padding: "1em 0",
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                borderRadius: "5px",
              }}
            >
              {pedido.produtos.map((produto, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1.2em",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontSize: "1em" }}>
                      <span style={{ fontWeight: "bold" }}>
                        x{produto.quantidade}
                      </span>{" "}
                      {produto.nome}
                    </Typography>
                    {produto.variacoes && (
                      // aqui vai mostrar as variações do produto, mas apenas a propriedade value. Elas serão divididas por aquele ponto que aparece no meio
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        sx={{
                          fontSize: ".8em",
                        }}
                      >
                        {Object.entries(produto.variacoes).map(
                          (variacao, index) => {
                            if (index === 0) {
                              return variacao[1].value;
                            } else {
                              return ` . ${variacao[1].value}`;
                            }
                          }
                        )}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      sx={{
                        fontSize: ".8em",
                      }}
                    >
                      {produto.adicionais.length > 0 &&
                        produto.adicionais.map((adicional, index) => {
                          if (index === 0) {
                            return "+" + adicional.nome;
                          } else {
                            return `, +${adicional.nome}`;
                          }
                        })}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ color: "#00a35c", fontSize: "1em" }}
                  >
                    {formataPreco(produto.preco)}
                  </Typography>
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 1em",
                }}
              >
                <Typography variant="h6">Subtotal</Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "#00a35c", fontWeight: "bold" }}
                >
                  {formataPreco(pedido.pagamento?.subtotal)}
                </Typography>
              </Box>
              {pedido.pagamento?.formaEntrega?.toUpperCase() === "ENTREGA" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 1em",
                  }}
                >
                  <Typography variant="h6">Frete</Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#00a35c", fontWeight: "bold" }}
                  >
                    {formataPreco(pedido.pagamento?.frete) || "Grátis"}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 1em",
                }}
              >
                <Typography variant="h6">Total</Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "#00a35c", fontWeight: "bold" }}
                >
                  {formataPreco(pedido.pagamento?.total)}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1.4em",
                fontWeight: 600,
                color: theme.palette.primary.main,
              }}
            >
              Pagamento e Observações
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "1em",
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2em",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Forma de Entrega: </span>
                {pedido.pagamento?.formaEntrega?.toUpperCase()}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2em",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Forma de Pagamento: </span>
                {pedido.pagamento?.forma_pagamento.toUpperCase()}
              </Typography>
              {pedido.pagamento?.forma_pagamento === "dinheiro" && (
                <Typography variant="h6">
                  Troco para: {formataPreco(pedido.pagamento?.troco)}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2em",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Observações: </span>{" "}
                {pedido.observacoes}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "1em",
                padding: "1em",
              }}
            >
              {!["cancelado", "finalizado", "entregue", "retirado"].includes(
                pedido.status.toLowerCase()
              ) && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      border: "1px solid #f25555",
                      backgroundColor: "#fff",
                      color: "#f25555",
                      width: 250,
                      marginTop: "1em",
                      fontSize: "1.2em",

                      "&:hover": {
                        backgroundColor: "#f25555",
                        color: "#fff",
                      },
                    }}
                    color="error"
                    onClick={() => changeStatus("cancelado")}
                  >
                    Cancelar Pedido
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      border: "1px solid #00a35c",
                      backgroundColor: "#00a35c",
                      color: "#fff",
                      width: 250,
                      marginTop: "1em",
                      fontSize: "1.2em",

                      "&:hover": {
                        backgroundColor: "#027d48",
                        border: "1px solid #027d48",
                        color: "#fff",
                      },
                    }}
                    onClick={changeStatus}
                  >
                    {pedido.pagamento?.formaEntrega?.toUpperCase() === "RETIRADA" ? 
                    todosStatusRetirada[pedido.status.toLowerCase()] : todosStatus[pedido.status.toLowerCase()]}
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DetalhesPedido;
