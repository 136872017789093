import { createSlice } from "@reduxjs/toolkit";

const initState = {
  _id: "",
  nome: "",
  imagem_perfil: "",
  cnpj: "",
  telefone: "",
  categoria: "",
  endereco_id: "",
  conectada: false,
  banner: "",
  paleta: [],
  slug: "",
  funcionamento: {
    horarios: {
      domingo: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
      segunda: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
      terca: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
      quarta: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
      quinta: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
      sexta: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
      sabado: {
        horario_abertura: "",
        horario_fechamento: "",
        aberto: false,
      },
    },
    entrega: {
      ativo: false,
      taxaPorKm: 0,
      taxaMinima: 0,
      taxaGratis: 0,
      distanciaMaxima: 0,
    }
  },
};

export const lojaSlice = createSlice({
  name: "loja",
  initialState: {
    ...initState,
  },
  reducers: {
    changeLoja(state, { payload }) {
      return {
        ...state,
        ...payload,
        conectada: true,
      };
    },
    logoutLoja() {
      return {
        ...initState,
      };
    },
  },
});

export const { changeLoja, logoutLoja } = lojaSlice.actions;

export const selectLoja = (state) => state.loja; // state.loja.loja is the loja object

export default lojaSlice.reducer;