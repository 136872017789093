export async function distanciaEntre(cep1, cep2) {
    //if(cep1 === cep2) return "0 km";
  const viaCepUrl = "https://viacep.com.br/ws";
  const nominatimUrl = "https://nominatim.openstreetmap.org/search.php";

  const response1 = await fetch(`${viaCepUrl}/${cep1}/json/`);
  const data1 = await response1.json();
  const { localidade, uf, logradouro } = data1;

  const response2 = await fetch(`${viaCepUrl}/${cep2}/json/`);
  const data2 = await response2.json();
  const { localidade: localidade2, uf: uf2, logradouro: logradouro2 } = data2;

  const response3 = await fetch(
    `${nominatimUrl}?q=${logradouro},${localidade},${uf},Brasil&format=jsonv2`
  );
  const data3 = await response3.json();
  const { lat: lat1, lon: lon1 } = data3[0];

  const response4 = await fetch(
    `${nominatimUrl}?q=${logradouro2},${localidade2},${uf2},Brasil&format=jsonv2`
  );
  const data4 = await response4.json();
  const { lat: lat2, lon: lon2 } = data4[0];

  const R = 6371; // raio da Terra em km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance.toFixed(2) + " km";
}