import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./userSlice";
import lojaReducer from "./lojaSlice";
import clienteReducer from "./clienteSlice";

export const store = configureStore({
    reducer: combineReducers({
        user: userReducer,
        loja: lojaReducer,
        cliente: clienteReducer,
    }),
});