import { Box, Typography, Button } from "@mui/material";
import { selectUser } from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import global_theme from "../../../assets/global/globalTheme";
import UrlApi from "../../../appconfig";
import { ApiToken } from "../../../appconfig";
import { useNavigate } from "react-router-dom";
import { toastIt } from "../../../functions/pushNotif";

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const user = useSelector(selectUser);
  const theme = global_theme;
  const navigate = useNavigate();

  const carregarUsuarios = async () => {
    fetch(`${UrlApi}/usuarios`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        token: user.token,
        loja_id: user.loja_id,
      },
    })
      .then(async (response) => {
        if (!response) {
          // console.log("error");
          return false;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setUsuarios(data);
        }
      })
      .catch((err) => console.log(''));
  };

  const resetarSenha = async (id) => {
    const confirm = window.confirm(
      "Deseja realmente resetar a senha deste usuário?"
    );
    if (!confirm) {
      return false;
    }

    fetch(`${UrlApi}/usuarios/redefinir_senha`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        user_id: id,
      },
    })
      .then(async (response) => {
        if (!response) {
          // console.log("error");
          return false;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          toastIt("Senha redefinida com sucesso", "success");
        }
      })
      .catch((err) => console.log(''));
  };

  const deletarUsuario = async (id) => {
    const confirm = window.confirm("Deseja realmente deletar este usuário?");
    if (!confirm) {
      return false;
    }
    fetch(`${UrlApi}/usuarios/deletar`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
        user_id: id,
      },
    })
      .then(async (response) => {
        if (!response) {
          // console.log("error");
          return false;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          toastIt("Usuário deletado com sucesso", "success");
          setUsuarios(usuarios.filter((usuario) => usuario._id !== id));
        }
      })
      .catch((err) => console.log(''));
  };

  useEffect(() => {
    if (user && user.role === "admin") {
      carregarUsuarios();
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        p: 1,
      }}
    >
      {user && user.role !== "admin" ? (
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        >
          Você não tem permissão para acessar esta página.
        </Typography>
      ) : (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
            }}
          >
            Usuários
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/usuarios/novo")}
            sx={{
              mt: "1rem",
              mb: "1rem",
              backgroundColor: theme.palette.primary.main,

              "&:hover": {
                backgroundColor: theme.palette.primary.contrast,
              },
            }}
          >
            Novo
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "1em",
              gap: "1em",
            }}
          >
            {usuarios.length &&
              usuarios.map((usuario) => (
                <Box
                  key={usuario._id}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.5em",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        marginRight: 1,
                      }}
                    >
                      {usuario.usuario} ·
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.primary.contrast,
                      }}
                    >
                      {" " + usuario.role}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => resetarSenha(usuario._id)}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.contrast,
                        },
                      }}
                    >
                      Resetar senha
                    </Button>
                    {usuario._id !== user._id && (
                      <Button
                        variant="contained"
                        onClick={() => deletarUsuario(usuario._id)}
                        sx={{
                          backgroundColor: "#f44336",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#d32f2f",
                          },
                        }}
                      >
                        Deletar usuário
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Usuarios;
