import { useState } from "react"
import { Box, Typography, TextField, Button } from "@mui/material"
import UrlApi from "../../../../appconfig"
import { ApiToken } from "../../../../appconfig"
import { useSelector } from "react-redux"
import { selectLoja } from "../../../../redux/lojaSlice"
import global_theme from "../../../../assets/global/globalTheme"
import { toastIt } from "../../../../functions/pushNotif"

const CriarCategoria = () => {
  const [nome, setNome] = useState("")
  const [descricao, setDescricao] = useState("")
  const loja = useSelector(selectLoja)
  const theme = global_theme

  const createCategory = async () => {
    fetch(`${UrlApi}/loja/categorias`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
      body: JSON.stringify({
        nome,
        descricao,
        loja_id: loja._id
    })
    })
    .then((response) => response.json())
    .then((data) => {
      if (!data.error) {
        toastIt("Categoria criada com sucesso", "success")
        setNome("")
        setDescricao("")
      } else {
        toastIt(data.error, "error")
      }
    })
    .catch((err) => console.log(''))
  }

  return (
    <Box sx={{
      p: 2
    }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
            Criar Categoria
        </Typography>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "1rem",
                maxWidth: 500
            }}
        >
            <TextField
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                sx={{
                  ...theme.inputs.primary.main
                }}
            />
            <TextField
                label="Descrição"
                variant="outlined"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                multiline
                sx={{
                  ...theme.inputs.primary.main
                }}
            />
            <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.main,

                  "&:hover": {
                    backgroundColor: theme.palette.primary.contrast,
                  },
                }}
                onClick={createCategory}
            >
                Criar Categoria
            </Button>
        </Box>
    </Box>
  )
}

export default CriarCategoria;