// este será um modal para criar uma nova subcategoria
import { Box, Modal, Button, Typography, TextField } from "@mui/material";
import { useState } from "react";
import { toastIt } from "../../../../functions/pushNotif";
import global_theme from "../../../../assets/global/globalTheme";
import UrlApi from "../../../../appconfig";
import { ApiToken } from "../../../../appconfig";
import { useSelector } from "react-redux";
import { selectLoja } from "../../../../redux/lojaSlice";

const NewSub = ({ open, handleClose, updateAll, categoria }) => {
  const [nome, setNome] = useState("");
  const loja = useSelector(selectLoja);
  const theme = global_theme;

  const handleSave = async () => {
    if (!nome) {
      toastIt("Preencha todos os campos", "error");
      return;
    }

    fetch(`${UrlApi}/subcategorias/${loja._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ApiToken}`,
      },
      body: JSON.stringify({
        nome,
        categoria_id: categoria._id,
      }),
    })
      .then((response) => {
        if (response.ok) {
          toastIt("Subcategoria criada com sucesso", "success");
          handleClose();
          updateAll();
        } else {
          response.json().then((data) => {
            toastIt(data.message, "error");
          });
        }
      })
      .catch((error) => {
        toastIt(error.message, "error");
      });
  };

  if (!categoria) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          outline: "none",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        >
          Nova subcategoria
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            m: "1rem 0",
          }}
        >
          <TextField
            label="Nome"
            variant="outlined"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            sx={{
              ...theme.inputs.primary,
            }}
          />
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary.main,
            alignSelf: "flex-end",

            "&:hover": {
              backgroundColor: theme.palette.primary.contrast,
            },
          }}
          onClick={handleSave}
        >
          Criar
        </Button>
      </Box>
    </Modal>
  );
};

export default NewSub;
