import { useEffect, useState } from "react";
import { selectLoja, changeLoja } from "../../redux/lojaSlice";
import { useSelector, useDispatch } from "react-redux";
import { toastIt } from "../../functions/pushNotif";
import { ApiToken } from "../../appconfig";
import UrlApi from "../../appconfig";
import "react-time-picker/dist/TimePicker.css";
import TimePicker from "react-time-picker";
import global_theme from "../../assets/global/globalTheme";
import { Box, Typography, Button, TextField } from "@mui/material";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import * as XLSX from "xlsx";
import xlsxTeste from "../../assets/content/specs.xlsx";

const HorarioFuncionamento = () => {
  const diaPadrao = {
    aberto: true,
    horario_abertura: "00:00",
    horario_fechamento: "00:00",
    padrao: true,
  };
  const [domingo, setDomingo] = useState(diaPadrao);
  const [segunda, setSegunda] = useState(diaPadrao);
  const [terca, setTerca] = useState(diaPadrao);
  const [quarta, setQuarta] = useState(diaPadrao);
  const [quinta, setQuinta] = useState(diaPadrao);
  const [sexta, setSexta] = useState(diaPadrao);
  const [sabado, setSabado] = useState(diaPadrao);

  const [loading, setLoading] = useState(false);

  const semanaSetState = {
    0: {
      obj: domingo,
      set: setDomingo,
    },
    1: {
      obj: segunda,
      set: setSegunda,
    },
    2: {
      obj: terca,
      set: setTerca,
    },
    3: {
      obj: quarta,
      set: setQuarta,
    },
    4: {
      obj: quinta,
      set: setQuinta,
    },
    5: {
      obj: sexta,
      set: setSexta,
    },
    6: {
      obj: sabado,
      set: setSabado,
    },
  };

  const diaDaSemana = {
    0: "Domingo",
    1: "Segunda-feira",
    2: "Terça-feira",
    3: "Quarta-feira",
    4: "Quinta-feira",
    5: "Sexta-feira",
    6: "Sábado",
  };

  const loja = useSelector(selectLoja);
  const dispatch = useDispatch();
  const theme = global_theme;

  const buscarHorarios = () => {
    if (loja.funcionamento.horarios) {
      setDomingo(loja.funcionamento.horarios.domingo);
      setSegunda(loja.funcionamento.horarios.segunda);
      setTerca(loja.funcionamento.horarios.terca);
      setQuarta(loja.funcionamento.horarios.quarta);
      setQuinta(loja.funcionamento.horarios.quinta);
      setSexta(loja.funcionamento.horarios.sexta);
      setSabado(loja.funcionamento.horarios.sabado);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (loja._id) {
      buscarHorarios();
    }
  }, [loja]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: "A" });

      console.log(JSON.stringify(jsonData, null, 2));
    };

    reader.readAsArrayBuffer(file);
  };

  const validaHorarios = (dias) => {
    var valido = true;
    for (let i = 0; i < dias.length; i++) {
      if (dias[i].horario_abertura > dias[i].horario_fechamento) {
        toastIt("O horário de abertura não pode ser maior que o de fechamento");
        valido = false;
        break;
      }

      if (dias[i].horario_abertura === dias[i].horario_fechamento) {
        toastIt("O horário de abertura não pode ser igual ao de fechamento");
        valido = false;
        break;
      }
    }

    return valido;
  };

  const salvarHorarios = () => {
    if (
      validaHorarios([domingo, segunda, terca, quarta, quinta, sexta, sabado])
    ) {
      const body = {
        funcionamento: {
          ...loja.funcionamento,
          horarios: {
            domingo,
            segunda,
            terca,
            quarta,
            quinta,
            sexta,
            sabado,
          },
        },
      };

      if (!loja._id) return toastIt("Erro ao salvar horários", "error");

      fetch(`${UrlApi}/loja/${loja._id}/funcionamento`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiToken}`,
          loja_id: loja._id,
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            toastIt(data.error, "error");
          } else {
            toastIt("Horários salvos com sucesso!", "success");
            dispatch(
              changeLoja({
                ...loja,
                funcionamento: {
                  ...loja.funcionamento,
                  horarios: {
                    domingo,
                    segunda,
                    terca,
                    quarta,
                    quinta,
                    sexta,
                    sabado,
                  },
                },
              })
            );
          }
        })
        .catch((error) => {
          toastIt("Erro ao salvar horários", "error");
          console.log(error);
        });
    } else {
      toastIt("Erro ao salvar horários", "error");
    }
  };

  return !loading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
        p: 2,
        color: theme.palette.primary.main,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Horários de funcionamento
      </Typography>

      {[0, 1, 2, 3, 4, 5, 6].map((dia) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mb: 2,
            border: "1px solid #ccc",
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              width: "200px",
            }}
          >
            {diaDaSemana[dia]}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <TimePicker
              disableClock={true}
              clearIcon={null}
              clockIcon={null}
              value={semanaSetState[dia].obj.horario_abertura}
              onChange={(e) => {
                semanaSetState[dia].set({
                  ...semanaSetState[dia].obj,
                  horario_abertura: e,
                });
              }}
            />
            <Typography variant="body1" sx={{ mx: 2 }}>
              até
            </Typography>
            <TimePicker
              disableClock={true}
              clearIcon={null}
              clockIcon={null}
              value={semanaSetState[dia].obj.horario_fechamento}
              onChange={(e) => {
                semanaSetState[dia].set({
                  ...semanaSetState[dia].obj,
                  horario_fechamento: e,
                });
              }}
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              ml: 2,
              width: "150px",
              backgroundColor: semanaSetState[dia].obj.aberto
                ? "#4caf50"
                : "#f44336",

              "&:hover": {
                backgroundColor: semanaSetState[dia].obj.aberto
                  ? "#4caf66"
                  : "#f44311",
              },
            }}
            onClick={() => {
              semanaSetState[dia].set({
                ...semanaSetState[dia].obj,
                aberto: !semanaSetState[dia].obj.aberto,
              });
            }}
          >
            {semanaSetState[dia].obj.aberto ? "Aberto" : "Fechado"}
          </Button>
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.contrast,
          },
        }}
        onClick={salvarHorarios}
      >
        Salvar
      </Button>
    </Box>
  ) : (
    <LoadingPage />
  );
};

export default HorarioFuncionamento;
