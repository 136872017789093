import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  ThemeProvider,
  Backdrop,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { selectLoja } from "../../redux/lojaSlice";
import { selectUser } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import ExcluirProduto from "./ExcluirProduto/ExcluirProduto";
import DefaultProduct from "../../assets/default_product.jpg";
import { formataPreco } from "../../functions/formatInfo";
import global_theme from "../../assets/global/globalTheme";
import UrlApi from "../../appconfig";
import { ApiToken } from "../../appconfig";
import { toastIt } from "../../functions/pushNotif";
import CatalogoCard from "../../components/Card/Product/CatalogoCard";
import * as XLSX from "xlsx";
import FileOpenIcon from "@mui/icons-material/FileOpen";

const Catalogo = () => {
  const [produtos, setProdutos] = useState(null);
  const [item, setItem] = useState(null);
  const [itemEstoque, setItemEstoque] = useState(null);

  const loja = useSelector(selectLoja);
  const usuario = useSelector(selectUser);
  const theme = global_theme;

  const navigate = useNavigate();

  const carregarProdutos = async () => {
    const response = await fetch(`${UrlApi}/loja/produtos/${loja._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return false;
    }
    setProdutos(data);
  };

  useEffect(() => {
    if (loja) {
      carregarProdutos();
    }
  }, [loja]);

  const esgotarProduto = async (id) => {
    const response = await fetch(`${UrlApi}/loja/produtos/estoque/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
      body: JSON.stringify({
        estoque: 0,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      toastIt(data.message, "error");
      return false;
    }
    carregarProdutos();
    toastIt("Produto indisponibilizado com sucesso", "success");
  };

  const reativarProduto = async (id) => {
    const response = await fetch(`${UrlApi}/loja/produtos/estoque/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
      body: JSON.stringify({
        estoque: 1,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      toastIt(data.message, "error");
      return false;
    }
    carregarProdutos();
    toastIt("Produto reativado com sucesso", "success");
  };

  const writeToFile = (obj) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(obj);

    const currencyFormat = "#,##0.00 [$R$-416]";
    const cellRange = XLSX.utils.decode_range(ws["!ref"]);
    for (let rowNum = cellRange.s.r; rowNum <= cellRange.e.r; rowNum++) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: 5 }); // Column index 2 is "preco" (C column)
      const cell = ws[cellAddress];
      if (cell && cell.t === "n") {
        cell.z = currencyFormat;
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, "Produtos");

    // Gerar o arquivo XLSX como um Blob
    const wbout = XLSX.write(wb, { type: "array", bookType: "xlsx" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Criar um URL temporário para download
    const url = window.URL.createObjectURL(blob);

    // Criar um link de download
    const link = document.createElement("a");
    link.href = url;
    link.download =
      "produtos_" + new Date().toString().replace(" ", "") + ".xlsx";

    // Simular um clique no link para iniciar o download
    link.click();

    // Liberar o URL temporário
    window.URL.revokeObjectURL(url);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      convertToJson(file);
    }
  };

  const convertToJson = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      // Assuming you want to convert the first sheet of the XLSX file
      const sheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      fetch(`${UrlApi}/loja/produtos/importacao/importar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiToken}`,
          loja_id: loja._id,
        },
        body: JSON.stringify({
          jsonObject: jsonData,
          criador: usuario.usuario,
          data_cadastro: new Date(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.success) {
            toastIt(data.message, "error");
          } else {
            toastIt(data.message, "success");
            carregarProdutos();
          }
        })
        .catch((err) => {
          // console.log(err);
          toastIt("Erro ao importar categorias", "error");
        });
    };

    reader.readAsBinaryString(file);
  };

  return produtos ? (
    <ThemeProvider theme={theme}>
      <ExcluirProduto
        produto={item}
        open={item !== null}
        handleClose={() => setItem(null)}
        updateAll={() => {
          setItem(null);
          carregarProdutos();
        }}
      />
      {itemEstoque && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={itemEstoque !== null}
          onClick={() => setItemEstoque(null)}
        >
          <Box
            component="form"
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              backgroundColor: "#fff",
              borderRadius: "12px",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: itemEstoque.estoque < 1 ? "#000" : "#f00",
              }}
            >
              {itemEstoque.estoque < 1
                ? "Deseja reativar o produto?"
                : "Deseja desativar o produto?"}
            </Typography>
            <Avatar
              sx={{
                width: "100px",
                height: "100px",
                margin: "auto",
                marginBottom: "1rem",
                border: "1px solid #ccc",
              }}
              alt={itemEstoque?.nome}
              src={itemEstoque?.imagens[0] || DefaultProduct}
            />
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "#000" }}
            >
              {itemEstoque?.nome}
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: itemEstoque.estoque < 1 ? "#0f0" : "#f00",

                "&:hover": {
                  backgroundColor: itemEstoque.estoque < 1 ? "#0a0" : "#c00",
                },
              }}
              onClick={() => {
                if (itemEstoque.estoque < 1) {
                  reativarProduto(itemEstoque._id);
                } else {
                  esgotarProduto(itemEstoque._id);
                }
                setItemEstoque(null);
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "#ccc",

                "&:hover": {
                  backgroundColor: "#555",
                },
              }}
              onClick={() => setItemEstoque(null)}
            >
              Cancelar
            </Button>
          </Box>
        </Backdrop>
      )}
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
        >
          Catálogo
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Link
            to="/catalogo/criar-produto"
            style={{
              width: "200px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "#5C5C9C",
              }}
            >
              Criar Produto
            </Button>
          </Link>
          <label
            htmlFor="upload-xlsx"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body1"
              title="Importar produtos (xlsx)"
              sx={{
                color: "#fff",
                backgroundColor: "#4fc26e",
                borderRadius: "4px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: ".5em",
              }}
            >
              <FileOpenIcon />
            </Typography>
            <input
              id="upload-xlsx"
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 4, sm: 6, md: 10 }}
          >
            {produtos?.map((produto, index) => (
              <CatalogoCard
                key={index}
                produto={produto}
                excluir={setItem}
                estoque={setItemEstoque}
              />
            ))}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  ) : (
    <LoadingPage />
  );
};

export default Catalogo;
