import { Route } from "react-router-dom";
import HomeLoja from "../pages/Loja/Home/HomeLoja";
import Checkout from "../cliente_side/pages/Checkout/Checkout";
import MeusPedidos from "../cliente_side/pages/Pedidos/MeusPedidos";
import ProdutoPage from "../pages/Loja/Produto/ProdutoPage";

const ClienteRoutes = [
    <Route path="/loja/:id" element={<HomeLoja />} />,
    <Route path="/checkout" element={<Checkout />} />,
    <Route path="/meuspedidos" element={<MeusPedidos />} />,
    <Route path="/loja/:slugLoja/produto/:slugProduto/id/:idProduto" element={<ProdutoPage />} />,
]

export default ClienteRoutes;