import RedefinirSenha from "../pages/SignIn/RedefinirSenha";
import Catalogo from "../pages/Catalogo/Catalogo";
import CriarProduto from "../pages/Catalogo/CriarProduto/CriarProduto";
import EditarProduto from "../pages/Catalogo/EditarProduto/EditarProduto";
import ListaPedidos from "../pages/Pedidos/ListaPedidos/ListaPedidos";
import PrivateRoutes from "./PrivateRoutes";
import Categorias from "../pages/Catalogo/Categorias/Categorias";
import CriarCategoria from "../pages/Catalogo/Categorias/CriarCategoria/CriarCategoria";
import EditarCategoria from "../pages/Catalogo/Categorias/EditarCategoria/EditarCategoria";
import Clientes from "../pages/Pessoas/Clientes/Clientes";
import Usuarios from "../pages/Pessoas/Usuarios/Usuarios";
import CriarUsuario from "../pages/Pessoas/Usuarios/CriarUsuario/CriarUsuario";
import ConfiguracoesRoutes from "./Configurações/Configuracoes";
import Dashboard from "../pages/Dashboard/Dashboard";
import { Route } from "react-router-dom";
import React from "react";
import PedidosMobile from "../mobile/pages/Pedidos/PedidosMobile";
import DetalhesPedido from "../mobile/pages/Pedidos/DetalhesPedidoMobile";

const LojaRoutes = [
  <Route
    path="/dashboard"
    element={
      <PrivateRoutes>
        <Dashboard />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/catalogo"
    element={
      <PrivateRoutes>
        <Catalogo />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/redefinir-senha"
    element={
      <PrivateRoutes>
        <RedefinirSenha />
      </PrivateRoutes>
    }
  />,
  ConfiguracoesRoutes,
  <Route
    path="/catalogo/criar-produto"
    element={
      <PrivateRoutes>
        <CriarProduto />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/catalogo/editar-produto/:id"
    element={
      <PrivateRoutes>
        <EditarProduto />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/catalogo/categorias"
    element={
      <PrivateRoutes>
        <Categorias />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/catalogo/categorias/criar"
    element={
      <PrivateRoutes>
        <CriarCategoria />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/catalogo/categorias/editar/:id"
    element={
      <PrivateRoutes>
        <EditarCategoria />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/pedidos"
    element={
      <PrivateRoutes>
        <ListaPedidos />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/m/pedidos"
    element={
      <PrivateRoutes>
        <PedidosMobile />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/m/pedidos/:id"
    element={
      <PrivateRoutes>
        <DetalhesPedido />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/clientes"
    element={
      <PrivateRoutes>
        <Clientes />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/usuarios"
    element={
      <PrivateRoutes>
        <Usuarios />
      </PrivateRoutes>
    }
  />,
  <Route
    path="/usuarios/novo"
    element={
      <PrivateRoutes>
        <CriarUsuario />
      </PrivateRoutes>
    }
  />,
];

export default LojaRoutes;
