import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeUser } from "../../redux/userSlice";
import { changeLoja } from "../../redux/lojaSlice";
import { toastIt } from "../../functions/pushNotif";
import global_theme from "../../assets/global/globalTheme";
import UrlApi from "../../appconfig";
import { ApiToken, senhaPadrao, AppNomeCompacto } from "../../appconfig";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://mktinsight.com.br/">
        Insight Agency
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = global_theme;

  const navigate = useNavigate();

  var rand = function () {
    return Math.random().toString(36).substr(2); // remove `0.`
  };

  var token = function () {
    return rand() + rand(); // to make it longer
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === false) {
      toastIt("Preencha todos os campos", "error");
      return false;
    }
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    const remember = data.get("remember");
    if (!email.includes("@" && ".")) setError("E-mail inválido");
    setLoading(true);
    fetch(`${UrlApi}/login`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        email,
        password,
        authorization: `Bearer ${ApiToken}`,
        remember,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(changeUser(data.data));
        const redefinirSenha = password === senhaPadrao ? true : false;
        carregarLoja(data.data.loja_id, redefinirSenha);
        if (data.token) {
          localStorage.setItem("token-pedja", data.token);
        }

        toastIt(`Bem-vindo, ${data.data.usuario}!`, "success");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toastIt("E-mail ou senha incorretos", "error");
        setLoading(false);
      });
  };

  const carregarLoja = async (id, redefinirSenha) => {
    const response = await fetch(`${UrlApi}/loja/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
    });
    const data = await response.json();
    dispatch(changeLoja(data));
    // muda o titulo e o icone da pagina
    document.title = data.nome + " | " + AppNomeCompacto;
    // muda o logo do site para o logo da loja
    document
      .querySelector("link[rel*='icon']")
      .setAttribute("href", data.imagem_perfil);

    if (redefinirSenha) {
      navigate("/redefinir-senha");
    } else {
      navigate("/pedidos");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.contrast }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: theme.palette.primary.main }}
          >
            Entrar
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              type={"email"}
              onChange={(e) => (error ? setError("") : null)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {error && <Typography color="error">{error}</Typography>}
            <FormControlLabel
              control={<Checkbox color="primary" name="remember" />}
              label="Lembre-me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bc: theme.palette.primary.main,
                "&:hover": {
                  bc: theme.palette.primary.contrast,
                },
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Entrar"
              )}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
