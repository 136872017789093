import EmRota from "../status-pedido/em_rota.svg"
import Entregue from "../status-pedido/entregue.svg"
import Localizacao from "../status-pedido/localizacao.svg"
import Pendente from "../status-pedido/pendente.svg"
import Cancelado from "../status-pedido/cancelado.svg"
import AguardandoRetirada from "../status-pedido/aguardando_retirada.svg"

import { ReactComponent as IconeLocalizacao } from "../status-pedido/localizacao.svg";
import { ReactComponent as IconeEntregue } from "../status-pedido/entregue.svg";
import { ReactComponent as IconeEmRota } from "../status-pedido/em_rota.svg";
import { ReactComponent as IconePendente } from "../status-pedido/pendente.svg";


export const statusIcons = {
    EmRota,
    AguardandoRetirada,
    Entregue,
    Localizacao,
    Pendente,
    Cancelado
}

export const statusIconsComponent = {
    EmRota: IconeEmRota,
    Entregue: IconeEntregue,
    Localizacao: IconeLocalizacao,
    Pendente: IconePendente,

}