// essa será a página de carregamento que será exibida enquanto o aplicativo estiver carregando.

import React from "react";
import { AppLogo } from "../../appconfig";
import { Box, keyframes } from "@mui/material";
import "./index.css"

const LoadingPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#5C5C9C",
        position: "absolute",
        top: 0,
        zIndex: 9999,
      }}
    >
      <img src={AppLogo} alt="Logo" className="logo"/>
    </Box>
  );
};

export default LoadingPage;