import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeUser, selectUser, setUserToken } from "../redux/userSlice";
import { changeLoja } from "../redux/lojaSlice";
import UrlApi, { ApiToken, AppNomeCompacto } from "../appconfig";

const PrivateRoutes = ({ children }) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const carregarLoja = async (id) => {
    const response = await fetch(`${UrlApi}/loja/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${ApiToken}`,
      },
    });
    const data = await response.json();
    dispatch(changeLoja(data));
    // muda o titulo e o icone da pagina
    document.title = data.nome + " | " + AppNomeCompacto;
    // muda o logo do site para o logo da loja
    document
      .querySelector("link[rel*='icon']")
      .setAttribute("href", data.imagem_perfil);
  };

  const checkUserToken = (user) => {
    const token = localStorage.getItem("token-pedja");
    // console.log('possivel token do usuario: ', token)
    if (token && !user.token) {
      // console.log('usuario tem token')
      fetch(`${UrlApi}/login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiToken}`,
          token: token,
        },
      })
        .then(async (response) => {
          if (!response) {
            // console.log("error");
            return false;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            dispatch(changeUser(data.data));
            dispatch(setUserToken(data.token));
            carregarLoja(data.data.loja_id);
            return true;
          }
        })
        .catch((err) => console.log(''));
    } else return false;
  };

  if (!user.isLogged) {
    setTimeout(async () =>  {
      if (!user?.usuario) {
        // console.log('checando usuario')
        var result = await checkUserToken(user);
        if(result !== false) {
          return children;  
        } 
        else {
          // console.log("result: ", result)
          // console.log("indo para login")
          navigate("/signin");
        }
      } else {
        return children;
      }
    }, 1000);
  } else return children;
};
export default PrivateRoutes;
