import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import global_theme from "../../../../assets/global/globalTheme";
import Descricao from "./Descricao";

const ProdutoInfo = ({ produto }) => {
  const [sectionSelected, setSectionSelected] = useState(0);
  const theme = global_theme;

  useEffect(() => {
    if (sectionSelected > 0) {
      if (produto && produto.descricao) {
        setSectionSelected(0);
      } else {
        setSectionSelected(1);
      }
    }
  }, [produto]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "3em",
          alignItems: "center",
          borderBottom: "1px solid #ccc",
          paddingBottom: "1em",

          // em telas menores diminui o gap
          "@media (max-width: 600px)": {
            gap: "1em",
          },
        }}
      >
        {produto && produto.descricao && (
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.primary.main,
              marginRight: 1,
              fontWeight: "bold",
              borderBottom:
                sectionSelected == 0
                  ? `2px solid ${theme.palette.secondary.main}`
                  : "none",
              cursor: "pointer",

              // diminui a fonte em telas menores
            "@media (max-width: 600px)": {
              fontSize: "1rem",
            },
            }}
            onClick={() => setSectionSelected(0)}
          >
            Descrição
          </Typography>
        )}
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: "bold",
            borderBottom:
              sectionSelected === 1
                ? `2px solid ${theme.palette.secondary.main}`
                : "none",
            cursor: "pointer",

            // diminui a fonte em telas menores
            "@media (max-width: 600px)": {
              fontSize: "1rem",
            },
          }}
          onClick={() => setSectionSelected(1)}
        >
          Avaliações
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: "bold",
            borderBottom:
              sectionSelected == 2
                ? `2px solid ${theme.palette.secondary.main}`
                : "none",
            cursor: "pointer",

            
            
            // diminui a fonte em telas menores
            "@media (max-width: 600px)": {
              fontSize: "1rem",
            },
          }}
          onClick={() => setSectionSelected(2)}
        >
          Relacionados
        </Typography>
      </Box>
      {produto && sectionSelected === 0 ? (
        <Descricao produto={produto} />
      ) : null}
    </Box>
  );
};

export default ProdutoInfo;
