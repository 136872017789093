import { useState } from "react";
import DefaultProduct from "../../../assets/default_product.jpg";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  ListItem,
} from "@mui/material";
import { formataPreco } from "../../../functions/formatInfo";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

const CatalogoCard = ({ produto, excluir, estoque }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();

  return (
    <Grid item xs={2} sm={2} md={2}>
      <Card
        sx={{
          maxWidth: 220,
          height: 300,
          paddingBottom: "0",
          display: "grid",
        }}
      >
        <CardMedia
          sx={{ height: 140 }}
          image={produto.imagens[0] || DefaultProduct}
          title={produto.nome}
        />
        <CardContent sx={{ paddingTop: 0 }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            title={produto.nome}
            sx={{
              fontSize: "1rem",
              position: "relative",
            }}
          >
            {
              // se o nome do produto for maior que 30 caracteres, ele corta e adiciona 3 pontos
              produto.nome.length > 30
                ? produto.nome.substring(0, 30) + "..."
                : produto.nome
            }
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            {formataPreco(produto.preco)}
          </Typography>
        </CardContent>
        <Box
          sx={{
            p: "0.5rem",
            gap: "0.5rem",
            overflow: "visible !important"
          }}
        >
          <IconButton
            size="small"
            sx={{
              margin: 0,
              width: 30,
              height: 30,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.5,
              fontSize: 11,

              "&:hover": {
                backgroundColor: "#999999",
              },
            }}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Box
            sx={{
              position: "absolute",
              backgroundColor: "#fff",
              display: openMenu ? "block" : "none",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              transform: "translateX(-7px) translateY(12px)",
              width: 100
            }}
            onBlur={() => {
              setOpenMenu(false)}}
          >
            <ListItem
              onClick={() =>
                navigate(`/catalogo/editar-produto/${produto._id}`)
              }
              sx={{
                cursor: "pointer",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ccc",
                },
              }}
            >
              Editar
            </ListItem>
            <ListItem
              onClick={() => {
                setOpenMenu(false);
                excluir(produto);
              }}
              sx={{
                cursor: "pointer",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ccc",
                },
              }}
            >
              Excluir
            </ListItem>
            <ListItem
              onClick={() => {
                setOpenMenu(false);
                estoque(produto);
              }}
              sx={{
                cursor: "pointer",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ccc",
                },
              }}
            >
              {produto.estoque < 1 ? "Ativar" : "Desativar"}
            </ListItem>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default CatalogoCard;
