import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";

function buildWhatsAppLink(phoneNumber, message) {
  const encodedMessage = encodeURIComponent(message);
  return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
}

function WhatsappButton({numero, mensagem}) {

  // formata o numero para o padrao do whatsapp
  numero = numero.replace(/\D/g, '');
  if (numero.length === 11) {
    numero = numero.replace(/^(\d{2})(\d{5})(\d{4}).*/, '55$1$2$3');
  } else if (numero.length === 10) {
    numero = numero.replace(/^(\d{2})(\d{4})(\d{4}).*/, '55$1$2$3');
  } else if (numero.length === 12) {
    numero = numero.replace(/^(\d{2})(\d{6})(\d{4}).*/, '55$1$2$3');
  } else if (numero.length === 13) {
    numero = numero.replace(/^(\d{2})(\d{7})(\d{4}).*/, '55$1$2$3');
  } else {
    numero = numero.replace(/^(\d{2})(\d{5})(\d{4}).*/, '55$1$2$3');
  }
  

  const handleWhatsAppClick = () => {
    const whatsappLink = buildWhatsAppLink(numero, mensagem);
    window.open(whatsappLink, "_blank");
  };

  return (
    <IconButton onClick={handleWhatsAppClick}>
      <WhatsAppIcon sx={{
        color: "#25D366",
      }} />
    </IconButton>
  );
}

export default WhatsappButton;