import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import UrlApi, { ApiToken } from "../../../appconfig";
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import global_theme from "../../../assets/global/globalTheme";
import { addToCart } from "../../../redux/clienteSlice";
import { useDispatch } from "react-redux";
import { toastIt } from "../../../functions/pushNotif";
import ProdutoInfo from "./components/ProdutoInfo";
import defaultProduct from "../../../assets/default_product.jpg";
import { Helmet } from "react-helmet";

const ProdutoPage = () => {
  const { slugLoja, slugProduto, idProduto } = useParams();
  const [produto, setProduto] = useState({});
  const [variacoes, setVariacoes] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [preco, setPreco] = useState(0);
  const [loja, setLoja] = useState({}); // [TODO] - pegar loja da url
  const theme = global_theme;

  useEffect(() => {
    async function getProduto() {
      const url = `${UrlApi}/loja/produtos/produto/${idProduto}`;
      // console.log("Fazendo requisição para: " + url);
      const data = await fetch(url, {
        headers: {
          Authorization: `Bearer ${ApiToken}`,
        },
      }).then((response) => response.json());
      // console.log(data);
      if (data) {
        setProduto(data);
        setPreco(data.preco);
        // if (data.variacoes) {
        //   var variacoesObj = [];
        //   data.variacoes.forEach((variacao) => {
        //     variacoesObj.push({
        //       nome: variacao.nome,
        //       valor: "",
        //       preco: variacao.preco,
        //     });
        //   });

        //   setVariacoes(variacoesObj);
        // }
      }
    }

    async function getLoja() {
      const url = `${UrlApi}/loja/slug/${slugLoja}`;
      // console.log("Fazendo requisição para: " + url);
      const data = await fetch(url, {
        headers: {
          Authorization: `Bearer ${ApiToken}`,
        },
      }).then((response) => response.json());
      // console.log(data);
      if (data) {
        setLoja(data);
      }
    }

    getLoja();
    getProduto();
  }, [idProduto]);

  const handleVariacaoChange = (variacao, event) => {
    const value = event.target.value;
    const precoVariacao = variacao.opcoes.find(
      (opcao) => opcao.nome === value
    ).preco;

    try {
      if (variacoes.find((v) => v.nome === variacao.nome)) {
        var variacoesNew = variacoes.map((v) => {
          if (v.nome === variacao.nome) {
            return { ...v, valor: value };
          } else {
            return v;
          }
        });

        setVariacoes(variacoesNew);
      } else {
        setVariacoes([
          ...variacoes,
          { nome: variacao.nome, valor: value, preco: variacao.preco },
        ]);
      }

      setPreco(precoVariacao);
      // console.log(variacoes);
    } catch (err) {
      // console.log(err);
    }
  };

  const dispatch = useDispatch();

  const adicionarAoCarrinho = () => {
    // preço inicial é o preço do produto
    let precoAntigo = produto.preco_desconto ? produto.preco : null;
    let produtoId = produto._id; // id inicial é o id do produto

    var novoProduto = { ...produto, variacoes: [], adicionais: [] };

    // checa se o produto tem alguma variação
    if (produto.variacoes) {
      // checa se todas as variacoes foram selecionadas
      for (const variacao of produto.variacoes) {
        const variacaoSelecionada = variacoes.find(
          (ps) => ps.nome === variacao.nome
        );
        if (!variacaoSelecionada || !variacaoSelecionada.valor) {
          toastIt(`Selecione o(a) ${variacao.nome.toLowerCase()}`, "error");
          return;
        } else {
          novoProduto.variacoes.push({
            nome: variacao.nome,
            value: variacaoSelecionada.valor,
          });
        }
      }
      // percorre todas as variacoes selecionadas e define o preço do produto total como o preço da variacao mais cara
      variacoes.forEach((variacao) => {
        const variacaoProduto = produto.variacoes.find(
          (vp) => vp.nome === variacao.nome
        );
        const opcao = variacaoProduto.opcoes.find(
          (opcao) => opcao.nome === variacao.valor
        );

        // pega o id do produto e adiciona o nome da variacao e o nome da opcao
        produtoId += `-${variacao.nome}-${opcao.nome}`;
      });

      // checa se o produto tem adicionais
      if (produto.adicionais) {
        let precoInicial = produto.preco; // preço inicial é o preço do produto
        adicionais.forEach((adicional) => {
          produtoId += `-${adicional.nome}`;

          novoProduto.adicionais.push({
            nome: adicional.nome,
            preco: adicional.preco,
          });
        });
      }
    }

    toastIt("Produto adicionado ao carrinho", "success");
    dispatch(
      addToCart({
        ...novoProduto,
        preco: parseFloat(preco.toFixed(2)),
        precoAntigo: precoAntigo,
        _id: produtoId,
      })
    );
  };

  if (!produto) return <div>Produto não encontrado</div>;

  return (
    <Box>
      <Helmet>
        <meta name="title" content={produto.nome || "Produto"} />
        <meta
          name="description"
          content={produto.descricao || "Descrição do produto"}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://pedjadelivery.com.br/loja/${slugLoja}/produto/${slugProduto}/id/${idProduto}`}
        />
        <meta
          property="og:title"
          content="Special Dog Prime Raças Pequenas Adulto Frango e Arroz"
        />
        <meta
          property="og:description"
          content={produto.descricao || "Descrição do produto"}
        />
        <meta
          property="og:image"
          content={
            produto.imagens && produto.imagens[0]
              ? produto.imagens[0]
              : defaultProduct
          }
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://pedjadelivery.com.br/loja/${slugLoja}/produto/${slugProduto}/id/${idProduto}`}
        />
        <meta property="twitter:title" content={produto.nome || ""} />
        <meta
          property="twitter:description"
          content={produto.descricao || "Descrição do produto"}
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </Helmet>
      {produto && loja && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: ".8rem",
            minWidth: "100%",
          }}
        >
          <Link
            to={`/loja/${slugLoja}`}
            style={{
              textDecoration: "underline",
              color: theme.palette.secondary.main,
              marginBottom: "1rem",
              alignSelf: "flex-start",
            }}
          >
            <Typography
              variant="body"
              sx={{
                fontWeight: "bold",
              }}
            >
              Voltar para a loja
            </Typography>
          </Link>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: ".8rem",
              minWidth: "100%",
              // border: "2px solid yellow",
              margin: "0",
              minHeight: "500px",

              // diminuir o padding em telas menores
              "@media (max-width: 600px)": {
                padding: ".3rem",
              },
            }}
          >
            <Grid
              item
              sm={12}
              md={6}
              sx={{
                height: "100%",
                // border: "1px solid blue"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  // border: "1px solid pink",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: ".8rem",
                    minWidth: "100%",
                    // border: "2px solid purple",
                    height: "100%",

                    // diminuir o padding em telas menores
                    "@media (max-width: 600px)": {
                      padding: ".3rem",
                    },
                  }}
                >
                  <img
                    src={
                      produto.imagens && produto.imagens[0]
                        ? produto.imagens[0]
                        : defaultProduct
                    }
                    alt={produto.nome}
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              sx={{ height: "100%", 
              // border: "1px solid green", 
              paddingTop: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  // border: "1px solid pink",
                  minHeight: "500px",
                  paddingTop: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "0 .8rem .8rem 0",
                    minWidth: "100%",
                    height: "100%",
                    minHeight: "500px",
                    // border: "2px solid purple",

                    // diminuir o padding em telas menores
                    "@media (max-width: 600px)": {
                      padding: ".3rem",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      // border: "2px solid red",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        width: "100%",
                        //border: "2px solid black",
                      }}
                    >
                      <Avatar
                        alt={produto.nome}
                        src={
                          loja && loja.imagem_perfil && loja.imagem_perfil
                          // produto.imagens && produto.imagens[0]
                          //   ? produto.imagens[0]
                          //   : "https://source.unsplash.com/random"
                        }
                        sx={{
                          width: "32px",
                          height: "32px",
                          objectFit: "cover",
                          borderRadius: "50%",
                          // border: "2px solid purple",
                        }}
                      />
                      <Typography
                        variant="body"
                        sx={{
                          fontWeight: "bold",
                          color: "#7a7a7a",
                        }}
                      >
                        Vendido e entregue por {loja.nome || "Loja"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",

                        // diminui a fonte em telas menores
                        "@media (max-width: 600px)": {
                          fontSize: "1.5rem",
                        },
                      }}
                    >
                      {produto.nome}
                    </Typography>

                    <Box sx={{
                      margin: "2rem 0",
                    }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5em",
                          width: "100%",
                        }}
                      >
                        {produto.variacoes &&
                          produto.variacoes.map((variacao) => (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body"
                                sx={{
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {variacao.nome[0].toUpperCase() +
                                  variacao.nome.substring(1)}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".8em",
                                  width: "100%",
                                }}
                              >
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="female"
                                  name="radio-buttons-group"
                                  row
                                  onChange={(e) =>
                                    handleVariacaoChange(variacao, e)
                                  }
                                >
                                  {variacao.opcoes.map((opcao) => (
                                    <FormControlLabel
                                      value={opcao.nome}
                                      control={
                                        <Radio
                                          sx={{
                                            color: theme.palette.secondary.main,
                                            "&.Mui-checked": {
                                              color:
                                                theme.palette.secondary.main,
                                            },
                                          }}
                                        />
                                      }
                                      label={opcao.nome}
                                    />
                                  ))}
                                </RadioGroup>
                              </Box>
                            </Box>
                          ))}
                      </Box>

                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        R$ {preco.toFixed(2).replace(".", ",")}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{
                      mb: 2,
                      width: "100%",
                      backgroundColor: theme.palette.secondary.main,
                      padding: "1rem 0",
                      justifySelf: "flex-end",
                      alignSelf: "flex-end",
                      bottom: "0",

                      "&:hover": {
                        backgroundColor: theme.palette.secondary.contrast,
                      },
                    }}
                    onClick={adicionarAoCarrinho}
                  >
                    Adicionar ao carrinho
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <ProdutoInfo produto={produto} />
        </Container>
      )}
    </Box>
  );
};

export default ProdutoPage;
